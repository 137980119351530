import { Card, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import { Fragment } from 'react';

export const InfoSectionJobOrder = ({ data }) => {
  return (
    <Fragment>
      <div style={{ fontSize: '.9rem' }}>Detail Data Job Order </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <InfoItemHorizontal
                label="Tgl. Job Order"
                text={DateConvert(new Date()).detail}
              />

              <InfoItemHorizontal
                label="No. Job Order"
                text={data.no_job_order ?? ''}
              />

              <InfoItemHorizontal label="No. RAP" text={data.no_rap ?? ''} />

              <InfoItemHorizontal label="No. SPK" text={data.no_spk ?? ''} />
            </Col>

            <Col>
              <InfoItemHorizontal label="Customer" text={data.nama_customer} />

              <InfoItemHorizontal
                label="Item Job Order"
                text={data.nama_barang_jadi ?? ''}
              />

              <InfoItemHorizontal
                label="Qty. Job Order"
                text={`${parseFloat(data.qty_rap ?? data.qty ?? 0)} ${
                  data.nama_satuan ?? ''
                }`}
              />

              <InfoItemHorizontal
                label="Total Qty. Yang Telah Diposting HPP"
                text={`${parseFloat(data?.qty_hpp ?? 0)} ${
                  data.nama_satuan ?? ''
                }`}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};
