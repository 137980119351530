import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import HTMLReactParser from 'html-react-parser';

import { DataStatus } from 'components';
import { PostingBiayaPrelimApi } from 'api';
import { DateConvert } from 'utilities';
import { TableItemSPK } from '../Section';
import { getGrandTotalPrelim } from '../Helper';

const InfoSectionDetailSPK = ({ data }) => {
  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? 'text-primary' : ''}
        style={{ cursor: link ? 'pointer' : 'default' }}
      >
        {value}
      </td>
    </tr>
  );

  const ShowData = ({ title, value }) => (
    <div>
      <small
        className="text-secondary"
        style={{ opacity: 0.9, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div className="text-dark" style={{ fontSize: '0.9rem' }}>
        <div>{value ? value : '-'}</div>
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  data.tgl_penawaran
                    ? DateConvert(new Date(data.tgl_penawaran)).defaultDMY
                    : '-'
                }
              />
              <InfoItem title="No. Penawaran" value={data.no_penawaran} />
              <InfoItem title="No. Peluang" value={data?.no_peluang ?? '-'} />
            </tbody>
          </table>
        </Col>

        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem title="Customer" value={data.nama_customer} />
              <InfoItem title="ATT" value={data.att} />
              <InfoItem
                title="Tgl. Realisasi Penawaran"
                value={
                  data?.realisasi_penawaran
                    ? DateConvert(new Date(data?.realisasi_penawaran))
                        .defaultDMY
                    : '-'
                }
              />
              <InfoItem title="Proyek" value={data.nama_proyek ?? '-'} />
            </tbody>
          </table>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <ShowData
            title="Tgl. SPK"
            value={
              data?.tgl_spk
                ? DateConvert(new Date(data?.tgl_spk)).defaultDMY
                : '-'
            }
          />
        </Col>
        <Col>
          <ShowData title="No.SPK" value={data?.no_spk ?? '-'} />
        </Col>
        <Col>
          <ShowData
            title="Tgl. Selesai Barang Jadi"
            value={
              data?.tgl_selesai
                ? DateConvert(new Date(data?.tgl_selesai)).defaultDMY
                : '-'
            }
          />
        </Col>
        <Col>
          <ShowData
            title="Segment Pasar"
            value={data?.nama_segmentasi_pasar ?? '-'}
          />
        </Col>
      </Row>

      <hr />
    </>
  );
};

const DetailSPK = ({ modalConfig }) => {
  const location = useLocation();
  const { id_spk } = modalConfig;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dataInfo, setDataInfo] = useState({});
  const [dataSPK, setDataSPK] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([PostingBiayaPrelimApi.singleSPK({ id_spk })])
      .then(
        Axios.spread(({ data }) => {
          setDataInfo(data?.data ?? {});
          setDataSPK(data?.data?.detail ?? []);
          setDataPrelim(data?.data.prelim ?? []);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const grandTotalPrelim = getGrandTotalPrelim(dataPrelim ?? []);

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          {/* Info section Detail SPK */}
          <InfoSectionDetailSPK data={dataInfo} />

          {/* List Item SPK */}
          <TableItemSPK
            data={dataSPK}
            diskon={dataInfo.diskon ?? 0}
            ppn={dataInfo.ppn ?? 0}
            totalPrelim={grandTotalPrelim}
          />

          {/* Catatan */}
          <b>Catatan</b>
          <div className="p-2 border">
            {HTMLReactParser(dataInfo?.catatan ?? '<p></p>')}
          </div>
        </>
      )}
    </>
  );
};

export default DetailSPK;
