import Services from 'services';

class BDPOverheadApi {
  get(params) {
    return Services.get('/bdp_overhead/page', { params });
  }

  getSingle(params) {
    return Services.get('/bdp_overhead/single_realisasi', { params });
  }

  getSingleHistory(params) {
    return Services.get('/bdp_overhead/page_bdp', { params });
  }

  getCOA() {
    return Services.get('/bdp_overhead/dropdown');
  }

  getNomor(params) {
    return Services.get('/bdp_overhead/no_baru', { params });
  }

  save(data) {
    return Services.post('/bdp_overhead', data);
  }
}

export default new BDPOverheadApi();
