import { useState, createContext, useMemo } from 'react';
import { getGrandtotaSummary } from '../Helpers';

export const HPPContext = createContext();

export const HPPProvider = ({ children }) => {
  const [dataInfo, setDataInfo] = useState({});
  const [dataBDP, setDataBDP] = useState({
    bahan: [],
    upah: [],
    alat: [],
    subcont: [],
    overhead: [],
    sisa: [],
  });

  const summary = useMemo(
    () => [
      {
        nama_bdp: 'BDP Bahan',
        total: getGrandtotaSummary(dataBDP.bahan ?? []),
      },
      { nama_bdp: 'BDP Upah', total: getGrandtotaSummary(dataBDP.upah ?? []) },
      {
        nama_bdp: 'BDP Alat & Mesin',
        total: getGrandtotaSummary(dataBDP.alat_mesin ?? []),
      },
      {
        nama_bdp: 'BDP Subcont',
        total: getGrandtotaSummary(dataBDP.subkon ?? []),
      },
      {
        nama_bdp: 'BDP Overhead',
        total: getGrandtotaSummary(dataBDP.overhead ?? []),
      },
      {
        nama_bdp: 'BDP Sisa Produksi',
        total: Math.abs(getGrandtotaSummary(dataBDP.sisa ?? [])) * -1,
      },
    ],
    [dataBDP]
  );

  const totalSummary = useMemo(
    () => summary.reduce((sum, item) => sum + item.total, 0),
    [summary]
  );

  return (
    <HPPContext.Provider
      value={{
        summary,
        totalSummary,
        dataBDP,
        setDataBDP,
        dataInfo,
        setDataInfo,
      }}
    >
      {children}
    </HPPContext.Provider>
  );
};

export const withHPPProvider = (Component) => (props) => {
  return (
    <HPPProvider>
      <Component {...props} />
    </HPPProvider>
  );
};
