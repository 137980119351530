import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import Axios from 'axios';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DataStatus,
  UpdateButton,
  DeleteButton,
  Alert,
  Switch,
  Pagination,
  Th,
  Td,
  ThFixed,
  ExportButton,
  FilterButton,
} from 'components';
import { ItemOverheadApi } from 'api';
import { TableNumber } from 'utilities';
import FileSaver from 'file-saver';
import { useIsGuest, useSessionStorage } from 'hooks';
import { ModalForm, ModalFilter, ModalDelete } from './Components';

const RegistrasiOverhead = ({ setNavbarTitle }) => {
  const title = 'Item Overhead';
  const isGuest = useIsGuest();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;
  const { REACT_APP_FILE_BASE_URL_2 } = process.env;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    id_satuan: undefined,
    id_kelompok: undefined,
  });
  const [dropdown, setDropdown] = useState({
    buaso: [],
    kelompok: [],
    satuan: [],
    coa: [],
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: null,
    data: null,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const fetchData = () => {
    setIsLoading(true);

    const query = { ...pagination };
    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    ItemOverheadApi.getPage(query)
      .then((res) => {
        setData(res?.data?.data ?? []);

        setPagination((prev) => ({
          ...prev,
          total_page: res.data.total_page,
          data_count: res.data.data_count,
        }));
      })
      .finally(() => setIsLoading(false));
  };

  const fetchDropdown = () => {
    Axios.all([
      ItemOverheadApi.getBuaso(),
      ItemOverheadApi.getSatuan(),
      ItemOverheadApi.getKelompok(),
      ItemOverheadApi.getCOA(),
    ])
      .then(
        Axios.spread((resBuaso, resSatuan, resKelompok, resCOA) => {
          const buaso = resBuaso?.data?.data?.map((v) => ({
            ...v,
            value: v.id_buaso,
            label: `${v.kode_buaso} - ${v.nama_buaso}`,
          }));

          const kelompok = resKelompok?.data?.data?.map((v) => ({
            ...v,
            value: v.id_kelompok,
            label: v.nama_kelompok,
          }));

          const satuan = resSatuan?.data?.data?.map((v) => ({
            ...v,
            value: v.id_satuan,
            label: v.nama_satuan,
          }));

          const coa = resCOA?.data?.data?.map((v) => ({
            ...v,
            value: v.nomor_akun,
            label: `${v.nomor_akun} - ${v.nama_akun}`,
          }));

          setDropdown({ buaso, kelompok, satuan, coa });
        })
      )
      .catch(({ response }) =>
        alert(`Gagal memuat Dropdown ${response?.data?.message ?? ''}`)
      )
      .finally(() => setIsLoading(false));
  };

  const changeDataStatus = (status, id_item_buaso) => {
    const onLoadedSuccess = () => {
      setAlert({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
    };

    const onLoadedFailed = (response) => {
      setAlert({
        variant: 'danger',
        text: `Ubah status data gagal ${response?.data?.message ?? ''}`,
      });
    };

    status === true
      ? ItemOverheadApi.show({ id_item_buaso })
          .then(() => onLoadedSuccess())
          .catch(({ response }) => onLoadedFailed(response))
          .finally(() => fetchData())
      : ItemOverheadApi.hide({ id_item_buaso })
          .then(() => onLoadedSuccess())
          .catch(({ response }) => onLoadedFailed(response))
          .finally(() => fetchData());
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(
    () => fetchData(),
    [
      pagination.q,
      pagination.page,
      pagination.per_page,
      pagination.id_kelompok,
      pagination.id_satuan,
      pagination.active,
    ]
  );

  useEffect(() => {
    setNavbarTitle(title);
    fetchDropdown();
  }, []);

  const TableContent = () => {
    return (
      <>
        <CRUDLayout.Table>
          <thead>
            <tr className="text-center">
              <Th>No.</Th>
              {!isGuest && <Th className="text-center">Aksi</Th>}
              <ThFixed>Kode Item Overhead</ThFixed>
              <Th>Nama Item Overhead</Th>
              <ThFixed className="text-nowrap">Kelompok Item Overhead</ThFixed>
              <ThFixed>Satuan Pakai</ThFixed>
              <Th>COA</Th>
              <ThFixed>Persentase Pembebanan</ThFixed>
              <Th>Keterangan</Th>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              return (
                <tr key={val.id_item_buaso}>
                  <Td className="text-center" width="20px">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>
                  {!isGuest && (
                    <Td width="100px">
                      <div className="d-flex justify-content-center">
                        <UpdateButton
                          size="sm"
                          className="m-1"
                          onClick={() =>
                            setModalConfig({
                              show: true,
                              type: 'update',
                              data: val,
                            })
                          }
                        />
                        <DeleteButton
                          size="sm"
                          className="m-1"
                          onClick={() =>
                            setModalConfig({
                              show: true,
                              type: 'delete',
                              data: val,
                            })
                          }
                        />

                        <Switch
                          id={val.id_item_buaso}
                          checked={val.is_hidden ? false : true}
                          wrapperClassName="m-1"
                          onChange={() =>
                            changeDataStatus(val.is_hidden, val.id_item_buaso)
                          }
                        />
                      </div>
                    </Td>
                  )}
                  <Td width="20px">{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td>{`${val.no_coa} - ${val.nama_akun}`}</Td>
                  <Td textRight>
                    {val.persentase_pembebanan
                      ? `${parseFloat(val.persentase_pembebanan)}%`
                      : ''}
                  </Td>
                  <Td>{val.keterangan}</Td>
                </tr>
              );
            })}
          </tbody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={pagination.per_page}
          dataPage={
            pagination.data_count <= 10
              ? data.length
              : data.map((_, index) => {
                  if (index === data.length - 1) {
                    return TableNumber(
                      pagination.page,
                      pagination.per_page,
                      index
                    );
                  }
                })
          }
          dataNumber={data.map((_, index) => {
            if (index === 0) {
              return TableNumber(pagination.page, pagination.per_page, index);
            }
          })}
          dataCount={pagination.data_count}
          onDataLengthChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              per_page: e.target.value,
              page: 1,
            }));
          }}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({ ...prev, page: selected + 1 }))
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
            <FilterButton
              active={pagination.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          <ExportButton
            onClick={() => {
              setIsLoading(true);
              ItemOverheadApi.export()
                .then((val) => {
                  const urlFile = REACT_APP_FILE_BASE_URL_2 + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
            className="mr-2"
          />
          <CreateButton
            onClick={() =>
              setModalConfig({
                show: true,
                type: 'create',
                data: null,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        showCloseButton
        show={Boolean(alert?.text?.length)}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert({ text: '', variant: '' })}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : data?.length ? (
        <TableContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalForm
        dropdown={dropdown}
        modalState={modalConfig}
        setAlert={setAlert}
        fetchData={fetchData}
        onHide={() => setModalConfig({ show: false, type: null, data: null })}
      />

      <ModalDelete
        modalState={modalConfig}
        setAlert={setAlert}
        fetchData={fetchData}
        onHide={() => setModalConfig({ show: false, type: null, data: null })}
      />

      <ModalFilter
        dropdown={dropdown}
        show={modalFilter}
        setShow={setModalFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default RegistrasiOverhead;
