import { Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { InfoItemVertical } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

export const InfoSectionHPP = ({ data }) => {
  return (
    <Fragment>
      <div style={{ fontSize: '.9rem' }}>Detail Data HPP</div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <InfoItemVertical
                label="Tgl. HPP"
                text={
                  data?.tgl_hpp
                    ? DateConvert(new Date(data.tgl_hpp)).detail
                    : '-'
                }
              />

              <InfoItemVertical
                label="Qty. Posting HPP"
                text={`${parseFloat(data?.qty_hpp ?? 0)} ${
                  data.nama_satuan ?? ''
                }`}
              />

              <InfoItemVertical
                label="COA Debet"
                text={`${data.no_coa_debet ?? ''} - ${
                  data.nama_akun_debet ?? ''
                }`}
              />

              <InfoItemVertical
                label="COA Kredit"
                text={`${data.no_coa_kredit ?? ''} - ${
                  data.nama_akun_kredit ?? ''
                }`}
              />

              <InfoItemVertical
                label="Keterangan Jurnal"
                text={data.keterangan_jurnal ?? ''}
              />
            </Col>

            <Col>
              <InfoItemVertical label="No. HPP" text={data.no_hpp ?? ''} />

              <InfoItemVertical
                label="Jumlah HPP"
                text={
                  RupiahConvert(String(parseFloat(data?.jumlah_hpp ?? 0)))
                    .detail
                }
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};
