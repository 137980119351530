import Services from 'services';

class PostingBiayaPrelimApi {
  page(params) {
    return Services.get('/posting_biaya_prelim/page', { params });
  }

  pageSPK(params) {
    return Services.get('/posting_biaya_prelim/spk_page', { params });
  }

  single(params) {
    return Services.get('/posting_biaya_prelim/single', { params });
  }

  singleSPK(params) {
    return Services.get('/posting_biaya_prelim/single_spk', { params });
  }

  realisasi(params) {
    return Services.get('/posting_biaya_prelim/detail', { params });
  }

  history(params) {
    return Services.get('/posting_biaya_prelim/histori', { params });
  }

  historySingle(params) {
    return Services.get('/posting_biaya_prelim/single_histori', { params });
  }

  nomor(params) {
    return Services.get('/posting_biaya_prelim/no_baru', { params });
  }

  coa(params) {
    return Services.get('/posting_biaya_prelim/dropdown', { params });
  }

  save(data) {
    return Services.post('/posting_biaya_prelim', data);
  }
}

export default new PostingBiayaPrelimApi();
