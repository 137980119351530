import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';

const Dashboard = ({ setNavbarTitle }) => {
  useEffect(() => {
    setNavbarTitle('Dashboard');
  }, [setNavbarTitle]);

  return (
    <>
      <Card className="mb-3">
        <h3 className="p-3">Dashboard</h3>
      </Card>
    </>
  );
};

export default Dashboard;
