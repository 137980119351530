import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoEyeOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import { Td, Th, ThFixed, Table, ActionButton, DataStatus } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { useIsGuest } from 'hooks';
import { calcGrandTotal } from './calculateOpname';
import ModalJurnalBDP from './ModalJurnalBDP';
import ModalJurnalBDPDetail from './ModalJurnalBDPDetail';
import DetailKontrak from './DetailKontrak';

const TableRealisasiUpah = ({
  dataList,
  dataHistory,
  getInitialData,
  refKey,
}) => {
  const isGuest = useIsGuest();
  const [modalConfig, setModalConfig] = useState({
    type: '',
    show: false,
    data: {},
  });

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((val, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index}>
                  {val.uraian}
                </li>
              );
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index}>
                    {val.uraian}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed className="py-2 text-center">No.</ThFixed>
            <ThFixed className="p-2 text-center">
              Informasi Realisasi Produksi
            </ThFixed>
            <Th className="p-2 text-center">Vendor</Th>
            <Th className="p-2 text-center">Rincian Pekerjaan</Th>
            <Th className="p-1 text-center">Total Upah Kerja</Th>
            <Th className="p-1 text-center">Pembayaran Periode Ini</Th>
            {!isGuest && <ThFixed className="p-0 text-center">Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {dataList?.filter((item) => item.ref === refKey)?.length > 0 ? (
            dataList
              ?.filter((item) => item.ref === refKey)
              ?.map((val, index) => {
                const isInHistory = dataHistory?.find(
                  (e) =>
                    e.id_realisasi_produksi_upah ===
                      val.id_realisasi_produksi_upah && true
                );
                const calc = calcGrandTotal(val?.rincian);

                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      <span className="d-block">{`${
                        val.tgl_realisasi_produksi_upah
                          ? DateConvert(
                              new Date(val.tgl_realisasi_produksi_upah)
                            ).defaultDMY
                          : '-'
                      }`}</span>
                      <span className="d-block">{`${
                        val.no_realisasi_produksi_upah ?? ''
                      }`}</span>
                    </Td>
                    <Td>{val.nama_vendor ?? '-'}</Td>
                    <Td>
                      {val.rincian && val.rincian?.length > 0 ? (
                        <ItemCollapse data={val.rincian} />
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(String(parseInt(calc.totalJumlah ?? 0)))
                          .detail
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(parseInt(calc.totalPeriodeIni ?? 0))
                        ).detail
                      }
                    </Td>
                    {!isGuest && (
                      <Td className="text-center">
                        {!isInHistory && (
                          <ButtonGroup>
                            <ActionButton
                              variant="success"
                              onClick={() =>
                                setModalConfig({
                                  type: 'kontrak',
                                  show: true,
                                  data: val.id_kontrak_upah,
                                })
                              }
                              size="sm"
                            >
                              <IoDocumentTextOutline />
                            </ActionButton>

                            <ActionButton
                              size="sm"
                              onClick={() =>
                                setModalConfig({
                                  type: 'detail',
                                  show: true,
                                  data: val,
                                })
                              }
                            >
                              <IoEyeOutline />
                            </ActionButton>

                            <ActionButton
                              size="sm"
                              style={{ backgroundColor: '#1956b9' }}
                              onClick={() =>
                                setModalConfig({
                                  type: 'create',
                                  show: true,
                                  data: val,
                                })
                              }
                              disable={Boolean(val.isAdded || isInHistory)}
                            >
                              <IoAddOutline />
                            </ActionButton>
                          </ButtonGroup>
                        )}
                      </Td>
                    )}
                  </tr>
                );
              })
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {modalConfig.show && modalConfig.type === 'create' && (
        <ModalJurnalBDP
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          getInitialData={getInitialData}
        />
      )}

      {modalConfig.show && modalConfig.type === 'detail' && (
        <ModalJurnalBDPDetail
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}

      {modalConfig.show && modalConfig.type === 'kontrak' && (
        <DetailKontrak
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          id={modalConfig.data}
        />
      )}
    </>
  );
};

export default TableRealisasiUpah;
