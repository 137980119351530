import React from 'react';

const InputFile = ({
  label,
  type,
  name,
  size,
  value,
  placeholder,
  onChange,
  accept,
  error,
  errorText,
  className,
  wrapperClassName,
  style,
  wrapperStyle,
  noMargin,
  ...rest
}) => {
  return (
    <div
      className={`${noMargin ? 'm-0' : 'mb-2'} ${wrapperClassName}`}
      style={wrapperStyle}
    >
      <small>{label}</small>
      <input
        {...rest}
        type="file"
        name={name}
        value={value}
        accept={accept}
        onChange={onChange}
        className={`form-control-file form-control-${size ? size : 'sm'} ${
          error && 'is-invalid'
        } ${className}`}
      />
      <div className="invalid-feedback">{errorText}</div>
    </div>
  );
};

export default InputFile;
