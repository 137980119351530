import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { range, debounce } from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  SelectMonth,
  FilterButton,
} from 'components';
import { DateConvert } from 'utilities';
import { useSessionStorage } from 'hooks';
import { HargaSatuanRataRataPenyusutanApi } from 'api';
import {
  TableContent,
  ModalFilterHSRPenyusutan,
  CustomSelect,
} from './components';
import axios from 'axios';

const HargaSatuanRataRataPenyusutan = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const today = new Date();
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };

  const [data, setData] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });
  const [dropdown, setDropdown] = useState({
    kategori: [],
    jenis: [],
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [filter, setFilter] = useSessionStorage(SESSION_KEY.FILTER, {
    active: false,
    id_jenis_aset: null,
    id_kategori_aset: null,
  });
  const [pagination, setPagination] = useSessionStorage(
    SESSION_KEY.PAGINATION,
    {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    }
  );

  const getInitialData = () => {
    const date = `${dateSelected.year}-${dateSelected.month}-01`;

    const query = {
      q: searchConfig.key,
      page: pagination.page,
      per_page: pagination.dataLength,
      id_jenis_aset: filter.id_jenis_aset,
      id_kategori_aset: filter.id_kategori_aset,
      tanggal: date,
    };

    setPageLoading(true);

    HargaSatuanRataRataPenyusutanApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination({
          ...pagination,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setPageLoading(false));
  };

  const getDropdown = () => {
    axios
      .all([
        HargaSatuanRataRataPenyusutanApi.dropdown({ tipe: 'jenis' }),
        HargaSatuanRataRataPenyusutanApi.dropdown({ tipe: 'kategori' }),
      ])
      .then(
        axios.spread((resJenis, resKategori) => {
          const jenis = resJenis.data.data.map((j) => ({
            value: j.id_jenis_aset,
            label: j.nama_jenis_aset,
          }));

          const kategori = resKategori.data.data.map((k) => ({
            value: k.id_kategori_aset,
            label: k.nama_kategori_aset,
          }));

          setDropdown({ kategori, jenis });
        })
      );
  };

  const searchHandler = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key,
    });
    setPagination({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
  };

  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchConfig.key,
    pagination.page,
    pagination.dataLength,
    filter.id_kategori_aset,
    filter.id_jenis_aset,
    dateSelected.month,
    dateSelected.year,
  ]);

  useEffect(() => {
    setNavbarTitle('Harga Satuan Rata-rata Penyusutan');
    getDropdown();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  return (
    <CRUDLayout>
      <Row className="mb-2 d-flex justify-content-end align-items-center">
        <Col
          md={5}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            value={filter?.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex flex-row justify-content-end align-items-center">
          <div className="d-flex align-items-center">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });

                setPagination({
                  ...pagination,
                  page: '1',
                });
              }}
            />
            <CustomSelect
              className="m-1"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });

                setPagination({
                  ...pagination,
                  page: '1',
                });
              }}
            >
              <option value="">Pilih tahun</option>
              {range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </CustomSelect>

            <ActionButton
              className="ml-1"
              text="Posting Data"
              variant="primary"
              onClick={() =>
                history.push(
                  '/transaksi/harga-satuan-rata-rata-penyusutan/tambah'
                )
              }
            />
          </div>
        </Col>
      </Row>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent
          data={data}
          searchConfig={searchConfig}
          paginationConfig={pagination}
          setPaginationConfig={setPagination}
        />
      )}
      {showFilter && (
        <ModalFilterHSRPenyusutan
          show={showFilter}
          setShow={setShowFilter}
          filter={filter}
          setFilter={setFilter}
          dropdown={dropdown}
          SESSION_KEY={SESSION_KEY}
        />
      )}
    </CRUDLayout>
  );
};

export default HargaSatuanRataRataPenyusutan;
