import React, { useState } from 'react';
import {
  DataStatus,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  ReadButton,
} from 'components';
import { RupiahConvert, DateConvert, TableNumber } from 'utilities';
import ModalDetailJurnal from '../../comps/ModalDetailJurnal';

const PageListBDPAlatMesin = ({
  dataBDPAlat,
  paginationConfig,
  setPaginationConfig,
  searchConfig,
  isPageLoading,
}) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  if (!dataBDPAlat || dataBDPAlat.length < 1 || isPageLoading.alat) {
    if (isPageLoading.alat)
      return <DataStatus loading={isPageLoading.alat} text="Memuat data ..." />;
    return <DataStatus text="Tidak ada data" />;
  }

  return (
    <div className="px-2 py-3">
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi BDP</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <ThFixed>Informasi RAP</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th style={{ minWidth: '200px' }}>Item Alat Mesin</Th>
            <Th style={{ minWidth: '100px' }}>Qty</Th>
            <Th style={{ minWidth: '100px' }}>Harga Satuan</Th>
            <Th style={{ minWidth: '100px' }}>Jumlah</Th>
            <Th style={{ minWidth: '250px' }}>Akun COA</Th>
            <Th style={{ minWidth: '200px' }}>Keterangan Jurnal</Th>
          </tr>
        </thead>
        <tbody>
          {dataBDPAlat.map((val, index) => (
            <tr key={index}>
              <Td>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </Td>
              <Td>
                <ReadButton
                  size="sm"
                  onClick={() => setModalConfig({ show: true, data: val })}
                />
              </Td>

              <Td>
                {val.tgl_bdp_alat_mesin
                  ? DateConvert(new Date(val.tgl_bdp_alat_mesin)).defaultDMY
                  : '-'}
                {`\n ${val.no_bdp_alat_mesin ?? ''}`}
              </Td>

              <Td>
                {val.tgl_spk
                  ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                  : '-'}
                {`\n ${val.no_spk ?? ''}`}
              </Td>

              <Td>
                {val.tgl_rap
                  ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                  : '-'}
                {`\n ${val.no_rap ?? ''}`}
              </Td>

              <Td>
                {val.tgl_job_order
                  ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                  : '-'}
                {`\n ${val.no_job_order ?? ''}`}
              </Td>
              <Td>{val.nama_item ?? '-'}</Td>
              <Td>{`${val.qty_realisasi_produksi_alat_mesin ?? '0'} ${
                val.nama_satuan ?? ''
              }`}</Td>
              <Td className="text-right">
                {
                  RupiahConvert(
                    String(parseInt(val.harga_satuan_rata_rata_pakai ?? 0))
                  ).detail
                }
              </Td>
              <Td className="text-right">
                {RupiahConvert(String(parseInt(val.jumlah ?? 0))).detail}
              </Td>
              <Td>
                <span className="d-block">{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debet}`}</span>
                <span className="d-block">{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
              </Td>
              <Td>{val.keterangan_jurnal}</Td>
            </tr>
          ))}
        </tbody>
      </Table>

      {!searchConfig.status && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}

      {/* Modal BDP */}
      <ModalDetailJurnal
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
        BDPType="Alat Mesin"
      />
    </div>
  );
};

export default PageListBDPAlatMesin;
