import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPAlatMesinApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { hasNotifCheck } from 'pages/Transaksi/BDPProduksi/Helpers';
import TableHistoryAlatMesin from './comps/TableHistoryAlatMesin';
import TableRealisasiAlatMesin from './comps/TableRealisasiAlatMesin';

const MachineProcessSection = ({ machineProcess, setMachineProcess }) => {
  const location = useLocation();
  const {
    id_realisasi_produksi: id_realisasi_produksi_master,
    no_realisasi_produksi,
  } = location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [historyMachineProcess, setHistoryMachineProcess] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPAlatMesinApi.getSingle({ no_realisasi_produksi }),
      BDPAlatMesinApi.getSingleHistory({
        id_job_order,
        id_realisasi_produksi: id_realisasi_produksi_master,
      }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data?.realisasi_alat_mesin;
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setMachineProcess(data);
          setHistoryMachineProcess(history);

          const hasNotif = hasNotifCheck(data, history);
          setNotif((prev) => ({
            ...prev,
            bdp_alat_mesin: {
              status: hasNotif,
              mp: hasNotif,
            },
          }));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getInitialData(), []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Machine Process
      </small>
      <TableRealisasiAlatMesin
        dataList={machineProcess}
        dataHistory={historyMachineProcess}
        getInitialData={getInitialData}
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistoryAlatMesin
        type="detail"
        dataHistory={historyMachineProcess}
      />
    </div>
  );
};

export default MachineProcessSection;
