import { useState, useContext } from 'react';
import { DateConvert } from 'utilities';
import { TransferProduksiOverheadApi } from 'api';
import { TransferOverheadContext } from '../Context';

const formatMonthYear = (date) => {
  const fmtDate = DateConvert(new Date(date));

  return `${fmtDate.defaultMonth}/${fmtDate.defaultYear}`;
};

const getNomorTransferBahan = async (date) => {
  const tanggal = DateConvert(date).default;

  return await TransferProduksiOverheadApi.no_baru({ tanggal })
    .then((res) => res.data.data)
    .catch(() => alert('Gagal memuat nomor Transfer Produksi Overhead'));
};

export const useMockNumber = () => {
  const [mockNumber, setMockNumber] = useState({});
  const { dataTransfer } = useContext(TransferOverheadContext);

  const generateMockNumber = async (date) => {
    const selectedMonthYear = formatMonthYear(date);

    if (mockNumber.hasOwnProperty(selectedMonthYear)) {
      const count =
        dataTransfer?.filter(
          (val) =>
            formatMonthYear(val.tgl_transfer_produksi_overhead) ===
            selectedMonthYear
        ).length ?? 0;

      const splitNumber = mockNumber[selectedMonthYear].split('/');
      const lastIndex = splitNumber.length - 1;
      const incrementLastNumber = +splitNumber[lastIndex] + count;
      const formatIncrementLastNumber = ('000000' + incrementLastNumber).slice(
        -6
      );
      splitNumber.splice(lastIndex, 1, formatIncrementLastNumber);

      return splitNumber.join('/');
    } else {
      const serverNumber = await getNomorTransferBahan(date);
      setMockNumber((prev) => ({ ...prev, [selectedMonthYear]: serverNumber }));
      return serverNumber;
    }
  };

  return [generateMockNumber];
};
