import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { Formik } from 'formik';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  ThFixed,
  TdFixed,
  Th,
  Td,
  Pagination,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonDetail,
} from 'components2';
import { tableNumber, dateConvert, rupiahConvert } from 'utilities2';
import { useModalConfirm } from 'hooks2';
import {
  ModalFormRincian,
  PostingPettyCashContent,
} from './__PostingPettyCashComps__';
import { formSubmitValueMapper } from './__PostingPettyCashUtils__';
import PostingPettyCashApi from './__PostingPettyCashApi__';

export const PostingPettyCashList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const modalConfirm = useModalConfirm();
  const { REACT_APP_SHOW_FILE_BASE_URL } = process.env;
  const [pagination, setPagination] = useState({
    q: '',
    page: 1,
    per_page: 10,
  });
  const [modal, setModal] = useState({
    type: 'CREATE', // CREATE | UPDATE | DETAIL
    show: false,
    id_posting_petty_cash: null,
  });

  const getPostingPettyCash = useQuery(
    ['posting_petty_cash', 'list', pagination],
    () => PostingPettyCashApi.getList(pagination)
  );

  const getSinglePostingPettyCash = useQuery(
    ['single', 'posting_petty_cash', modal.id_posting_petty_cash],
    () =>
      PostingPettyCashApi.getSingle({
        id_posting_petty_cash: modal.id_posting_petty_cash,
      })
        .then(({ data }) => data)
        .then((data) => {
          const files = data?.media?.map((e) => ({
            nama: e?.path_media?.split('/').pop(),
            link: `${REACT_APP_SHOW_FILE_BASE_URL}/${e?.path_media}`,
            path_media: e?.path_media,
          }));
          return { ...data, files };
        })
        .finally(() => setModal((prev) => ({ ...prev, show: true }))),
    { enabled: !!modal.id_posting_petty_cash }
  );

  const createPengajuanPettyCash = useMutation((data) =>
    PostingPettyCashApi.create(data)
  );

  const updatePengajuanPettyCash = useMutation((data) =>
    PostingPettyCashApi.update(data)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setPagination({
      ...pagination,
      q: searchKey,
    });
  };

  const onCreateButtonClickHandler = () =>
    history.push('/transaksi/posting-petty-cash/realisasi-petty-cash');

  const onDetailButtonClickHandler = (data) =>
    setModal((prev) => ({
      ...prev,
      type: 'DETAIL',
      id_posting_petty_cash: data.id_posting_petty_cash,
    }));

  const onPaginationChange = ({ selected }) =>
    setPagination({ ...pagination, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setPagination({ ...pagination, page: 1, per_page: e.target.value });

  const onSubmitForm = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    await modalConfirm.trigger({
      size: 'md',
      type: modal.type === 'CREATE' ? 'create' : 'update',
      component: (
        <Formik initialValues={values}>
          <PostingPettyCashContent action="DETAIL" />
        </Formik>
      ),
      onSubmit: async () => {
        modalConfirm.loading();

        if (modal.type === 'CREATE') {
          return await createPengajuanPettyCash
            .mutateAsync(formSubmitValueMapper(values), resetForm)
            .then(() => {
              modalConfirm.infoSuccess({
                size: 'md',
                onHide: () => {
                  modalConfirm.close();
                  dismissModal(resetForm);
                },
              });
              getPostingPettyCash.refetch();
            })
            .catch(() => modalConfirm.infoError());
        }

        await updatePengajuanPettyCash
          .mutateAsync(formSubmitValueMapper(values))
          .then(() => {
            modalConfirm.infoSuccess({
              size: 'md',
              onHide: () => {
                modalConfirm.close();
                dismissModal(resetForm);
              },
            });
            getPostingPettyCash.refetch();
          })
          .catch(() => modalConfirm.infoError());
      },
      onHide: () => modalConfirm.close(),
    });
  };

  const dismissModal = (resetForm) => {
    resetForm();
    setModal({ data: null, show: false, type: '' });
  };

  useEffect(() => setNavbarTitle('Posting Petty Cash'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 1500)} />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate size="md" icon onClick={onCreateButtonClickHandler} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getPostingPettyCash.isLoading || getPostingPettyCash.isError ? (
        <DataStatus
          loading={getPostingPettyCash.isLoading}
          text={
            getPostingPettyCash.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Posting Petty Cash
            </small>
          </div>
          <Table>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Posting Petty Cash
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Informasi Realisasi Petty Cash
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Item
                </Th>
                <Th style={{ minWidth: 150 }} className="p-1">
                  Jumlah
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  COA Debet
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  COA Kredit
                </Th>
                <ThFixed>Aksi</ThFixed>
              </tr>
            </thead>
            <tbody>
              {getPostingPettyCash?.data?.data?.length > 0 ? (
                getPostingPettyCash?.data?.data?.map((e, index) => (
                  <tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: pagination.page,
                        dataLength: pagination.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>
                      <div>
                        {e.tgl_posting_petty_cash
                          ? dateConvert().getSlashDMY(
                              new Date(e.tgl_posting_petty_cash)
                            )
                          : '-'}
                      </div>
                      <div>{e.no_posting_petty_cash}</div>
                    </Td>
                    <Td>
                      <div>
                        {e.tgl_realisasi_petty_cash
                          ? dateConvert().getSlashDMY(
                              new Date(e.tgl_realisasi_petty_cash)
                            )
                          : '-'}
                      </div>
                      <div>{e.no_realisasi_petty_cash}</div>
                    </Td>
                    <Td>{e.nama_item}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(
                        e.jumlah_realisasi_petty_cash
                      )}
                    </Td>
                    <Td>{`${e.nomor_akun_debet ?? ''} - ${
                      e.nama_akun_debet ?? ''
                    }`}</Td>
                    <Td>{`${e.nomor_akun_kredit ?? ''} - ${
                      e.nama_akun_kredit ?? ''
                    }`}</Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() => onDetailButtonClickHandler(e)}
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </tr>
                ))
              ) : (
                <tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            dataLength={pagination?.per_page}
            dataNumber={
              pagination?.page * pagination?.per_page - pagination?.per_page + 1
            }
            dataPage={
              pagination?.dataCount < pagination?.per_page
                ? pagination?.dataCount
                : pagination?.page * pagination?.per_page
            }
            dataCount={getPostingPettyCash?.data?.data_count}
            currentPage={pagination?.page}
            totalPage={getPostingPettyCash?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      <Formik
        enableReinitialize
        initialValues={getSinglePostingPettyCash.data}
        onSubmit={onSubmitForm}
      >
        <ModalFormRincian
          type="DETAIL"
          onHide={() => setModal({ type: '', show: false, data: {} })}
          show={modal.show}
        />
      </Formik>
    </CRUDLayout>
  );
};
