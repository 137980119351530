import { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  SelectSearch,
  Select,
  Input,
  ActionButton,
  InputCurrency,
} from 'components';
import { ItemOverheadApi } from 'api';

export const ModalForm = ({
  dropdown,
  modalState,
  setAlert,
  fetchData,
  onHide,
}) => {
  const { show, type, data } = modalState;
  const isCreate = type === 'create';
  const formikRef = useRef(null);

  const CONF = {
    create: {
      title: 'Tambah',
    },
    update: {
      title: 'Ubah',
    },
  };

  const initialValues = {
    id_item_buaso: data?.id_item_buaso ?? undefined,
    id_buaso: data?.id_buaso ?? '5',
    kode_item: data?.kode_item,
    nama_item: data?.nama_item,
    keterangan: data?.keterangan,
    id_satuan: data?.id_satuan,
    id_kelompok: data?.id_kelompok,
    no_coa: data?.no_coa,
    persentase_pembebanan: parseFloat(data?.persentase_pembebanan),
  };

  const validationSchema = Yup.object().shape({
    nama_item: Yup.string().required('Masukkan Nama Item Overhead'),
    id_satuan: Yup.string().required('Pilih Satuan'),
    id_kelompok: Yup.string().required('Pilih Kelompok'),
    no_coa: Yup.string().required('Pilih COA'),
    persentase_pembebanan: Yup.string()
      .required('Nilai Persentase Pembebanan diperlukan')
      .test(
        'maxValueIs100',
        'Maksimal Nilai Persentase Pembebanan 100%',
        (value, _) => parseFloat(value) <= 100
      ),
  });

  const onSubmit = async (values, { resetForm }) => {
    if (isCreate) {
      return await ItemOverheadApi.create(values)
        .then(() => {
          resetForm();
          onHide();
          setAlert({
            text: 'Berhasil menyimpan Item Overhead',
            variant: 'primary',
          });
          fetchData();
        })
        .catch(({ response }) =>
          alert(
            `Gagal menyimpan Item Overhead ${response?.data?.message ?? ''}`
          )
        );
    }

    return await ItemOverheadApi.update(values)
      .then(() => {
        resetForm();
        onHide();
        setAlert({
          text: 'Berhasil update Item Overhead',
          variant: 'primary',
        });
        fetchData();
      })
      .catch(({ response }) =>
        alert(`Gagal update Item Overhead ${response?.data?.message ?? ''}`)
      );
  };

  // Fetch Kode Item Overhead
  useEffect(() => {
    console.log('fetch');
    if (isCreate && formikRef.current) {
      (async () =>
        ItemOverheadApi.generateKodeItem()
          .then((res) =>
            formikRef.current.setFieldValue('kode_item', res.data.data)
          )
          .catch(({ response }) =>
            alert(
              `Gagal memuat Kode Item Overhead ${response?.data?.message ?? ''}`
            )
          ))();
    }
  }, [formikRef.current, modalState]);

  return (
    <Modal show={show && type !== 'delete'} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="h6">{`${CONF[type]?.title} Data Item Overhead`}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Select
                label="BUASO"
                name="id_buaso"
                onChange={handleChange}
                errorText={errors.id_buaso}
                defaultValue={values.id_buaso}
                disabled={true}
              >
                {dropdown?.buaso.map((e) => (
                  <option key={e.id_buaso} value={e.id_buaso}>
                    {e.kode_buaso} - {e.nama_buaso}
                  </option>
                ))}
              </Select>

              <Input
                label="Kode Overhead"
                type="text"
                name="kode_item"
                placeholder="Kode Overhead"
                value={values.kode_item}
                onChange={handleChange}
              />

              <Input
                label="Nama Item Overhead"
                type="text"
                name="nama_item"
                placeholder="Item Overhead"
                value={values.nama_item}
                onChange={handleChange}
                error={errors.nama_item && touched.nama_item && true}
                errorText={errors.nama_item}
              />

              <SelectSearch
                label="Kelompok Item Overhead"
                name="id_kelompok"
                placeholder="Pilih Kelompok"
                onChange={(val) => setFieldValue('id_kelompok', val.value)}
                option={dropdown?.kelompok ?? []}
                defaultValue={
                  values?.id_kelompok
                    ? dropdown?.kelompok?.find(
                        (v) => v.value === values?.id_kelompok
                      )
                    : null
                }
                error={errors.id_kelompok && touched.id_kelompok && true}
                errorText={
                  errors.id_kelompok &&
                  touched.id_kelompok &&
                  errors.id_kelompok
                }
              />

              <SelectSearch
                label="Satuan Pakai"
                name="id_satuan"
                placeholder="Pilih Satuan Pakai"
                onChange={(val) => setFieldValue('id_satuan', val.value)}
                option={dropdown?.satuan ?? []}
                defaultValue={
                  values?.id_satuan
                    ? dropdown?.satuan?.find(
                        (v) => v.value === values.id_satuan
                      )
                    : null
                }
                error={errors.id_satuan && touched.id_satuan && true}
                errorText={
                  errors.id_satuan && touched.id_satuan && errors.id_satuan
                }
              />

              <SelectSearch
                label="COA"
                name="no_coa"
                placeholder="Pilih COA"
                onChange={(val) => setFieldValue('no_coa', val.value)}
                option={dropdown?.coa ?? []}
                defaultValue={
                  values?.no_coa
                    ? dropdown?.coa?.find((v) => v.value === values.no_coa)
                    : null
                }
                error={errors.no_coa && touched.no_coa && true}
                errorText={errors.no_coa && touched.no_coa && errors.no_coa}
              />

              <InputCurrency
                prefix=""
                suffix="%"
                className="text-right"
                label="Persentase Pembebanan"
                placeholder="Persentase Pembebanan"
                value={values.persentase_pembebanan}
                onChange={(v) => setFieldValue('persentase_pembebanan', v)}
                error={
                  errors.persentase_pembebanan &&
                  touched.persentase_pembebanan &&
                  true
                }
                errorText={errors.persentase_pembebanan}
              />

              <Input
                label="Keterangan"
                type="text"
                name="keterangan"
                placeholder="Keterangan"
                value={values.keterangan}
                onChange={handleChange}
              />
            </Modal.Body>

            <Modal.Footer>
              <ActionButton
                type="submit"
                variant={isCreate ? 'primary' : 'success'}
                text={isCreate ? 'Tambah' : 'Ubah'}
                className="mt-2 px-4"
                loading={isSubmitting}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
