import {
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  UpdateButton,
  DeleteButton,
  DataStatus,
} from 'components';
import { useContext } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { DateConvert } from 'utilities';
import { TransferOverheadContext } from '../Context';

export const TableTransfer = ({ tipe = 'editable', data = [] }) => {
  const isEditable = tipe === 'editable';
  const { setModalForm, setDataTransfer } = useContext(TransferOverheadContext);

  const deleteItemTransfer = (index) => {
    setDataTransfer((prv) => prv.toSpliced(index, 1));
  };

  return (
    <Table>
      <thead>
        <tr className="text-center">
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Transfer Overhead Produksi</ThFixed>
          <Th>Item Overhead</Th>
          <Th>Qty. </Th>
          <Th>Satuan</Th>
          <Th>Keterangan</Th>
          {isEditable && <ThFixed>Aksi</ThFixed>}
        </tr>
      </thead>

      <tbody>
        {data?.length ? (
          data?.map((val, index) => (
            <tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>
                <div>
                  {val.tgl_transfer_produksi_overhead
                    ? DateConvert(new Date(val.tgl_transfer_produksi_overhead))
                        .defaultDMY
                    : '-'}
                </div>
                {val.no_transfer_produksi_overhead}
              </Td>
              <Td>{val.nama_item}</Td>
              <Td textRight>
                {parseFloat(val.qty_transfer_produksi_overhead)}
              </Td>
              <Td>{val.nama_satuan}</Td>
              <Td>{val.keterangan}</Td>
              {isEditable && (
                <Td>
                  <ButtonGroup>
                    <UpdateButton
                      size="sm"
                      onClick={() =>
                        setModalForm({
                          show: true,
                          data: val,
                        })
                      }
                    />
                    <DeleteButton
                      size="sm"
                      onClick={() => deleteItemTransfer(index)}
                    />
                  </ButtonGroup>
                </Td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <Td colSpan={isEditable ? 9 : 8}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
