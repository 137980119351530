import React, { useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import LabourCostSection from './LabourCostSection';
import LabourCostFinSection from './LabourCostFinSection';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { BadgeNotif } from 'pages/Transaksi/BDPProduksi/Components';

const TabUpah = ({
  labourCost,
  setLabourCost,
  labourCostFin,
  setLabourCostFin,
}) => {
  const { notif } = useContext(NotifBDPContext);

  return (
    <Card className="border">
      <Tab.Container defaultActiveKey="labour_cost">
        <Card.Header className='className="d-flex justify-content-between align-items-center'>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="labour_cost">
                Labor Cost
                <BadgeNotif show={notif.bdp_upah.labour_cost} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="labour_cost_finishing">
                Labor Cost Finishing
                <BadgeNotif show={notif.bdp_upah.labour_cost_finishing} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="labour_cost">
            <LabourCostSection
              labourCost={labourCost}
              setLabourCost={setLabourCost}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="labour_cost_finishing">
            <LabourCostFinSection
              labourCostFin={labourCostFin}
              setLabourCostFin={setLabourCostFin}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default TabUpah;
