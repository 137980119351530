import { useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import { IoAlertCircleOutline } from 'react-icons/io5';
import { Modal, ActionButton } from 'components';
import { PostingBiayaPrelimApi } from 'api';

const ModalConfirm = ({
  show,
  data: DATA,
  onHide,
  setAlertConfig,
  addSubmitCount,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitHandler = () => {
    setIsSubmitting(true);

    PostingBiayaPrelimApi.save(DATA)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil di posting',
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal di posting',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
        onHide();
        addSubmitCount();
      });
  };

  return (
    <Modal
      closeButton
      title="Konfirmasi Posting Data"
      show={show}
      onHide={onHide}
    >
      <ModalBody className="text-center">
        <IoAlertCircleOutline size={80} className="text-danger mb-3" />
        <p>
          <b>Apakah anda yakin akan memposting data ini?</b>
          <br />
          <small className="text-danger">
            Jurnal yang telah diposting tidak dapat diedit!
          </small>
        </p>
        <div className="d-flex justify-content-center">
          <ActionButton
            variant="outline-danger"
            text="Batal"
            className="m-1 px-4"
            onClick={onHide}
          />
          <ActionButton
            variant="primary"
            text="Posting"
            className="m-1 px-4"
            loading={isSubmitting}
            onClick={onSubmitHandler}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalConfirm;
