import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPUpahApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import {
  hasNotifCheck,
  parentHasNotifCheck,
} from 'pages/Transaksi/BDPProduksi/Helpers';
import TableHistoryUpah from './comps/TableHistoryUpah';
import TableRealisasiUpah from './comps/TableRealisasiUpah';

const LabourCostSection = ({ labourCost, setLabourCost }) => {
  const location = useLocation();
  const { id_realisasi_produksi: id_realisasi_produksi_master } =
    location.state;
  const { id_job_order } = useParams();
  const [historyLabourCost, setHistoryLabourCost] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setNotif } = useContext(NotifBDPContext);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPUpahApi.single({
        id_job_order,
        ref: 'RLC',
        id_realisasi_produksi_master,
      }),
      BDPUpahApi.history({
        id_job_order,
        ref: 'RLC',
        id_realisasi_produksi_master,
      }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data ?? [];
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${
              item.nama_akun_debet ?? ''
            }`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${
              item.nama_akun_kredit ?? ''
            }`,
          }));

          setLabourCost(data);
          setHistoryLabourCost(history);

          const hasNotif = hasNotifCheck(data, history);
          setNotif((prev) => ({
            ...prev,
            bdp_upah: {
              ...prev.bdp_upah,
              status: parentHasNotifCheck(prev.bdp_upah, hasNotif),
              labour_cost: hasNotif,
            },
          }));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getInitialData(), []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Labour Cost
      </small>
      <TableRealisasiUpah
        dataList={labourCost}
        dataHistory={historyLabourCost}
        getInitialData={getInitialData}
        refKey="RLC"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistoryUpah
        type="detail"
        dataHistory={historyLabourCost}
        refKey="RLC"
      />
    </div>
  );
};

export default LabourCostSection;
