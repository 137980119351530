import Services from 'services';

class BDPAlatMesinApi {
  get(params) {
    return Services.get('/bdp_alat_mesin/page', { params });
  }

  getSingle(params) {
    return Services.get('/bdp_alat_mesin/single_realisasi', { params });
  }

  getSingleHistory(params) {
    return Services.get('/bdp_alat_mesin/page_bdp', { params });
  }

  getCOA() {
    return Services.get('/bdp_alat_mesin/dropdown');
  }

  getNomor(params) {
    return Services.get('/bdp_alat_mesin/no_baru', { params });
  }

  save(data) {
    return Services.post('/bdp_alat_mesin', data);
  }
}

export default new BDPAlatMesinApi();
