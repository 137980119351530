import { useFormik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { useContext } from 'react';
import { DropdownHPPContext, withDropdownHPPProvider } from '../Context';

export const ModalFilterJobOrderHPP = withDropdownHPPProvider(
  ({ show, setShow, filter, setFilter }) => {
    const defaultDropdown = { value: null, label: 'Semua' };
    const { dropdownCustomer, dropdownBarangJadi } =
      useContext(DropdownHPPContext);

    const initialValues = {
      tgl_spk_start: filter?.tgl_spk_start,
      tgl_spk_end: filter?.tgl_spk_end,
      tgl_rap_start: filter?.tgl_rap_start,
      tgl_rap_end: filter?.tgl_rap_end,
      tgl_job_order_start: filter?.tgl_job_order_start,
      tgl_job_order_end: filter?.tgl_job_order_end,
      id_barang_jadi: filter?.id_barang_jadi,
      id_customer: filter?.id_customer,
    };

    const onSubmit = (values) => {
      const checkActive =
        !!Object.values(values).filter((val) => val !== undefined).length > 0;

      if (checkActive) {
        setFilter((prev) => ({
          active: true,
          ...prev,
          ...values,
        }));

        setShow(false);
        return;
      }

      setFilter((prev) => ({ ...prev, ...values, active: false }));
      setShow(false);
    };

    const { values, setFieldValue, handleSubmit } = useFormik({
      initialValues,
      onSubmit,
    });

    const onResetButtonClick = () => {
      setFilter((prev) => ({
        ...prev,
        active: false,
        tgl_spk_start: undefined,
        tgl_spk_end: undefined,
        tgl_job_order_start: undefined,
        tgl_job_order_end: undefined,
        id_barang_jadi: undefined,
        id_customer: undefined,
      }));

      setShow(false);
    };

    return (
      <FilterModal
        show={show}
        setShow={setShow}
        onResetButtonClick={() => onResetButtonClick()}
        onFilterButtonClick={handleSubmit}
      >
        <DatePicker
          selectsRange
          label="Tgl. SPK"
          placeholderText="Pilih Tanggal SPK"
          monthsShown={2}
          startDate={values.tgl_spk_start ? new Date(values.tgl_spk_start) : ''}
          endDate={values.tgl_spk_end ? new Date(values.tgl_spk_end) : ''}
          onChange={(dates) => {
            const [start, end] = dates;
            const startDate = start ? DateConvert(start).default : null;
            const endDate = end ? DateConvert(end).default : null;

            setFieldValue('tgl_spk_start', startDate);
            setFieldValue('tgl_spk_end', endDate);
          }}
        />

        <DatePicker
          selectsRange
          label="Tgl. RAP"
          placeholderText="Pilih Tanggal RAP"
          monthsShown={2}
          startDate={values.tgl_rap_start ? new Date(values.tgl_rap_start) : ''}
          endDate={values.tgl_rap_end ? new Date(values.tgl_rap_end) : ''}
          onChange={(dates) => {
            const [start, end] = dates;
            const startDate = start ? DateConvert(start).default : null;
            const endDate = end ? DateConvert(end).default : null;

            setFieldValue('tgl_rap_start', startDate);
            setFieldValue('tgl_rap_end', endDate);
          }}
        />

        <DatePicker
          selectsRange
          label="Tgl. Job Order"
          placeholderText="Pilih Tanggal Job Order"
          monthsShown={2}
          startDate={
            values.tgl_job_order_start
              ? new Date(values.tgl_job_order_start)
              : ''
          }
          endDate={
            values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
          }
          onChange={(dates) => {
            const [start, end] = dates;
            const startDate = start ? DateConvert(start).default : null;
            const endDate = end ? DateConvert(end).default : null;

            setFieldValue('tgl_job_order_start', startDate);
            setFieldValue('tgl_job_order_end', endDate);
          }}
        />

        <SelectSearch
          label="Customer"
          placeholder="Pilih Customer"
          option={[defaultDropdown].concat(dropdownCustomer?.data ?? [])}
          onChange={({ value }) => setFieldValue('id_customer', value)}
          defaultValue={
            values.id_customer
              ? dropdownCustomer?.data?.find(
                  (v) => v.value === values.id_customer
                )
              : defaultDropdown
          }
        />

        <SelectSearch
          label="Item Job Order"
          placeholder="Pilih Item Job Order"
          option={[defaultDropdown].concat(dropdownBarangJadi?.data ?? [])}
          onChange={({ value }) => setFieldValue('id_barang_jadi', value)}
          defaultValue={
            values.id_barang_jadi
              ? dropdownBarangJadi?.data?.find(
                  (v) => v.value === values.id_barang_jadi
                )
              : defaultDropdown
          }
        />
      </FilterModal>
    );
  }
);
