import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { DatePicker, Input } from 'components2';
import { rupiahConvert } from 'utilities2';

export const PostingPettyCashContent = () => {
  const { values } = useFormikContext();

  return (
    <Fragment>
      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Penerimaan Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_penerimaan_petty_cash
                ? new Date(values.tgl_penerimaan_petty_cash)
                : ''
            }
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="No. Penerimaan Petty Cash"
            className="d-flex flex-column"
            value={values.no_penerimaan_petty_cash}
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="Nominal Penerimaan Petty Cash"
            className="d-flex flex-column text-right"
            value={rupiahConvert().getWithComa(
              values.nominal_penerimaan_petty_cash
            )}
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="Proyek"
            className="d-flex flex-column"
            value={values.nama_proyek}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <Input
            disabled
            label="Pengaju"
            value={values.nama_karyawan_pengaju ?? ''}
          />
        </Col>
        <Col md="3">
          <Input
            disabled
            label="Jabatan"
            value={values.nama_jabatan_pengaju ?? ''}
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="Unit Organisasi"
            value={values.nama_unit_organisasi ?? ''}
          />
        </Col>
      </Row>

      <Row>
        <Col md="3">
          <DatePicker
            disabled
            label="Tgl. Realisasi Petty Cash"
            dateFormat="dd/MM/yyyy"
            selected={
              values.tgl_realisasi_petty_cash
                ? new Date(values.tgl_realisasi_petty_cash)
                : ''
            }
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="No. Realisasi Petty Cash"
            className="d-flex flex-column"
            value={values.no_realisasi_petty_cash}
          />
        </Col>

        <Col md="3">
          <Input
            disabled
            label="Sisa Petty Cash"
            className="d-flex flex-column text-right"
            value={rupiahConvert().getWithComa(values.sisa_petty_cash ?? 0)}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
