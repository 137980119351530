export const hasNotifCheck = (realisasi = [], history = []) => {
  // has notif if realisasi length > history
  return realisasi.length > history.length ? true : false;
};

export const parentHasNotifCheck = (childTabNotif, currentCheckNotif) => {
  console.log(childTabNotif);

  const parentHasNotif = Object.values(childTabNotif)
    .toSpliced(0, 0, currentCheckNotif)
    .includes(true);

  console.log(parentHasNotif);

  return parentHasNotif;
};
