import Services from 'services';

class RegItemOverheadApi {
  getPage(params) {
    return Services.get('/overhead/page/', { params });
  }
  getBuaso() {
    return Services.get('/buaso');
  }
  getSatuan() {
    return Services.get('/satuan/dropdown');
  }
  getKelompok() {
    return Services.get('/overhead/kelompok?id_buaso=5');
  }
  getCOA() {
    return Services.get('/overhead/coa');
  }
  generateKodeItem() {
    return Services.get('/overhead/no_baru');
  }
  create(data) {
    return Services.post('/overhead', data);
  }
  update(data) {
    return Services.put('/overhead', data);
  }
  delete(id_overhead) {
    return Services.post('/overhead/delete', id_overhead);
  }
  search(key) {
    return Services.get(`overhead/page/?q=${key}`);
  }
  show(id_overhead) {
    return Services.put('/overhead/show', id_overhead);
  }
  hide(id_overhead) {
    return Services.put('/overhead/hide', id_overhead);
  }
  export() {
    return Services.get('/overhead/csv');
  }
}

export default new RegItemOverheadApi();
