import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPBahanApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import {
  hasNotifCheck,
  parentHasNotifCheck,
} from 'pages/Transaksi/BDPProduksi/Helpers';
import TableRealisasiBahan from './comps/TableRealisasiBahan';
import TableHistoryBahan from './comps/TableHistoryBahan';

const BahanPenunjangSection = ({ bahanPenunjang, setBahanPenunjang }) => {
  const location = useLocation();
  const { no_realisasi_produksi } = location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [historyBahanPenunjang, setHistoryBahanPenunjang] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPBahanApi.single({ ref: 'RPF', no_realisasi_produksi }),
      BDPBahanApi.history({ id_job_order, ref: 'RPF' }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data?.realisasi_bahan;
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setBahanPenunjang(data);
          setHistoryBahanPenunjang(history);

          const hasNotif = hasNotifCheck(
            data?.filter((e) => e.ref === 'RPF'),
            history
          );
          setNotif((prev) => ({
            ...prev,
            bdp_bahan: {
              ...prev.bdp_bahan,
              status: parentHasNotifCheck(prev.bdp_bahan, hasNotif),
              bahan_penunjang: hasNotif,
            },
          }));
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInitialData();

    return () => setIsLoading(false);
  }, []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Bahan Penunjang
      </small>
      <TableRealisasiBahan
        dataList={bahanPenunjang}
        dataHistory={historyBahanPenunjang}
        getInitialData={getInitialData}
        refKey="RPF"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List BDP Yang Telah Diinput
      </small>
      <TableHistoryBahan
        type="update"
        dataList={bahanPenunjang}
        setDataList={setBahanPenunjang}
        refKey="RPF"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistoryBahan
        type="detail"
        dataHistory={historyBahanPenunjang}
        refKey="RPF"
      />
    </div>
  );
};

export default BahanPenunjangSection;
