import React, { useState } from 'react';
import {
  DataStatus,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  ReadButton,
} from 'components';
import { RupiahConvert, DateConvert, TableNumber } from 'utilities';
import { calcGrandTotal } from '../../comps/calculateOpname';
import ModalDetailJurnalSubkon from '../../comps/ModalDetailJurnalSubkon';

const PageListBDPSubkon = ({
  dataBDPSubkon,
  paginationConfig,
  setPaginationConfig,
  searchConfig,
  isPageLoading,
}) => {
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  const ItemCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);
    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((val, index) => {
            if (isReadMoreClick) {
              return (
                <li key={index} index={index}>
                  {val.uraian}
                </li>
              );
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index}>
                    {val.uraian}
                  </li>
                )
              );
            }
          })}
        </ul>
        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  if (!dataBDPSubkon || dataBDPSubkon.length < 1 || isPageLoading.upah) {
    if (isPageLoading.upah)
      return <DataStatus loading={isPageLoading.upah} text="Memuat data ..." />;
    return <DataStatus text="Tidak ada data" />;
  }

  return (
    <div className="px-2 py-3">
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Informasi BDP</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <ThFixed>Informasi RAP</ThFixed>
            <ThFixed>Informasi Job Order</ThFixed>
            <Th>Vendor</Th>
            <Th style={{ minWidth: '200px' }}>Rincian Pekerjaan</Th>
            <Th style={{ minWidth: '100px' }}>Jumlah</Th>
            <Th style={{ minWidth: '250px' }}>Akun COA</Th>
            <Th style={{ minWidth: '200px' }}>Keterangan Jurnal</Th>
          </tr>
        </thead>
        <tbody>
          {dataBDPSubkon.map((val, index) => {
            const calc = calcGrandTotal(val?.rincian ?? []);

            return (
              <tr key={index}>
                <Td>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td>
                  <ReadButton
                    size="sm"
                    onClick={() => {
                      console.log(val);
                      setModalConfig({
                        show: true,
                        data: val,
                      });
                    }}
                  />
                </Td>

                <Td>
                  {val.tgl_bdp_subkon
                    ? DateConvert(new Date(val.tgl_bdp_subkon)).defaultDMY
                    : '-'}
                  {`\n ${val.no_bdp_subkon ?? ''}`}
                </Td>

                <Td>
                  {val.tgl_spk
                    ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                    : '-'}
                  {`\n ${val.no_spk ?? ''}`}
                </Td>

                <Td>
                  {val.tgl_rap
                    ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                    : '-'}
                  {`\n ${val.no_rap ?? ''}`}
                </Td>

                <Td>
                  {val.tgl_job_order
                    ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                    : '-'}
                  {`\n ${val.no_job_order ?? ''}`}
                </Td>
                <Td>{val.nama_vendor ?? '-'}</Td>
                <Td>
                  {val?.rincian && val?.rincian?.length > 0 ? (
                    <ItemCollapse data={val?.rincian} />
                  ) : (
                    '-'
                  )}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(parseInt(calc?.totalPeriodeHinggaIni || 0))
                    ).detail
                  }
                </Td>
                <Td>
                  <span className="d-block">{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debet}`}</span>
                  <span className="d-block">{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
                </Td>
                <Td>{val.keterangan_jurnal}</Td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {!searchConfig.status && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}

      <ModalDetailJurnalSubkon
        isHistory
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
    </div>
  );
};

export default PageListBDPSubkon;
