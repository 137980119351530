import { Modal, Row, Col } from 'react-bootstrap';
import { RupiahConvert, DateConvert } from 'utilities';

const ModalJurnalBDPDetail = ({ modalConfig, setModalConfig }) => {
  const dataModal = modalConfig?.data ?? {};

  const InfoItemHorizontal = ({ label, text, width }) => (
    <div className={`d-flex align-items-top`}>
      <div style={{ width: width ? width : 180, fontSize: 14 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14, maxWidth: '50%' }}>{text}</div>
    </div>
  );

  const InfoItemVertical = ({ title, text }) => (
    <div className="mb-2">
      <small
        className="text-secondary"
        style={{ opacity: 0.8, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  const InfoSection = () => {
    return (
      <>
        <InfoItemHorizontal
          label="Tgl. Realisasi Produksi"
          text={
            DateConvert(new Date(dataModal?.tgl_realisasi_produksi_alat_mesin))
              .defaultDMY
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi"
          text={dataModal?.no_realisasi_produksi_alat_mesin}
        />
        <InfoItemHorizontal
          label="Item Bahan"
          text={dataModal?.nama_item_aset}
        />
        <InfoItemHorizontal
          label="Qty."
          text={`${dataModal?.qty_realisasi_produksi_alat_mesin} ${dataModal.nama_satuan}`}
        />
        <InfoItemHorizontal
          label="Harga Satuan"
          text={
            RupiahConvert(String(parseInt(dataModal?.harga_satuan_penyusutan)))
              .detail
          }
        />
      </>
    );
  };

  const FormBody = () => {
    return (
      <Modal.Body>
        <InfoSection />
        <hr />
        <Row>
          <Col md>
            <InfoItemVertical
              title="Tgl.BDP"
              text={DateConvert(new Date(dataModal?.tgl_bdp_alat_mesin)).detail}
            />
          </Col>
          <Col md>
            <InfoItemVertical
              title="No.BDP"
              text={dataModal?.no_bdp_alat_mesin}
            />
          </Col>
        </Row>
        <InfoItemVertical
          title="Jumlah"
          text={RupiahConvert(String(parseInt(dataModal?.jumlah))).detail}
        />
        <InfoItemVertical title="COA Debet" text={dataModal?.nama_coa_debet} />
        <InfoItemVertical
          title="COA Kredit"
          text={dataModal?.nama_coa_kredit}
        />
        <InfoItemVertical
          title="Keterangan Jurnal"
          text={dataModal?.keterangan_jurnal}
        />
      </Modal.Body>
    );
  };

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>Detail Data Jurnal BDP</b>
      </Modal.Header>
      <FormBody />
    </Modal>
  );
};

export default ModalJurnalBDPDetail;
