import Services from 'services';

class BDPSubkonApi {
  get(params) {
    return Services.get('/bdp_subkon/page', { params });
  }

  getSingle(params) {
    return Services.get('/bdp_subkon/single_realisasi', { params });
  }

  getSingleHistory(params) {
    return Services.get('/bdp_subkon/page_bdp', { params });
  }

  opname(params) {
    return Services.get('/bdp_subkon/single_opname', { params });
  }

  getCOA() {
    return Services.get('/bdp_subkon/dropdown');
  }

  getNomor(params) {
    return Services.get('/bdp_subkon/no_baru', { params });
  }

  save(data) {
    return Services.post('/bdp_subkon', data);
  }
}

export default new BDPSubkonApi();
