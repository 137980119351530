// React
import { useEffect, useState } from 'react';

// Components
import { Col, Row } from 'react-bootstrap';
import {
  Alert,
  CRUDLayout,
  DataStatus,
  InputSearch,
} from '../../../components';

// View Components
import { TabItem } from './components';

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = 'Harga Perkiraan Sendiri';

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // STATE DATA SATUAN
  const [key, setKey] = useState('');
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    const value = e.target.value;

                    setKey(value);

                    setAlertConfig({
                      text: value,
                      variant: 'primary',
                    });
                    setShowAlert(Boolean(value && value !== ''));
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <>
          <b>List Data Harga Perkiraan Sendiri</b>
          <TabItem q={key} filter={undefined} value="pakai" />
        </>
      )}
    </CRUDLayout>
  );
};

export default HargaPerkiraanSendiri;
