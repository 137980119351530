import React from 'react';
import { Col } from 'react-bootstrap';
import { Td, Th, ThFixed, Table, DataStatus } from 'components';
import { RupiahConvert } from 'utilities';
import { calculateOpname, calcGrandTotal } from './calculateOpname';

const TableDetailOpname = ({ data }) => {
  return (
    <>
      <small className="font-weight-bold">Detail Opname</small>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed className="p-2 text-center" rowSpan={2}>
              No.
            </ThFixed>
            <Th className="p-2 text-center" rowSpan={2} style={{ width: 350 }}>
              Uraian
            </Th>
            <Th className="p-2 text-center" rowSpan={2}>
              Volume
            </Th>
            <Th className="p-2 text-center" rowSpan={2}>
              Harga Satuan
            </Th>
            <Th className="p-2 text-center" rowSpan={2}>
              Jumlah
            </Th>
            <Th className="p-2 text-center" colSpan={6}>
              Realisasi Pembayaran
            </Th>
          </tr>
          <tr>
            <Th className="p-2 text-center">%</Th>
            <Th className="p-2 text-center">Periode Lalu</Th>
            <Th className="p-2 text-center">%</Th>
            <Th className="p-2 text-center">Periode ini</Th>
            <Th className="p-2 text-center">%</Th>
            <Th className="p-2 text-center">S/D Periode ini</Th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              const calc = calculateOpname(
                val.qty,
                val.unit_price,
                val.persentase_periode_lalu,
                val.persentase_periode_ini
              );

              return (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.uraian ?? ''}</Td>
                  <Td className="text-right">{`${parseFloat(val.qty)} ${
                    val.kode_satuan
                  }`}</Td>
                  <Td className="text-right">
                    {RupiahConvert(String(parseInt(val.unit_price))).detail}
                  </Td>
                  <Td className="text-right">
                    {RupiahConvert(String(parseInt(calc.jumlah ?? 0))).detail}
                  </Td>

                  {/* Periode Lalu */}
                  <Td className="text-right">{`${parseFloat(
                    val.persentase_periode_lalu ?? 0
                  )} %`}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(
                        String(parseInt(calc.total_persentase_lalu || 0))
                      ).detail
                    }
                  </Td>

                  {/* Periode Ini */}
                  <Td className="text-right">{`${parseFloat(
                    val.persentase_periode_ini ?? 0
                  )} %`}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(
                        String(parseInt(calc.total_persentase_ini || 0))
                      ).detail
                    }
                  </Td>

                  {/* Periode Lalu s.d Ini */}
                  <Td className="text-right">{`${parseFloat(
                    calc.persentase_hingga_ini ?? 0
                  )} %`}</Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(
                        String(parseInt(calc.total_persentase_hingga_ini || 0))
                      ).detail
                    }
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <Td colSpan={11}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}

          {data?.length > 0 && (
            <tr>
              <Td colSpan={4} className="font-weight-bold text-right">
                GRAND TOTAL
              </Td>
              <Td className="font-weight-bold text-right">
                {
                  RupiahConvert(
                    String(parseInt(calcGrandTotal(data).totalJumlah))
                  ).detail
                }
              </Td>
              <Td />
              <Td className="font-weight-bold text-right">
                {
                  RupiahConvert(
                    String(parseInt(calcGrandTotal(data).totalPeriodeLalu))
                  ).detail
                }
              </Td>
              <Td />
              <Td className="font-weight-bold text-right">
                {
                  RupiahConvert(
                    String(parseInt(calcGrandTotal(data).totalPeriodeIni))
                  ).detail
                }
              </Td>
              <Td />
              <Td className="font-weight-bold text-right">
                {
                  RupiahConvert(
                    String(parseInt(calcGrandTotal(data).totalPeriodeHinggaIni))
                  ).detail
                }
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <Col className="d-flex flex-column justify-content-end align-items-end font-weight-bold">
        <table style={{ fontSize: 14 }}>
          <tr>
            <td>Total Upah Kerja</td>
            <td className="py-1 px-3">:</td>
            <td className="text-right">
              {
                RupiahConvert(
                  String(parseInt(calcGrandTotal(data).totalJumlah))
                ).detail
              }
            </td>
          </tr>
          <tr>
            <td>Pembayaran Periode Ini</td>
            <td className="py-1 px-3">:</td>
            <td className="text-right">
              {
                RupiahConvert(
                  String(parseInt(calcGrandTotal(data).totalPeriodeIni))
                ).detail
              }
            </td>
          </tr>
          <tr>
            <td>Retensi</td>
            <td className="py-1 px-3">:</td>
            <td className="text-right">
              {
                RupiahConvert(String(parseInt(calcGrandTotal(data).retensi)))
                  .detail
              }
            </td>
          </tr>
        </table>
      </Col>
    </>
  );
};

export default TableDetailOpname;
