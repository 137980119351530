import Services from 'services';

class OverheadPrelimApi {
  get(params) {
    return Services.get('/overhead_prelim/page', { params });
  }

  getPermintaan(params) {
    return Services.get('/overhead_prelim/list_permintaan_prelim', { params });
  }
  getSinglePermintaan(params) {
    return Services.get('/overhead_prelim/single_permintaan_prelim', {
      params,
    });
  }

  getNomor(params) {
    return Services.get('/overhead_prelim/no_baru', { params });
  }

  save(data) {
    return Services.post('/overhead_prelim/', data);
  }
}

export default new OverheadPrelimApi();
