import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  tgl_hpp: yup.string().required('Tanggal HPP harus diisi'),
  no_hpp: yup.string().required('Pilih Tgl. HPP '),
  qty_hpp: yup
    .number()
    .required('Masukkan Qty. Posting HPP')
    .positive('Qty. Posting harus lebih besar dari 0')
    .test(
      'shouldLargerThanZero',
      'Qty. Posting harus lebih besar dari 0 ',
      (value) => !!parseFloat(value) > 0
    ),
  id_coa_debet: yup.string().required('Pilih COA Debet'),
  id_coa_kredit: yup.string().required('Pilih COA Kredit'),
  keterangan: yup.string().required('Keterangan jurnal wajib diisi'),
});
