import React, { useState } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { Td, Th, ThFixed, Table, ActionButton, DataStatus } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { useIsGuest } from 'hooks';
import ModalJurnalBDP from './ModalJurnalBDP';

const TableRealisasiBahan = ({
  dataList,
  dataHistory,
  getInitialData,
  refKey,
}) => {
  const isGuest = useIsGuest();
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  return (
    <>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed className="py-2 text-center">No.</ThFixed>
            <ThFixed className="p-2 text-center">
              Informasi Realisasi Produksi
            </ThFixed>
            <Th className="p-2 text-center" style={{ width: 400 }}>
              Item Bahan
            </Th>
            <Th className="p-2 text-center">Satuan</Th>
            <Th className="p-1 text-center" style={{ width: 70 }}>
              Qty. Realisasi
            </Th>
            <Th className="p-1 text-center" style={{ width: 250 }}>
              Harga Satuan <br />
              Rata-rata Pakai
            </Th>
            <Th className="p-1 text-center" style={{ width: 250 }}>
              Jumlah
            </Th>
            {!isGuest && <ThFixed className="p-0 text-center">Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {dataList?.filter((item) => item.ref === refKey).length > 0 ? (
            dataList
              ?.filter((item) => item.ref === refKey)
              .map((val, index) => {
                const isInHistory = dataHistory.find(
                  (e) =>
                    e.id_realisasi_produksi_bahan ===
                      val.id_realisasi_produksi_bahan && true
                );

                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>
                    <Td>
                      <span className="d-block">{`${
                        val.tgl_realisasi_produksi_bahan
                          ? DateConvert(
                              new Date(val.tgl_realisasi_produksi_bahan)
                            ).defaultDMY
                          : '-'
                      }`}</span>
                      <span className="d-block">{`${
                        val.no_realisasi_produksi_bahan ?? ''
                      }`}</span>
                    </Td>
                    <Td>{val.nama_item}</Td>
                    <Td>{val.nama_satuan}</Td>
                    <Td className="text-right">
                      {val.qty_realisasi_produksi_bahan}
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseInt(val?.harga_satuan_rata_rata_pakai ?? 0)
                          )
                        ).detail
                      }
                    </Td>
                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseFloat(val.qty_realisasi_produksi_bahan) *
                              parseInt(val.harga_satuan_rata_rata_pakai ?? 0)
                          )
                        ).detail
                      }
                    </Td>
                    {!isGuest && (
                      <Td className="text-center">
                        {!isInHistory && (
                          <ActionButton
                            size="sm"
                            style={{ backgroundColor: '#1956b9' }}
                            onClick={() =>
                              setModalConfig({
                                show: true,
                                data: val,
                              })
                            }
                            disable={Boolean(val.isAdded || isInHistory)}
                          >
                            <IoAddOutline />
                          </ActionButton>
                        )}
                      </Td>
                    )}
                  </tr>
                );
              })
          ) : (
            <tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {modalConfig.show && (
        <ModalJurnalBDP
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          getInitialData={getInitialData}
        />
      )}
    </>
  );
};

export default TableRealisasiBahan;
