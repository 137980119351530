import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  {
    //Dashboard
    text: 'Dashboard',
    link: '/',
    type: 'menu',
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: ['SUPA'],
  },
  {
    // Master
    text: 'Master',
    type: 'dropdown',
    icon: <IoServerOutline />,
    hak: ['SUPA', 'COC'],
    menu: [
      {
        text: 'Item Overhead',
        link: '/master/item-overhead',
        hak: ['SUPA', 'COC_REG_OVH'],
      },
      {
        text: 'Kelompok Overhead',
        link: '/master/kelompok-overhead',
        hak: ['SUPA', 'COC_MAS_KOH'],
      },
      {
        text: 'Harga Perkiraan Sendiri',
        link: '/master/harga-perkiraan-sendiri',
        hak: ['SUPA', 'COC_MAS_HPS'],
      },
    ],
  },
  {
    // Transaksi
    text: 'Transaksi',
    type: 'dropdown',
    icon: <IoCashOutline />,
    hak: ['SUPA', 'COC'],
    menu: [
      {
        text: 'Transfer Overhead Produksi',
        link: '/transaksi/transfer-produksi-overhead',
        hak: ['SUPA', 'COC_TRN_TPO'],
      },
      {
        text: 'BDP Produksi',
        link: '/transaksi/bdp-produksi',
        hak: ['SUPA', 'COC_TRN_BDP'],
      },
      {
        text: 'HPP',
        link: '/transaksi/hpp',
        hak: ['SUPA', 'COC_TRN_HPP'],
      },
      {
        text: 'Harga Satuan Rata-Rata Bahan',
        link: '/transaksi/harga-satuan-rata-rata-bahan',
        hak: ['SUPA', 'COC_TRN_HSR'],
      },
      {
        text: 'Harga Satuan Rata-Rata Penyusutan',
        link: '/transaksi/harga-satuan-rata-rata-penyusutan',
        hak: ['SUPA', 'COC_TRN_HSP'],
      },
      {
        text: 'Harga Satuan Rata-Rata Overhead',
        link: '/transaksi/harga-satuan-rata-rata-overhead',
        hak: ['SUPA', 'COC_TRN_HSO'],
      },
      {
        text: 'Overhead Prelim',
        link: '/transaksi/overhead-prelim',
        hak: ['SUPA', 'COC_TRN_OHP'],
      },
      {
        text: 'Posting Biaya Prelim',
        link: '/transaksi/posting-biaya-prelim',
        hak: ['SUPA', 'COC_TRN_PBR'],
      },
      {
        text: 'Posting Petty Cash',
        link: '/transaksi/posting-petty-cash',
        hak: ['SUPA', 'COC_TRN_PPC'],
      },
    ],
  },
  {
    // Laporan
    text: 'Laporan',
    type: 'dropdown',
    icon: <IoDocumentTextOutline />,
    hak: ['SUPA'],
    menu: [
      {
        text: 'Coming Soon',
        link: '/soon',
        hak: ['SUPA'],
      },
    ],
  },
];
