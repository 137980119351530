export const mapOnSubmit = (data) => ({
  id_hpp: data.id_hpp,
  id_job_order: data.id_job_order,
  tgl_hpp: data.tgl_hpp,
  qty_hpp: data?.qty_hpp,
  no_coa_debet: data.id_coa_debet,
  nomor_akun_debet: data.id_coa_debet,
  no_coa_kredit: data.id_coa_kredit,
  nomor_akun_kredit: data.id_coa_kredit,
  jumlah_hpp: data.jumlah_hpp,
  keterangan_jurnal: data.keterangan,
  bahan:
    data?.bahan?.map(({ id_bdp_bahan, ref }) => ({ id_bdp_bahan, ref })) ?? [],

  upah: data?.upah?.map(({ id_bdp_upah, ref }) => ({ id_bdp_upah, ref })) ?? [],

  alat_mesin:
    data?.alat?.map(({ id_bdp_alat_mesin, ref }) => ({
      id_bdp_alat_mesin,
      ref,
    })) ?? [],

  subkon:
    data?.subcont?.map(({ id_bdp_subkon, ref }) => ({
      id_bdp_subkon,
      ref,
    })) ?? [],

  overhead:
    data?.overhead?.map(({ id_bdp_overhead, ref }) => ({
      id_bdp_overhead,
      ref,
    })) ?? [],

  sisa_produksi:
    data?.sisa?.map(({ id_bdp_sisa_produksi, ref }) => ({
      id_bdp_sisa_produksi,
      ref,
    })) ?? [],
});
