import { DataStatus, Table, Th, ThFixed, Td, Pagination } from 'components';
import { TableNumber, RupiahConvert } from 'utilities';
import { numberConvert, rupiahConvert } from 'utilities2';

export const TableContent = ({
  data,
  type = 'page',
  paginationConfig,
  setPaginationConfig,
}) => {
  const errorMessage =
    type === 'page'
      ? 'Tidak ada data'
      : 'Hitung harga satuan rata-rata terlebih dulu';

  return (
    <>
      <Table>
        <thead>
          <tr>
            <ThFixed className="text-nowrap">No.</ThFixed>
            <ThFixed className="text-nowrap">Kode Aset</ThFixed>
            <Th>Item Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <ThFixed className="text-nowrap">
              Total Qty. <br />
              Realisasi
            </ThFixed>
            <ThFixed className="text-nowrap">
              Harga Satuan <br /> Rata-rata Penyusutan
            </ThFixed>
          </tr>
        </thead>
        <tbody>
          {!data || data?.length < 1 ? (
            <tr>
              <td colSpan={7}>
                <DataStatus text={errorMessage} />
              </td>
            </tr>
          ) : (
            data.map((val, index) => (
              <tr key={index}>
                <Td>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td>{val.kode_item_aset ?? '-'}</Td>
                <Td>{val.nama_item_aset ?? '-'}</Td>
                <Td>{val.nama_kategori_aset ?? '-'}</Td>
                <Td>{val.nama_jenis_aset ?? '-'}</Td>
                <Td textRight>
                  {numberConvert().getWithComa(
                    val.total_qty_realisasi ?? val.total_produksi
                  )}
                  {` ${val.nama_satuan_pakai ?? val.nama_satuan ?? ''}`}
                </Td>
                <Td className="text-right">
                  {val.harga_satuan
                    ? rupiahConvert().getWithComa(parseFloat(val.harga_satuan))
                    : '-'}

                  {`/${val.nama_satuan_pakai ?? val.nama_satuan ?? ''}`}
                </Td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {data?.length > 1 && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </>
  );
};
