import { Fragment, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { TablePermintaan } from './TablePermintaan';
import { TableTransfer } from './TableTransfer';
import { TransferOverheadContext } from '../Context';

export const TabBiayaOverhead = () => {
  const { dataPermintaan, dataTransfer, dataHistory } = useContext(
    TransferOverheadContext
  );

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <small>List Data Permintaan Biaya Overhead</small>
        </Col>
      </Row>
      <TablePermintaan data={dataPermintaan} />

      <small className="mt-2">List Data Transfer Biaya Overhead</small>
      <TableTransfer
        tipe="editable"
        tipeTransfer="penunjang"
        data={dataTransfer}
        uniqueKey="id_permintaan_produksi_finishing"
      />

      <small className="mt-2">History Transfer Biaya Overhead</small>
      <TableTransfer tipe="readonly" data={dataHistory} />
    </Fragment>
  );
};
