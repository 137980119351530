import { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import { useHistory } from 'react-router-dom';

const InfoSection = memo(({ dataInfo }) => {
  const history = useHistory();
  return (
    <Row>
      <Col md>
        <InfoItemHorizontal
          width={150}
          label="Tgl. SPK"
          text={
            dataInfo.tgl_spk
              ? DateConvert(new Date(dataInfo.tgl_spk)).detail
              : '-'
          }
        />
        <div
          onClick={() =>
            history.push(
              '/transaksi/posting-biaya-prelim/posting/detail-spk/' +
                dataInfo.id_spk
            )
          }
        >
          <InfoItemHorizontal
            width={150}
            textStyle={{
              color: 'rgb(119,168,248)',
              cursor: 'pointer',
              fontSize: 14,
            }}
            label="No. SPK"
            text={dataInfo.no_spk ?? '-'}
          />
        </div>
        <InfoItemHorizontal
          width={150}
          label="Tgl. Selesai"
          text={
            dataInfo.tgl_selesai
              ? DateConvert(new Date(dataInfo.tgl_selesai)).detail
              : '-'
          }
        />
        {/* <InfoItemHorizontal 
          width={150}
          label="Kode Barang Jadi"
          text={dataInfo.kode_barang_jadi ?? '-'}
        />
        <InfoItemHorizontal 
          width={150}
          label="Barang Jadi"
          text={dataInfo.nama_barang_jadi ?? '-'}
        />
        <InfoItemHorizontal 
          width={150}
          label="Qty. Job Order"
          text={`${dataInfo.qty_rap ? parseFloat(dataInfo.qty_rap) : '-'} ${dataInfo.nama_satuan ?? ''}`}
        /> */}
      </Col>
      <Col md>
        <InfoItemHorizontal
          width={150}
          label="Proyek"
          text={dataInfo.nama_proyek ?? '-'}
        />
        <InfoItemHorizontal
          width={150}
          label="Customer"
          text={dataInfo.nama_customer ?? '-'}
        />
        <InfoItemHorizontal
          width={150}
          label="ATT"
          text={dataInfo.att ?? '-'}
        />
        <InfoItemHorizontal
          width={150}
          label="Baseline"
          text={dataInfo.baseline ?? '-'}
        />
      </Col>
    </Row>
  );
});

export default InfoSection;
