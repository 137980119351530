import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ActionButton } from 'components';
import { ItemOverheadApi } from 'api';

export const ModalDelete = ({ modalState, setAlert, onHide, fetchData }) => {
  const { show, type, data } = modalState;
  const { id_item_buaso } = data ?? {};

  const [btnLoading, setBtnLoading] = useState(false);

  const deleteDataHandler = () => {
    setBtnLoading(true);

    ItemOverheadApi.delete({ id_item_buaso })
      .then(() =>
        setAlert({
          variant: 'primary',
          text: 'Hapus data berhasil!',
        })
      )
      .catch((err) =>
        setAlert({
          variant: 'danger',
          text: `Hapus data gagal!(${err.response.data.message})`,
        })
      )
      .finally(() => {
        onHide();
        fetchData();
      });
  };

  return (
    <Modal
      show={show && type === 'delete'}
      onHide={onHide}
      loading={btnLoading}
      onConfirm={deleteDataHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-danger h6">
          Hapus Data Item Overhead
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 className="mb-1">Apakah anda yakin menghapus data?</h5>
        <div>
          <div>Kode Overhead : {data?.kode_item}</div>
          <div>Nama Item Overhead : {data?.nama_item}</div>
        </div>
        <small className="text-danger">
          Data yang telah dihapus tidak dapat dikembalikan
        </small>
        <div className="mt-3">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            onClick={onHide}
          />
          <ActionButton
            variant="danger"
            text="Hapus data"
            onClick={deleteDataHandler}
            loading={btnLoading}
            className="ml-1"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
