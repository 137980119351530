import { createContext } from 'react';
import { useQuery } from 'react-query';
import { HPPApi } from 'api';

export const DropdownHPPContext = createContext();

export const DropdownHPPProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES
  const dropdownMenuKey = 'transfer_bahan_produksi';

  const dropdownCOA = useQuery(
    ['dropdown', 'coa', dropdownMenuKey],
    () =>
      HPPApi.dropdown({ tipe: 'coa' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            ...v,
            value: v.id_coa,
            label: `${v.nomor_akun} - ${v.nama_akun}`,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      HPPApi.dropdown({ tipe: 'customer' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            ...v,
            value: v.id_customer,
            label: v.nama_customer,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownBarangJadi = useQuery(
    ['dropdown', 'barang_jadi', dropdownMenuKey],
    () =>
      HPPApi.dropdown({ tipe: 'barang_jadi' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            ...v,
            value: v.id_item_buaso,
            label: v.nama_item,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownStatusJurnal = [
    { value: null, label: 'Semua' },
    { value: true, label: 'Sudah Dijurnal' },
    { value: false, label: 'Belum Dijurnal' },
  ];

  return (
    <DropdownHPPContext.Provider
      value={{
        dropdownCOA,
        dropdownCustomer,
        dropdownBarangJadi,
        dropdownStatusJurnal,
      }}
    >
      {children}
    </DropdownHPPContext.Provider>
  );
};

export const withDropdownHPPProvider = (Component) => (props) => {
  return (
    <DropdownHPPProvider>
      <Component {...props} />
    </DropdownHPPProvider>
  );
};
