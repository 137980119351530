import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  DatePicker,
  Input,
  InputCurrency,
  SelectSearch,
  TextArea,
} from 'components';
import { DateConvert } from 'utilities';
import { HPPApi } from 'api';
import { useContext } from 'react';
import {
  DropdownHPPContext,
  HPPContext,
  withDropdownHPPProvider,
} from '../Context';

export const FormSectionPostingHPP = withDropdownHPPProvider(
  ({ type = 'create' }) => {
    const isCreate = type === 'create';
    const { dropdownCOA } = useContext(DropdownHPPContext);
    const { dataInfo } = useContext(HPPContext);
    const { values, errors, touched, setFieldValue, setValues } =
      useFormikContext();

    const fetchNomorHandler = async (tanggal) => {
      return await HPPApi.no_baru({ tanggal })
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          alert(err.response.data.message);
          return '';
        });
    };

    return (
      <>
        <div style={{ fontSize: '.9rem' }}>Tambah Data HPP</div>
        <Card>
          <Card.Body>
            <Row className="d-flex justify-content-center align-items-center">
              <Col>
                <DatePicker
                  label="Tgl. HPP"
                  placeholderText="Pilih tanggal"
                  selected={values.tgl_hpp ? new Date(values.tgl_hpp) : ''}
                  onChange={async (date) => {
                    const tgl_hpp = DateConvert(date).default;

                    if (isCreate) {
                      const no_hpp = await fetchNomorHandler(tgl_hpp);

                      return setValues((prev) => ({
                        ...prev,
                        tgl_hpp,
                        no_hpp,
                      }));
                    }

                    setFieldValue('tgl_hpp', tgl_hpp);
                  }}
                  error={Boolean(errors.tgl_hpp && touched.tgl_hpp)}
                  errorText={
                    Boolean(errors.tgl_hpp && touched.tgl_hpp) && errors.tgl_hpp
                  }
                />
              </Col>
              <Col>
                <Input readOnly label="No. HPP" value={values.no_hpp} />
              </Col>
            </Row>

            <Row className="d-flex justify-content-center align-items-center">
              <Col>
                <InputCurrency
                  className="text-right"
                  label="Qty. Posting HPP"
                  decimalScale={2}
                  prefix=""
                  suffix={` ${dataInfo.nama_satuan ?? ''}`}
                  value={values.qty_hpp}
                  onChange={(e) => setFieldValue('qty_hpp', e)}
                  error={Boolean(errors.qty_hpp && touched.qty_hpp)}
                  errorText={
                    Boolean(errors.qty_hpp && touched.qty_hpp) && errors.qty_hpp
                  }
                />
              </Col>
              <Col>
                <InputCurrency
                  disabled
                  className="text-right"
                  label="Jumlah HPP"
                  value={values.jumlah_hpp}
                />
              </Col>
            </Row>

            <SelectSearch
              label="COA Debet"
              option={dropdownCOA?.data}
              defaultValue={
                dropdownCOA?.data?.find(
                  (item) => item.value === values?.id_coa_debet
                ) ?? null
              }
              onChange={({ value }) => setFieldValue('id_coa_debet', value)}
              error={Boolean(errors.id_coa_debet && touched.id_coa_debet)}
              errorText={
                Boolean(errors.id_coa_debet && touched.id_coa_debet) &&
                errors.id_coa_debet
              }
            />
            <SelectSearch
              label="COA Kredit"
              option={dropdownCOA?.data ?? []}
              defaultValue={
                dropdownCOA?.data?.find(
                  (item) => item.value === values?.id_coa_kredit
                ) ?? null
              }
              onChange={({ value }) => setFieldValue('id_coa_kredit', value)}
              error={Boolean(errors.id_coa_kredit && touched.id_coa_kredit)}
              errorText={
                Boolean(errors.id_coa_kredit && touched.id_coa_kredit) &&
                errors.id_coa_kredit
              }
            />
            <TextArea
              label="Keterangan Jurnal*"
              rows={4}
              value={values.keterangan}
              onChange={({ target }) =>
                setFieldValue('keterangan', target.value)
              }
              error={Boolean(errors.keterangan && touched.keterangan)}
              errorText={
                Boolean(errors.keterangan && touched.keterangan) &&
                errors.keterangan
              }
            />
          </Card.Body>
        </Card>
      </>
    );
  }
);
