import { useState, useEffect, useContext } from 'react';
import { HPPApi } from 'api';
import { mapBDPList } from '../Helpers';
import { HPPContext } from '../Context';

export const useFetchSingleHPP = (id_hpp) => {
  const { summary, totalSummary, dataBDP, setDataBDP, dataInfo, setDataInfo } =
    useContext(HPPContext);
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    if (id_hpp) {
      setIsPageLoading(true);

      HPPApi.single({ id_hpp })
        .then(({ data }) => {
          const {
            bahan,
            upah,
            alat_mesin,
            subkon,
            overhead,
            sisa_produksi,
            ...info
          } = data?.data ?? {};

          setDataInfo(info);
          setDataBDP({
            bahan: mapBDPList(bahan),
            upah: mapBDPList(upah),
            alat_mesin: mapBDPList(alat_mesin),
            subkon: mapBDPList(subkon),
            overhead: mapBDPList(overhead),
            sisa_produksi: mapBDPList(sisa_produksi),
          });
        })
        .finally(() => setIsPageLoading(false));
    }
  }, [id_hpp, setDataBDP]);

  return { isPageLoading, dataInfo, dataBDP, summary, totalSummary };
};
