import * as React from 'react';
import { FilterModal, SelectSearch } from 'components';
import { useFormik } from 'formik';

export const ModalFilterHSRPakai = ({
  show,
  setShow,
  filter,
  setFilter,
  dropdown,
  SESSION_KEY,
}) => {
  const defaultDropdown = { value: null, label: 'Semua' };

  const initialValues = {
    id_kelompok: filter?.id_kelompok,
    id_jenis: filter?.id_jenis,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter({
        active: true,
        ...values,
      });
      setShow(false);
    }

    setFilter({ ...values, active: false });
    setShow(false);
  };

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
  });

  const onResetButtonClick = () => {
    sessionStorage.removeItem(SESSION_KEY.FILTER);

    setFilter({
      active: false,
      id_kelompok: undefined,
      id_jenis: undefined,
    });

    setShow(false);
  };

  const dropdownJenisFiltered = dropdown?.jenis?.filter(
    (v) => v.id_kelompok === values.id_kelompok
  );

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <SelectSearch
        label="Kelompok Bahan"
        placeholder="Semua"
        option={[defaultDropdown].concat(dropdown?.kelompok)}
        onChange={({ value }) => {
          setFieldValue('id_kelompok', value);
          setFieldValue('id_jenis', undefined);
        }}
        defaultValue={
          values.id_kelompok
            ? dropdown?.kelompok?.find((v) => v.value === values.id_kelompok)
            : null
        }
      />
      <SelectSearch
        key={values.id_kelompok}
        isDisabled={!values.id_kelompok}
        label="Jenis Bahan"
        placeholder="Semua"
        option={[defaultDropdown].concat(dropdownJenisFiltered)}
        onChange={({ value }) => setFieldValue('id_jenis', value)}
        defaultValue={
          values.id_jenis
            ? dropdownJenisFiltered?.find((v) => v.value === values.id_jenis)
            : null
        }
      />
    </FilterModal>
  );
};
