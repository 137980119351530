import { useContext } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import {
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectSearch,
  ActionButton,
  DataStatus,
} from 'components';
import {
  DropdownTransferOverheadContext,
  TransferOverheadContext,
} from '../Context';
import { RupiahConvert } from 'utilities';
import { calcSubTotal, calcGrandTotal } from '../Helpers';

export const TablePermintaan = ({ data = [] }) => {
  const { setModalForm, setDataPermintaan } = useContext(
    TransferOverheadContext
  );
  const { dropdownStatusPermintaan } = useContext(
    DropdownTransferOverheadContext
  );

  const onChangeStatusPermintaan = ({ value, index }) =>
    setDataPermintaan((prv) =>
      prv.toSpliced(index, 1, { ...prv[index], status_permintaan: value })
    );

  return (
    <Table>
      <thead>
        <tr className="text-center">
          <ThFixed>No.</ThFixed>
          <Th width={300}>Item Overhead</Th>
          <Th>Qty</Th>
          <Th>Satuan</Th>
          <Th width={200}>Unit Price (Rp)</Th>
          <Th>Konst.</Th>
          <Th width={200}>Sub Total Price (Rp)</Th>
          <Th width={150}>Status Permintaan</Th>
          <ThFixed>Aksi</ThFixed>
        </tr>
      </thead>

      <tbody>
        {data?.length ? (
          data.map((val, index) => (
            <tr key={index}>
              <TdFixed className="text-center align-middle">
                {index + 1}
              </TdFixed>
              <Td className="align-middle">{val.nama_item}</Td>
              <TdFixed className="align-middle" textRight>
                {parseFloat(val.qty)}
              </TdFixed>
              <TdFixed className="align-middle" textRight>
                {val.nama_satuan}
              </TdFixed>
              <TdFixed className="align-middle" textRight>
                {RupiahConvert(String(Math.round(val.unit_price))).detail}
              </TdFixed>
              <TdFixed className="align-middle" textRight>
                {parseFloat(val.konstanta)}
              </TdFixed>
              <TdFixed className="align-middle" textRight>
                {RupiahConvert(String(calcSubTotal(val))).detail}
              </TdFixed>
              <td className="p-0 align-middle">
                <SelectSearch
                  className="pt-2"
                  menuShouldBlockScroll={true}
                  menuPosition="fixed"
                  option={dropdownStatusPermintaan}
                  defaultValue={dropdownStatusPermintaan?.find(
                    ({ value }) => value === val.status_permintaan
                  )}
                  onChange={({ value }) =>
                    onChangeStatusPermintaan({ value, index })
                  }
                />
              </td>
              <Td>
                <div className="d-flex justify-content-center align-items-center">
                  <ActionButton
                    size="sm"
                    className="m-1"
                    onClick={() =>
                      setModalForm({
                        show: true,
                        data: val,
                      })
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </div>
              </Td>
            </tr>
          ))
        ) : (
          <tr>
            <Td colSpan={9}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </tr>
        )}
        {Boolean(data?.length) && (
          <tr className="font-weight-bold">
            <Td textRight colSpan={6}>
              Total Price
            </Td>
            <Td textRight>
              {RupiahConvert(String(calcGrandTotal(data ?? []))).detail}
            </Td>
            <Td colSpan={2} />
          </tr>
        )}
      </tbody>
    </Table>
  );
};
