import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPOverheadApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { hasNotifCheck } from 'pages/Transaksi/BDPProduksi/Helpers';
import TableHistoryOverhead from './comps/TableHistoryOverhead';
import TableRealisasiOverhead from './comps/TableRealisasiOverhead';

const BiayaOverheadPabrikSection = ({ overheadPabrik, setOverheadPabrik }) => {
  const location = useLocation();
  const {
    id_realisasi_produksi: id_realisasi_produksi_master,
    no_realisasi_produksi,
  } = location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [historyOverheadPabrik, setHistoryOverheadPabrik] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPOverheadApi.getSingle({ no_realisasi_produksi }),
      BDPOverheadApi.getSingleHistory({
        id_job_order,
        id_realisasi_produksi: id_realisasi_produksi_master,
      }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data?.realisasi_overhead;
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setOverheadPabrik(data);
          setHistoryOverheadPabrik(history);

          const hasNotif = hasNotifCheck(data, history);
          setNotif((prev) => ({
            ...prev,
            bdp_overhead: { status: hasNotif, bop: hasNotif },
          }));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getInitialData(), []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Biaya Overhead Pabrik
      </small>
      <TableRealisasiOverhead
        dataList={overheadPabrik}
        dataHistory={historyOverheadPabrik}
        getInitialData={getInitialData}
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistoryOverhead type="detail" dataHistory={historyOverheadPabrik} />
    </div>
  );
};

export default BiayaOverheadPabrikSection;
