import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { debounce, range } from 'lodash';
import axios from 'axios';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  SelectMonth,
  FilterButton,
} from 'components';
import { CustomSelect, ModalFilterHSRPakai, TableContent } from './components';
import { DateConvert } from 'utilities';
import { HargaSatuanRataRataPakaiApi } from 'api';
import { useIsGuest, useSessionStorage } from 'hooks';

const HargaSatuanRataRataPakai = ({ setNavbarTitle }) => {
  const today = new Date();
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };
  const [showFilter, setShowFilter] = useState(false);
  const [dataHSRSatuan, setDataHSRSatuan] = useState([]);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });

  const [dropdown, setDropdown] = useState({
    kategori: [],
    jenis: [],
  });

  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const [filter, setFilter] = useSessionStorage(SESSION_KEY.FILTER, {
    active: false,
    id_kelompok: undefined,
    id_jenis: undefined,
  });

  const [paginationConfig, setPaginationConfig] = useSessionStorage(
    SESSION_KEY.PAGINATION,
    {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    }
  );

  const getDropdown = () => {
    axios
      .all([
        HargaSatuanRataRataPakaiApi.dropdown({ tipe: 'kelompok' }),
        HargaSatuanRataRataPakaiApi.dropdown({ tipe: 'jenis' }),
      ])
      .then(
        axios.spread((resKelompok, resJenis) => {
          const kelompok = resKelompok.data.data.map((v) => ({
            value: v.id_kelompok,
            label: v.nama_kelompok,
          }));

          const jenis = resJenis.data.data.map((v) => ({
            value: v.id_jenis,
            label: v.nama_jenis,
            id_kelompok: v.id_kelompok,
          }));

          setDropdown({
            kelompok,
            jenis,
          });
        })
      );
  };

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    const date = `${dateSelected.year}-${dateSelected.month}-01`;
    const query = {
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
      id_kelompok: filter.id_kelompok,
      id_jenis: filter.id_jenis,
      tanggal: date,
    };

    if (dateSelected.month.toString() === '13') {
      query.tanggal = undefined;
      query.tahun = dateSelected.year;
    }

    HargaSatuanRataRataPakaiApi.page(query)
      .then(({ data }) => {
        setDataHSRSatuan(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const searchHandler = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
  };

  useEffect(
    () => getInitialData(),
    [
      searchConfig.key,
      filter.id_jenis,
      filter.id_kelompok,
      paginationConfig.page,
      paginationConfig.dataLength,
      dateSelected.month,
      dateSelected.year,
    ]
  );

  useEffect(() => {
    setNavbarTitle('Harga Satuan Rata-Rata Bahan');
    getDropdown();
    checkAlert();
  }, [setNavbarTitle]);

  return (
    <CRUDLayout>
      <Row className="mb-2 d-flex justify-content-end align-items-center">
        <Col
          md={5}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            value={filter?.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex flex-row justify-content-end align-items-center">
          <div className="d-flex align-items-center">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={dateSelected.month}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                });
              }}
            />
            <CustomSelect
              className="m-1"
              style={{ width: 100 }}
              defaultValue={dateSelected.year}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  year: e.target.value,
                });
                setPaginationConfig({
                  ...paginationConfig,
                  page: '1',
                });
              }}
            >
              <option value="">Pilih tahun</option>
              {range(today.getFullYear(), 1999).map((val) => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </CustomSelect>
            {!isGuest && (
              <div className="ml-1">
                <ActionButton
                  size="md"
                  text="Posting Data"
                  className="text-nowrap"
                  onClick={() =>
                    history.push(
                      '/transaksi/harga-satuan-rata-rata-bahan/tambah'
                    )
                  }
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {fetchingStatus.loading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent
          data={dataHSRSatuan}
          searchConfig={searchConfig}
          paginationConfig={paginationConfig}
          setPaginationConfig={setPaginationConfig}
        />
      )}

      <ModalFilterHSRPakai
        show={showFilter}
        setShow={setShowFilter}
        filter={filter}
        setFilter={setFilter}
        dropdown={dropdown}
        SESSION_KEY={SESSION_KEY}
      />
    </CRUDLayout>
  );
};

export default HargaSatuanRataRataPakai;
