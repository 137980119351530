import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  ThFixed,
  Td,
  BackButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PostingBiayaPrelimApi } from 'api';
import { IoAddOutline, IoEyeOutline } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';
import DetailSPK from './DetailSPK';

const ListSPKPostingBiayaPrelim = ({ setNavbarTitle }) => {
  const title = 'List Realisasi Prelim ';
  const history = useHistory();
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    id_spk: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PostingBiayaPrelimApi.pageSPK({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMore, setIsReadMore] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
            {data?.map((brg, index) => {
              if (isReadMore) {
                return (
                  <li key={index} index={index}>{`${
                    brg.nama_item
                  } (${parseFloat(brg.qty_rab ?? 0)} ${
                    brg.nama_satuan ?? ''
                  })`}</li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>{`${
                      brg.nama_item
                    } (${parseFloat(brg.qty_rab ?? 0)} ${
                      brg.nama_satuan ?? ''
                    })`}</li>
                  )
                );
              }
            })}
          </ul>
          {data?.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setIsReadMore((prev) => !prev)}
            >
              {isReadMore ? 'Sembunyikan' : 'Selengkapnya...'}
            </div>
          )}
        </>
      );
    };
    const DataTable = () => (
      <Table className="mt-1">
        <thead>
          <tr>
            <Th width={20}>No</Th>
            <ThFixed>Informasi SPK</ThFixed>
            <Th>Customer</Th>
            <Th>ATT</Th>
            <Th>Proyek</Th>
            <Th>Item Barang</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <ThFixed>Baseline</ThFixed>
            <Th>Total Akhir</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td>
                  <div>
                    {val.tgl_spk
                      ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_spk ? val.no_spk : '-'}</div>
                </Td>
                <Td>{val.nama_customer ?? ''}</Td>
                <Td>{val.att ?? ''}</Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <Td>
                  <BarangJadiCollapse data={val.barang_jadi ?? []} />
                </Td>
                <Td>
                  {val?.tgl_selesai_spk
                    ? DateConvert(new Date(val?.tgl_selesai_spk)).defaultDMY
                    : '-'}
                </Td>
                <Td>{val?.baseline ?? ''}</Td>
                <Td className="text-right">
                  {
                    RupiahConvert(String(parseInt(val?.total_akhir || 0)))
                      .detail
                  }
                </Td>
                <Td>
                  <ButtonGroup>
                    <ActionButton
                      size="sm"
                      className="col"
                      tooltipText="Detail SPK"
                      onClick={() =>
                        setModalConfig({ show: true, id_spk: val.id_spk })
                      }
                    >
                      <IoEyeOutline />
                    </ActionButton>

                    <ActionButton
                      size="sm"
                      className="col"
                      style={{ backgroundColor: '#36a2cd' }}
                      tooltipText="Posting Biaya"
                      onClick={() =>
                        history.push(
                          '/transaksi/posting-biaya-prelim/posting/' +
                            val.id_spk
                        )
                      }
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </ButtonGroup>
                </Td>
              </tr>
            ))}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mt-2">
          <b>List Realisasi Prelim Yang Siap Dibuatkan Posting Biaya Prelim</b>
        </div>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton className="mt-2" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}

      {/* Modal Detail SPK */}
      {modalConfig.show && (
        <Modal
          size="xl"
          show={modalConfig.show}
          onHide={() =>
            setModalConfig((prev) => ({ ...prev, show: false, id_spk: '' }))
          }
        >
          <Modal.Header closeButton>
            <b>Detail Data Surat Perjanjian Kerja (SPK)</b>
          </Modal.Header>
          <Modal.Body>
            <DetailSPK modalConfig={modalConfig} />
          </Modal.Body>
        </Modal>
      )}
    </CRUDLayout>
  );
};

export default ListSPKPostingBiayaPrelim;
