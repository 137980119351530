import React from 'react';
import { Modal as BSModal } from 'react-bootstrap';

const Modal = ({ title, show, onHide, children, closeButton }) => {
  return (
    <BSModal show={show} onHide={onHide}>
      <BSModal.Header closeButton={closeButton}>
        <b>{title}</b>
      </BSModal.Header>
      {children}
    </BSModal>
  );
};

export default Modal;
