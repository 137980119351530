import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPSisaProduksiApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { hasNotifCheck } from 'pages/Transaksi/BDPProduksi/Helpers';
import TableHistorySisaProduksi from './comps/TableHistorySisaProduksi';
import TableRealisasiSisaProduksi from './comps/TableRealisasiSisaProduksi';

const SisaProduksiSection = ({ sisaProduksi, setSisaProduksi }) => {
  const location = useLocation();
  const { id_realisasi_produksi: id_realisasi_produksi_master } =
    location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [isLoading, setIsLoading] = useState(true);
  const [historySisaProduksi, setHistorySisaProduksi] = useState([]);

  const getInitialData = () => {
    Axios.all([
      BDPSisaProduksiApi.getSingle({
        id_job_order,
        id_realisasi_produksi_master,
      }),
      BDPSisaProduksiApi.getSingleHistory({
        id_job_order,
        id_realisasi_produksi: id_realisasi_produksi_master,
      }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data?.realisasi_sisa_produksi ?? [];
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setSisaProduksi(data);
          setHistorySisaProduksi(history);

          const hasNotif = hasNotifCheck(data, history);
          setNotif((prev) => ({
            ...prev,
            bdp_sisa_produksi: {
              status: hasNotif,
              sisa_produksi: hasNotif,
            },
          }));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInitialData();

    return () => {
      setIsLoading(true);
    };
  }, []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Sisa Produksi
      </small>
      <TableRealisasiSisaProduksi
        dataList={sisaProduksi}
        dataHistory={historySisaProduksi}
        getInitialData={getInitialData}
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistorySisaProduksi
        type="detail"
        dataHistory={historySisaProduksi}
      />
    </div>
  );
};

export default SisaProduksiSection;
