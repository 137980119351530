import { useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownTransferOverheadContext } from '../Context';

export const ModalFilterPreform = ({ show, setShow, filter, setFilter }) => {
  const defaultDropdown = { value: null, label: 'Semua' };

  const { dropdownItem, dropdownCustomer, dropdownProyek } = useContext(
    DropdownTransferOverheadContext
  );

  const optItems = [defaultDropdown].concat(dropdownItem.data ?? []);
  const optCustomer = [defaultDropdown].concat(dropdownCustomer.data ?? []);
  const optProyek = [defaultDropdown].concat(dropdownProyek.data ?? []);

  const initialValues = {
    tgl_permintaan_produksi_start: filter?.tgl_permintaan_produksi_start,
    tgl_permintaan_produksi_end: filter?.tgl_permintaan_produksi_end,
    id_customer: filter?.id_customer,
    id_proyek: filter?.id_proyek,
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    id_barang_jadi: filter?.id_barang_jadi,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_permintaan_produksi_start: undefined,
      tgl_permintaan_produksi_end: undefined,
      id_proyek: undefined,
      id_customer: undefined,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      id_barang_jadi: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Permintaan Produksi"
            placeholderText="Pilih Tanggal Permintaan Produksi"
            startDate={
              values.tgl_permintaan_produksi_start
                ? new Date(values.tgl_permintaan_produksi_start)
                : ''
            }
            endDate={
              values.tgl_permintaan_produksi_end
                ? new Date(values.tgl_permintaan_produksi_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_permintaan_produksi_start', startDate);
              setFieldValue('tgl_permintaan_produksi_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Customer"
            placeholder="Pilih Nama Customer"
            loading={dropdownCustomer?.isLoading}
            option={optCustomer}
            defaultValue={
              values.id_customer
                ? optCustomer.find((v) => values.id_customer === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_customer', value)}
          />

          <SelectSearch
            label="Proyek"
            placeholder="Pilih Proyek"
            loading={dropdownProyek?.isLoading}
            option={optProyek}
            defaultValue={
              values.id_proyek
                ? optProyek.find((v) => values.id_proyek === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_proyek', value)}
          />

          <DatePicker
            selectsRange
            label="Tgl. Job Order"
            placeholderText="Pilih Tanggal Job Order"
            startDate={
              values.tgl_job_order_start
                ? new Date(values.tgl_job_order_start)
                : ''
            }
            endDate={
              values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_job_order_start', startDate);
              setFieldValue('tgl_job_order_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Job Order"
            placeholder="Pilih Item Job Order"
            loading={dropdownItem?.isLoading}
            option={optItems}
            defaultValue={
              values.id_barang_jadi
                ? optItems.find((v) => values.id_barang_jadi === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => {
              setFieldValue('id_barang_jadi', value);
            }}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
