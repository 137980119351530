export const CustomSelect = (props) => (
  <>
    <select
      {...props}
      className={`custom-select custom-select ${props.className} ${
        props.error && 'is-invalid'
      }`}
    >
      {props.children}
    </select>
    <div className="invalid-feedback">{props.errorText}</div>
  </>
);
