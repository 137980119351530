import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  Table,
  Th,
  ThFixed,
  Td,
  FilterButton,
  CreateButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { TransferProduksiOverheadApi } from 'api';
import { useSessionStorage } from 'hooks';
import { withDropdownTransferOverheadProvider } from './Context';
import { ModalFilterTransferOverhead } from './Components';

const TransferProduksiOverheadList = ({ setNavbarTitle }) => {
  const title = 'Transfer Overhead Produksi';
  const history = useHistory();
  const location = useLocation();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    tgl_transfer_produksi_overhead_start: undefined,
    tgl_transfer_produksi_overhead_end: undefined,
    id_barang_jadi: undefined,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsLoading(true);

    TransferProduksiOverheadApi.page({
      page: pagination.page,
      per_page: pagination.per_page,
      q: pagination.q,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      tgl_transfer_produksi_overhead_start:
        pagination.tgl_transfer_produksi_overhead_start,
      tgl_transfer_produksi_overhead_end:
        pagination.tgl_transfer_produksi_overhead_end,
      id_barang_jadi: pagination.id_barang_jadi,
    })
      .then((res) => {
        setData(res.data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState.alert);
    }
  };

  const dismissAlert = () => {
    const currentState = history.location.state;

    if (currentState?.alert) {
      let state = { ...currentState };
      delete state.alert;

      history.replace(history.location.pathname, state);
      setAlertConfig({
        text: '',
        variant: 'primary',
      });
    }
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.tgl_transfer_produksi_overhead_start,
    pagination.tgl_transfer_produksi_overhead__end,
    pagination.id_barang_jadi,
  ]);

  useEffect(() => checkAlert(), []);

  const TableContent = () => {
    return (
      <>
        <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
          List Data Transfer Overhead Produksi
        </div>

        <Table>
          <thead>
            <tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Job Order</ThFixed>
              <Th width={250}>Item Job Order</Th>
              <ThFixed>Informasi Transfer Overhead Produksi</ThFixed>
              <Th width={250}>Item Overhead</Th>
              <Th width={200}>Keterangan</Th>
            </tr>
          </thead>

          <tbody>
            {data?.map((val, index) => (
              <tr key={index}>
                <Td className="text-center align-middle">
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>
                <Td>
                  <div>
                    {val.tgl_job_order
                      ? DateConvert(new Date(val.tgl_job_order)).detail
                      : '-'}
                  </div>
                  <div>{val.no_job_order}</div>
                </Td>
                <Td>{val.nama_barang_jadi}</Td>
                <Td>
                  <div>
                    {val.tgl_transfer_produksi_overhead
                      ? DateConvert(
                          new Date(val.tgl_transfer_produksi_overhead)
                        ).detail
                      : '-'}
                  </div>
                  <div>{val.no_transfer_produksi_overhead}</div>
                </Td>
                <Td>
                  {`${val.nama_item} 
                  ${parseFloat(val.qty_transfer_produksi_overhead ?? '0')} 
                  ${val.nama_satuan ?? ''}`}
                </Td>
                <Td>{val.keterangan}</Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({ ...prev, page: selected + 1 }))
          }
          onDataLengthChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }));
          }}
        />
      </>
    );
  };

  return (
    <>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch
            onChange={debounce((e) => {
              const searchKey = e.target.value;

              setPagination((prev) => ({
                ...prev,
                q: searchKey,
                page: '1',
              }));
            }, 1500)}
          />

          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <CreateButton
            onClick={() =>
              history.push(
                '/transaksi/transfer-produksi-overhead/list-permintaan-produksi'
              )
            }
          />
        </Col>
      </Row>

      <Alert
        show={Boolean(alertConfig.text.length)}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={dismissAlert}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data?.length ? (
        <TableContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterTransferOverhead
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownTransferOverheadProvider(
  TransferProduksiOverheadList
);
