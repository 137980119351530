/*eslint-disable*/

import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import _, { debounce } from 'lodash';
import axios from 'axios';
import {
  ActionButton,
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  SelectMonth,
} from 'components';
import { HargaSatuanRataRataOverheadApi } from 'api';
import { useSessionStorage } from 'hooks';
import { DateConvert } from 'utilities';
import {
  CustomSelect,
  TableContent,
  ModalFilterHSROverhead,
} from './components';

const HargaSatuanRataRataOverhead = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const today = new Date();
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };

  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [dateSelected, setDateSelected] = useState({
    month: DateConvert(today).defaultMonth,
    year: DateConvert(today).defaultYear,
  });
  const [dropdown, setDropdown] = useState({
    kelompok: [],
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [filter, setFilter] = useSessionStorage(SESSION_KEY.FILTER, {
    active: false,
    id_kelompok: undefined,
  });
  const [pagination, setPagination] = useSessionStorage(
    SESSION_KEY.PAGINATION,
    {
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    }
  );

  const getInitialData = () => {
    setIsPageLoading(true);

    const tanggal = `${dateSelected.year}-${dateSelected.month}-01`;

    let query = {
      q: searchConfig.key,
      page: pagination.page,
      per_page: pagination.dataLength,
      id_kelompok: filter.id_kelompok,
      tanggal,
    };

    if (searchConfig.bulan === '13') {
      query.tanggal = undefined;
      query.tahun = dateSelected.year;
    }

    HargaSatuanRataRataOverheadApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination({
          ...pagination,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getDropdown = () => {
    axios.all([HargaSatuanRataRataOverheadApi.dropdown()]).then(
      axios.spread((resKelompok) => {
        const kelompok = resKelompok.data.data.map((j) => ({
          value: j.id_kelompok,
          label: j.nama_kelompok,
        }));
        setDropdown({ kelompok });
      })
    );
  };

  const searchHandler = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPagination({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('Harga Satuan Rata-Rata Overhead');
    getDropdown();
  }, []);

  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    searchConfig.key,
    pagination.page,
    pagination.dataLength,
    filter.id_kelompok,
    dateSelected.month,
    dateSelected.year,
  ]);

  return (
    <CRUDLayout>
      <Row className="mb-2 d-flex justify-content-end align-items-center">
        <Col
          md={5}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            value={filter?.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex flex-row justify-content-end align-items-center">
          <div className="d-flex align-items-center">
            <SelectMonth
              noMargin
              size="md"
              wrapperClassName="m-1"
              style={{ width: 150 }}
              defaultValue={DateConvert(today).defaultMonth}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });

                setPagination({
                  ...pagination,
                  page: '1',
                });
              }}
            />

            <CustomSelect
              name="tahun"
              className="m-1"
              style={{ width: 100 }}
              defaultValue={searchConfig.tahun}
              onChange={(e) => {
                setDateSelected({
                  ...dateSelected,
                  month: e.target.value,
                });

                setPagination({
                  ...pagination,
                  page: '1',
                });
              }}
            >
              {_.range(today.getFullYear(), 1999).map((e) => (
                <option value={e}>{e}</option>
              ))}
            </CustomSelect>

            <ActionButton
              className="m-1 text-nowrap"
              text="Posting Data"
              onClick={() =>
                history.push(
                  '/transaksi/harga-satuan-rata-rata-overhead/tambah'
                )
              }
            />
          </div>
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent
          data={data}
          searchConfig={searchConfig}
          paginationConfig={pagination}
          setPaginationConfig={setPagination}
        />
      )}

      {showFilter && (
        <ModalFilterHSROverhead
          show={showFilter}
          setShow={setShowFilter}
          filter={filter}
          setFilter={setFilter}
          dropdown={dropdown}
          SESSION_KEY={SESSION_KEY}
        />
      )}
    </CRUDLayout>
  );
};

export default HargaSatuanRataRataOverhead;
