import { useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { BadgeNotif } from 'pages/Transaksi/BDPProduksi/Components';
import BiayaOverheadPabrikSection from './BiayaOverheadPabrikSection';

const TabOverhead = ({ overheadPabrik, setOverheadPabrik }) => {
  const { notif } = useContext(NotifBDPContext);

  return (
    <Card className="border">
      <Tab.Container defaultActiveKey="bop">
        <Card.Header className='className="d-flex justify-content-between align-items-center'>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="bop">
                Biaya Overhead Pabrik
                <BadgeNotif show={notif.bdp_overhead.bop} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="bop">
            <BiayaOverheadPabrikSection
              overheadPabrik={overheadPabrik}
              setOverheadPabrik={setOverheadPabrik}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default TabOverhead;
