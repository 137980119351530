import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  InfoItemHorizontal,
  TextArea,
  ActionButton,
  SelectSearch,
  DatePicker,
  Input,
} from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { BDPAlatMesinApi } from 'api';

const ModalJurnalBDP = ({
  modalConfig,
  setModalConfig,
  getInitialData,
  type,
}) => {
  const today = DateConvert(new Date()).default;
  const [dropdownCOA, setDropdownCOA] = useState([]);
  const dataModal = modalConfig?.data ?? {};

  const formInitialValues = {
    id_realisasi_produksi_alat_mesin:
      dataModal?.id_realisasi_produksi_alat_mesin ?? '',
    tgl_realisasi_produksi_alat_mesin:
      dataModal?.tgl_realisasi_produksi_alat_mesin ?? '-',
    no_realisasi_produksi_alat_mesin:
      dataModal?.no_realisasi_produksi_alat_mesin ?? '-',
    nama_item_aset: dataModal?.nama_item_aset ?? '-',
    qty_realisasi_produksi_alat_mesin:
      dataModal?.qty_realisasi_produksi_alat_mesin ?? 0,
    harga_satuan_penyusutan: dataModal?.harga_satuan_penyusutan ?? 0,
    nama_satuan: dataModal?.nama_satuan ?? '',

    tgl_bdp_alat_mesin:
      type === 'update' ? dataModal?.tgl_bdp_alat_mesin ?? '' : '',
    no_bdp_alat_mesin:
      type === 'update' ? dataModal?.no_bdp_alat_mesin ?? '' : '',
    jumlah:
      parseFloat(dataModal?.qty_realisasi_produksi_alat_mesin ?? 0) *
      parseInt(dataModal?.harga_satuan_penyusutan ?? 0),
    id_coa_debet: type === 'update' ? dataModal?.id_coa_debet : '1010505',
    id_coa_kredit: type === 'update' ? dataModal?.id_coa_kredit : '1020302',
    keterangan_jurnal:
      type === 'update' ? dataModal.keterangan_jurnal ?? '' : '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_bdp_alat_mesin: Yup.string().required('Pilih tanggal BDP'),
    no_bdp_alat_mesin: Yup.string().required(
      'Pilih tgl. realisasi untuk menentukan nomor'
    ),
    id_coa_debet: Yup.string().required('Pilih COA Debet'),
    id_coa_kredit: Yup.string().required('Pilih COA Kredit'),
    keterangan_jurnal: Yup.string().required('Keterangan Jurnal wajib diisi'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = {
      ...values,
      nomor_akun_debet: values.id_coa_debet,
      nomor_akun_kredit: values.id_coa_kredit,
    };

    BDPAlatMesinApi.save(finalValue)
      .then(() => {
        setSubmitting(false);
        setModalConfig({ show: false, data: {} });
        getInitialData();
      })
      .catch(() => {
        setSubmitting(false);
        window.alert('Gagal menyimpan data!');
      });
  };

  const fetchCOA = () => {
    BDPAlatMesinApi.getCOA()
      .then((res) => {
        const coa = res?.data?.data ?? [];
        const mapcoa = coa.map((item) =>
          Object({
            ...item,
            value: item.id_coa,
            label: `${item.nomor_akun} - ${item.nama_akun}`,
          })
        );
        setDropdownCOA(mapcoa ?? []);
      })
      .catch(() => alert('Gagal memuat data COA!'));
  };

  const InfoSection = ({ values }) => {
    return (
      <>
        <InfoItemHorizontal
          label="Tgl. Realisasi Produksi"
          text={
            DateConvert(new Date(values.tgl_realisasi_produksi_alat_mesin))
              .detail
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi"
          text={values.no_realisasi_produksi_alat_mesin}
        />
        <InfoItemHorizontal
          label="Item Alat Mesin"
          text={values.nama_item_aset}
        />
        <InfoItemHorizontal
          label="Qty."
          text={`${values.qty_realisasi_produksi_alat_mesin} ${values.nama_satuan}`}
        />
        <InfoItemHorizontal
          label="Harga Satuan"
          text={
            RupiahConvert(String(parseInt(values.harga_satuan_penyusutan ?? 0)))
              .detail
          }
        />
      </>
    );
  };

  const FormBody = ({ formik }) => {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      setValues,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = formik;

    const getNoHandler = (tanggal = today) => {
      BDPAlatMesinApi.getNomor({ tanggal }).then((res) => {
        setValues({
          ...values,
          tgl_bdp_alat_mesin: tanggal,
          no_bdp_alat_mesin: res.data.data,
        });
      });
    };

    useEffect(() => type !== 'update' && getNoHandler(), []);

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <InfoSection values={values} />
          <hr />
          <Row>
            <Col md>
              <DatePicker
                label="Tgl. BDP"
                type="date"
                placeholderText="Pilih tgl. BDP"
                selected={
                  values.tgl_bdp_alat_mesin
                    ? new Date(values.tgl_bdp_alat_mesin)
                    : ''
                }
                onChange={(date) => {
                  const newDate = DateConvert(date).default;
                  getNoHandler(newDate);
                }}
                error={
                  Boolean(errors.tgl_bdp_alat_mesin) &&
                  touched.tgl_bdp_alat_mesin
                }
                errorText={errors.tgl_bdp_alat_mesin}
              />
            </Col>
            <Col md>
              <Input
                label="No. BDP"
                type="text"
                placeholder="Pilih tgl. BDP"
                value={values.no_bdp_alat_mesin}
                error={
                  Boolean(errors.no_bdp_alat_mesin) && touched.no_bdp_alat_mesin
                }
                errorText={errors.no_bdp_alat_mesin}
                readOnly
              />
            </Col>
          </Row>
          {console.log(values)}
          <Input
            label="Jumlah"
            value={RupiahConvert(String(parseInt(values.jumlah))).detail}
            className="text-right"
            readOnly
          />
          <SelectSearch
            label="COA Debet"
            name="id_coa_debet"
            placeholder="Pilih COA Debet"
            onChange={(val) => setFieldValue('id_coa_debet', val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_debet ? item : ''
            )}
            error={errors.id_coa_debet && touched.id_coa_debet && true}
            errorText={
              errors.id_coa_debet && touched.id_coa_debet && errors.id_coa_debet
            }
          />
          <SelectSearch
            label="COA Kredit"
            name="id_coa_kredit"
            placeholder="Pilih COA Kredit"
            onChange={(val) => setFieldValue('id_coa_kredit', val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_kredit ? item : ''
            )}
            error={errors.id_coa_kredit && touched.id_coa_kredit && true}
            errorText={
              errors.id_coa_kredit &&
              touched.id_coa_kredit &&
              errors.id_coa_kredit
            }
          />
          <TextArea
            label="Keterangan Jurnal*"
            name="keterangan_jurnal"
            value={values.keterangan_jurnal}
            onChange={handleChange}
            error={
              errors.keterangan_jurnal && touched.keterangan_jurnal && true
            }
            errorText={errors.keterangan_jurnal}
            rows={5}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant={type === 'update' ? 'warning' : 'primary'}
            type="submit"
            text="Simpan"
            className="px-4 text-white"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    );
  };

  useEffect(() => {
    fetchCOA();

    return () => {};
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>{`${type === 'update' ? 'Ubah' : 'Tambah'} Data Jurnal BDP`}</b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => <FormBody formik={formik} />}
      </Formik>
    </Modal>
  );
};

export default ModalJurnalBDP;
