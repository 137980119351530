import React, { useState, useEffect } from 'react';
import HTMLReactParser from 'html-react-parser';
import { DateConvert, RupiahConvert } from 'utilities';
// import { TableItemKontrak } from "./TableItemKontrak";
import { Modal } from 'react-bootstrap';
import { BDPProduksiApi } from 'api';
import { DataStatus, Table, Td, TdFixed, Th, ThFixed } from 'components';

const DetailKontrak = ({ setModalConfig, modalConfig, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getDetailKontrak = () => {
    setIsLoading(true);

    if (id) {
      BDPProduksiApi.getKontrakUpah({ id_kontrak_upah: id })
        .then((data) => {
          setData(data.data.data);
        })
        .catch((err) => alert('Data Kontrak Gagal Dimuat !!'))
        .finally(() => setIsLoading(false));
    }
  };

  const TableItemKontrak = ({ dataItem }) => {
    return (
      <>
        <Table>
          <thead className="bg-light">
            <tr>
              <ThFixed>No</ThFixed>
              <Th style={{ minWidth: 200 }}>Item Pekerjaan</Th>
              <Th style={{ width: 80 }}>Volume</Th>
              <Th style={{ minWidth: 100 }}>Satuan</Th>
              <Th style={{ width: 150 }}>Harga Satuan</Th>
              <Th style={{ width: 150 }}>Jumlah</Th>
            </tr>
          </thead>
          <tbody>
            {dataItem.map((val, ind) => (
              <>
                <tr>
                  <TdFixed>{ind + 1}</TdFixed>
                  <Td>{val.uraian ?? '-'}</Td>
                  <Td>{val.qty ?? '-'}</Td>
                  <Td>{val.nama_satuan ?? '-'}</Td>
                  <Td>
                    {RupiahConvert(parseInt(val.unit_price).toString())
                      .detail ?? '-'}
                  </Td>
                  <Td>
                    {
                      RupiahConvert(
                        parseInt(
                          parseFloat(val.qty ?? 0) *
                            parseFloat(val.unit_price ?? 0) *
                            parseFloat(val.konstanta ?? 0)
                        ).toString()
                      ).detail
                    }
                  </Td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  useEffect(() => {
    getDetailKontrak();
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <Modal
      size="xl"
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>Kontrak Upah</b>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <DataStatus loading text="Memuat Data..." />
        ) : (
          <div className="bg-white">
            {HTMLReactParser(data?.teks_kontrak_part_1 ?? '<p></p>')}
            <div>
              <TableItemKontrak
                dataItem={
                  data?.detail
                    ? data.detail.map((val) => ({
                        uraian: val?.uraian ?? '-',
                        qty: parseFloat(val?.qty ?? 0),
                        nama_satuan: val?.nama_satuan ?? '-',
                        unit_price: parseFloat(val?.unit_price ?? 0),
                        konstanta: parseFloat(val?.konstanta ?? 0),
                      }))
                    : []
                }
              />
            </div>
            {HTMLReactParser(data?.teks_kontrak_part_2 ?? '<p></p>')}
            {HTMLReactParser(data?.teks_kontrak_part_3 ?? '<p></p>')}
            <div className="mt-5 d-flex justify-content-around align-items-end">
              {/* Pihak Kedua */}
              <div className="text-center">
                <b>Pihak Kedua</b>
                <div style={{ marginTop: '100px', width: 250 }}>
                  <b>{data?.nama_vendor ?? ''}</b>
                  <hr />
                  <b>{data?.jenis_vendor}</b>
                </div>
              </div>

              {/* Pihak Pertama */}
              <div className="text-center">
                <p>
                  <span>
                    Denpasar,{' '}
                    {DateConvert(new Date(data.tgl_kontrak_upah)).defaultDay}{' '}
                    {DateConvert(new Date(data.tgl_kontrak_upah)).detailMonth}{' '}
                    {DateConvert(new Date(data.tgl_kontrak_upah)).defaultYear}
                  </span>
                  <br />
                  <b>Pihak Pertama</b>
                </p>
                <div style={{ marginTop: '100px', width: 250 }}>
                  <b>{data?.nama_karyawan ?? ''}</b>
                  <hr />
                  <b>{data?.nama_jabatan}</b>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DetailKontrak;
