import { DatePicker, SelectSearch, FilterModal } from 'components';
import { DateConvert } from 'utilities';
import { useFormik } from 'formik';

export const ModalFilterRealisasiProduksi = ({
  SESSION_KEY,
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
  isLoading,
}) => {
  const filter = data?.filter;
  const formInitialValues = {
    tgl_realisasi_awal: filter?.tgl_realisasi_awal,
    tgl_realisasi_akhir: filter?.tgl_realisasi_akhir,
    no_spk: filter?.no_spk,
    nama_customer: filter?.nama_customer,
    nama_proyek: filter?.nama_proyek,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 },
      });
    } else {
      setData({ ...data, filter: { ...values, active: false } });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setFieldValue, setValues, handleSubmit } = formik;

  const onChangeTglRealisasi = (dates) => {
    const [start, end] = dates;

    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_realisasi_awal: startDate,
      tgl_realisasi_akhir: endDate,
    });
  };

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER);
    sessionStorage.removeItem(SESSION_KEY.PAGINATION);

    setData((prev) => ({
      ...prev,
      filter: {
        tgl_realisasi_awal: undefined,
        tgl_realisasi_akhir: undefined,
        no_spk: undefined,
        nama_customer: undefined,
        nama_proyek: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Realisasi Produksi"
        placeholderText="Pilih tanggal realisasi produksi"
        startDate={
          values?.tgl_realisasi_awal ? new Date(values?.tgl_realisasi_awal) : ''
        }
        endDate={
          values?.tgl_realisasi_akhir
            ? new Date(values?.tgl_realisasi_akhir)
            : ''
        }
        onChange={(dates) => onChangeTglRealisasi(dates)}
        monthsShown={2}
      />

      <SelectSearch
        label="No. SPK"
        placeholder="Pilih No. SPK"
        option={dropdown.spk}
        defaultValue={
          values.no_spk
            ? dropdown.spk.find((v) => v.value === values.no_spk)
            : null
        }
        onChange={({ value }) => setFieldValue('no_spk', value)}
      />
      <SelectSearch
        key={values.nama_customer}
        label="Customer"
        placeholder="Pilih customer"
        loading={isLoading.dropdown}
        defaultValue={dropdown?.customer?.find(
          (item) => item.value === values.nama_customer
        )}
        option={dropdown.customer}
        onChange={(val) => setFieldValue('nama_customer', val.value)}
      />
      <SelectSearch
        key={values.nama_proyek}
        label="Proyek"
        placeholder="Pilih proyek"
        loading={isLoading.dropdown}
        defaultValue={
          dropdown?.proyek?.find((item) => item.value === values.nama_proyek) ??
          null
        }
        option={dropdown.proyek}
        onChange={(val) => setFieldValue('nama_proyek', val.value)}
      />
    </FilterModal>
  );
};
