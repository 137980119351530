import Services from 'services';

class HargaSatuanRataRataPenyusutanApi {
  page(params) {
    return Services.get('/harga_satuan_penyusutan/page', { params });
  }

  hsr(params) {
    return Services.get('/harga_satuan_penyusutan/hitung_hsr', { params });
  }

  create(value) {
    return Services.post('/harga_satuan_penyusutan', value);
  }

  dropdown(params) {
    return Services.get('/harga_satuan_penyusutan/dropdown', { params });
  }

  cekStatus(params) {
    return Services.get('/harga_satuan_penyusutan/cek_status_posting', {
      params,
    });
  }
}

export default new HargaSatuanRataRataPenyusutanApi();
