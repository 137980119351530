import { useContext } from 'react';
import { useFormik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownHPPContext } from '../Context';

export const ModalFilterHPP = ({ show, setShow, filter, setFilter }) => {
  const { dropdownStatusJurnal, dropdownCustomer, dropdownBarangJadi } =
    useContext(DropdownHPPContext);
  const defaultDropdown = { value: null, label: 'Semua' };
  const initialValues = {
    tgl_hpp_start: filter?.tgl_hpp_start,
    tgl_hpp_end: filter?.tgl_hpp_end,
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    id_barang_jadi: filter?.id_barang_jadi,
    id_customer: filter?.id_customer,
    status_jurnal: filter?.status_jurnal,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...prev, ...values, active: false }));
    setShow(false);
  };

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit,
  });

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      active: false,
      tgl_hpp_start: null,
      tgl_hpp_end: null,
      tgl_job_order_start: null,
      tgl_job_order_end: null,
      id_barang_jadi: null,
      id_customer: null,
      status_jurnal: null,
    }));

    setShow(false);
  };

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. HPP"
        placeholderText="Pilih Tanggal HPP"
        monthsShown={2}
        startDate={values.tgl_hpp_start ? new Date(values.tgl_hpp_start) : ''}
        endDate={values.tgl_hpp_end ? new Date(values.tgl_hpp_end) : ''}
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_hpp_start', startDate);
          setFieldValue('tgl_hpp_end', endDate);
        }}
      />

      <DatePicker
        selectsRange
        label="Tgl. Job Order"
        placeholderText="Pilih Tanggal Job Order"
        monthsShown={2}
        startDate={
          values.tgl_job_order_start ? new Date(values.tgl_job_order_start) : ''
        }
        endDate={
          values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_job_order_start', startDate);
          setFieldValue('tgl_job_order_end', endDate);
        }}
      />

      <SelectSearch
        label="Item Job Order"
        placeholder="Pilih Item Job Order"
        option={[defaultDropdown].concat(dropdownBarangJadi?.data ?? [])}
        onChange={({ value }) => setFieldValue('id_barang_jadi', value)}
        defaultValue={
          values.id_barang_jadi
            ? dropdownBarangJadi?.data?.find(
                (v) => v.value === values.id_barang_jadi
              )
            : defaultDropdown
        }
      />

      <SelectSearch
        label="Customer"
        placeholder="Pilih Customer"
        option={[defaultDropdown].concat(dropdownCustomer?.data ?? [])}
        onChange={({ value }) => setFieldValue('id_customer', value)}
        defaultValue={
          values.id_customer
            ? dropdownCustomer?.data?.find(
                (v) => v.value === values.id_customer
              )
            : defaultDropdown
        }
      />

      <SelectSearch
        label="Status Jurnal"
        placeholder="Pilih Status Jurnal"
        option={dropdownStatusJurnal}
        onChange={({ value }) => setFieldValue('status_jurnal', value)}
        defaultValue={
          values.status_jurnal
            ? dropdownStatusJurnal?.find(
                (v) => v.value === values.status_jurnal
              )
            : defaultDropdown
        }
      />
    </FilterModal>
  );
};
