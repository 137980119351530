import Logo from 'assets/image/LogoSadhana.png';
import MenuConfig from './MenuConfig';
import RoutesConfig from './RoutesConfig';

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  LOGO: Logo, //Logo
  MENU: MenuConfig, //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig, //Konfigurasi routing
  MODUL: 'Cost Control',
};
