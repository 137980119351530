/*eslint-disable*/
import React, { Fragment, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { Modal, Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import {
  Input,
  InputCurrency,
  DatePicker,
  Select,
  TextArea,
  ButtonCreate,
  InfoItemVertical,
} from 'components2';
import { dateConvert, rupiahConvert } from 'utilities2';
import TabGambar from './TabGambar';
import TabFile from './TabFile';
import {
  accDocTypes,
  accImageTypes,
  generateNoPosting,
} from '../__PostingPettyCashUtils__';
import PostingPettyCashApi from '../__PostingPettyCashApi__';

export const ModalFormRincian = ({ show, type, onHide, tableData }) => {
  const TODAY = new Date();
  const [mockNumber, setMockNumber] = useState({});
  const isDetail = type === 'DETAIL';
  const isCreate = type === 'CREATE';
  const {
    values: valuesRincian,
    errors: errorsRincian,
    touched: touchedRincian,
    setFieldValue: setFieldValueRincian,
    handleChange: handleChangeRincian,
    handleSubmit: handleSubmitRincian,
  } = useFormikContext();

  const generateMockNumber = async (date) => {
    const formatMonthYear = (date) =>
      `${dateConvert().getMonth(new Date(date))}/${dateConvert().getYear(
        new Date(date)
      )}`;
    const selectedMonthYear = formatMonthYear(date);

    if (mockNumber.hasOwnProperty(selectedMonthYear)) {
      const count =
        tableData?.filter(
          (val) =>
            formatMonthYear(val.tgl_posting_petty_cash) === selectedMonthYear
        ).length ?? 0;

      const splitPostingNumber = mockNumber[selectedMonthYear].split('/');
      const lastIndex = splitPostingNumber.length - 1;
      const incrementLastNumber = +splitPostingNumber[lastIndex] + count;
      const formatIncrementLastNumber = ('000000' + incrementLastNumber).slice(
        -6
      );
      splitPostingNumber.splice(lastIndex, 1, formatIncrementLastNumber);

      return splitPostingNumber.join('/');
    } else {
      const serverNumber = await generateNoPosting(date);
      setMockNumber((prev) => ({ ...prev, [selectedMonthYear]: serverNumber }));
      return serverNumber;
    }
  };

  const { data: coa, isLoading } = useQuery({
    queryKey: ['dropdown', 'coa'],
    queryFn: () => PostingPettyCashApi.getDropdownCOA(),
  });

  useEffect(async () => {
    if (isCreate) {
      const nomorPosting = await generateMockNumber(TODAY);

      setFieldValueRincian('tgl_posting_petty_cash', TODAY);
      setFieldValueRincian('no_posting_petty_cash', nomorPosting);
    }
  }, [show, type, valuesRincian?.id_realisasi_petty_cash_detail]);

  const DetailFormBody = () => {
    return (
      <Fragment>
        <Row>
          <Col>
            <InfoItemVertical label="Item" text={valuesRincian?.nama_item} />
          </Col>
          <Col>
            <InfoItemVertical
              label="Qty"
              text={`${valuesRincian?.qty} ${valuesRincian?.nama_satuan ?? ''}`}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Harga Satuan"
              text={rupiahConvert().getWithComa(valuesRincian?.harga_satuan)}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="Jumlah"
              text={rupiahConvert().getWithComa(
                Math.round(valuesRincian?.harga_satuan) *
                  Math.round(valuesRincian?.qty)
              )}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Posting Petty Cash"
              text={
                valuesRincian?.tgl_posting_petty_cash
                  ? dateConvert().getDetailFull(
                      new Date(valuesRincian?.tgl_posting_petty_cash)
                    )
                  : '-'
              }
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="No. Posting Petty Cash"
              text={valuesRincian?.no_posting_petty_cash ?? '-'}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="COA Debet"
              text={`${valuesRincian?.nomor_akun_debet} - ${valuesRincian?.nama_akun_debet}`}
            />
          </Col>
          <Col>
            <InfoItemVertical
              label="COA Kredit"
              text={`${valuesRincian?.nomor_akun_kredit} - ${valuesRincian?.nama_akun_kredit}`}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoItemVertical
              label="Keterangan"
              text={valuesRincian?.keterangan}
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {isDetail ? 'Detail' : ''} Posting Petty Cash
      </Modal.Header>
      <Modal.Body>
        {isDetail ? (
          <DetailFormBody />
        ) : (
          <Fragment>
            <Row>
              <Col md="3">
                <Input
                  disabled
                  label="Item"
                  name="nama_item"
                  placeholder="Masukkan Nama Item"
                  value={valuesRincian?.nama_item}
                  onChange={handleChangeRincian}
                  error={errorsRincian.nama_item && touchedRincian.nama_item}
                  errorText={errorsRincian.nama_item}
                />
              </Col>

              <Col md="3">
                <Input
                  disabled
                  label="Qty"
                  name="qty"
                  placeholder="Masukkan Qty."
                  type="number"
                  value={valuesRincian?.qty}
                  onChange={handleChangeRincian}
                  error={errorsRincian.qty && touchedRincian.qty}
                  errorText={errorsRincian.qty}
                />
              </Col>

              <Col md="3">
                <Select
                  disable
                  name="id_satuan"
                  label="Satuan"
                  placeholder="Pilih Satuan"
                  defaultValue={{
                    value: valuesRincian?.id_satuan,
                    label: valuesRincian?.nama_satuan,
                  }}
                />
              </Col>

              <Col md="3">
                <InputCurrency
                  disabled
                  label="Jumlah"
                  name="jumlah"
                  placeholder="Rp0"
                  className="text-right"
                  value={
                    Math.round(valuesRincian?.harga_satuan ?? 0) *
                    Math.round(valuesRincian?.qty ?? 0)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md="3">
                <DatePicker
                  label="Tgl. Posting Petty Cash"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    valuesRincian?.tgl_posting_petty_cash
                      ? new Date(valuesRincian?.tgl_posting_petty_cash)
                      : ''
                  }
                  onChange={async (date) => {
                    // const nomor = await generateNoPosting(date)
                    const nomor = await generateMockNumber(date);
                    setFieldValueRincian('tgl_posting_petty_cash', date);
                    setFieldValueRincian('no_posting_petty_cash', nomor);
                  }}
                  error={
                    errorsRincian.tgl_posting_petty_cash &&
                    touchedRincian.tgl_posting_petty_cash
                  }
                  errorText={errorsRincian.tgl_posting_petty_cash}
                />
              </Col>

              <Col md="3">
                <Input
                  disabled
                  label="No. Posting Petty Cash"
                  value={valuesRincian?.no_posting_petty_cash}
                />
              </Col>

              <Col md="3">
                <Select
                  label="COA Debet"
                  options={coa ?? []}
                  loading={isLoading}
                  defaultValue={
                    valuesRincian?.nomor_akun_debet
                      ? coa?.find(
                          (val) => val.value === valuesRincian?.nomor_akun_debet
                        )
                      : null
                  }
                  onChange={({ value, label }) => {
                    setFieldValueRincian('nomor_akun_debet', value);
                    setFieldValueRincian('nama_akun_debet', label);
                  }}
                  error={
                    errorsRincian.nomor_akun_debet &&
                    touchedRincian.nomor_akun_debet
                  }
                  errorText={
                    touchedRincian.nomor_akun_debet &&
                    errorsRincian.nomor_akun_debet
                  }
                />
              </Col>

              <Col md="3">
                <Select
                  label="COA Kredit"
                  options={coa ?? []}
                  loading={isLoading}
                  defaultValue={
                    valuesRincian?.nomor_akun_kredit
                      ? coa?.find(
                          (val) =>
                            val.value === valuesRincian?.nomor_akun_kredit
                        )
                      : null
                  }
                  onChange={({ value, label }) => {
                    setFieldValueRincian('nomor_akun_kredit', value);
                    setFieldValueRincian('nama_akun_kredit', label);
                  }}
                  error={
                    errorsRincian.nomor_akun_kredit &&
                    touchedRincian.nomor_akun_kredit
                  }
                  errorText={
                    touchedRincian.nomor_akun_kredit &&
                    errorsRincian.nomor_akun_kredit
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <TextArea
                  label="Keterangan"
                  name="keterangan"
                  placeholder="Masukkan Keterangan"
                  value={valuesRincian?.keterangan}
                  onChange={handleChangeRincian}
                  error={errorsRincian.keterangan && touchedRincian.keterangan}
                  errorText={errorsRincian.keterangan}
                />
              </Col>
            </Row>
          </Fragment>
        )}

        <Card className="border mt-4">
          <Tab.Container defaultActiveKey="gambar">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="gambar">Gambar</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="documents">File</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="gambar">
                  <TabGambar
                    dataGambar={
                      valuesRincian?.files?.filter((e) =>
                        accImageTypes?.includes(e.nama.split('.').pop())
                      ) ?? []
                    }
                    type="DETAIL"
                  />
                </Tab.Pane>

                <Tab.Pane eventKey="documents">
                  <TabFile
                    dataFile={
                      valuesRincian?.files?.filter((e) =>
                        accDocTypes?.includes(e.nama.split('.').pop())
                      ) ?? []
                    }
                    type="DETAIL"
                  />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </Modal.Body>

      {!isDetail && (
        <Modal.Footer>
          <ButtonCreate
            size="md"
            text="Simpan Data"
            onClick={() => handleSubmitRincian()}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
