import { useEffect, useState } from 'react';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { IoAddOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  ReadButton,
  Td,
  Th,
  ThFixed,
  FilterButton,
  BackButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { HPPApi } from 'api';
import { ModalFilterJobOrderHPP } from './Components';

export const ListPreformHPP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_hpp_start: undefined,
    tgl_hpp_end: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    id_barang_jadi: undefined,
    id_customer: undefined,
    status_jurnal: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
    };

    HPPApi.preform_page(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert(locationState?.alert);
    }
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(() => {
    setNavbarTitle('Harga Pokok Produksi (HPP)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_hpp_start,
    pagination.tgl_hpp_end,
  ]);

  return (
    <>
      <Row className="mb-2 d-flex justify-content-around align-items-end">
        <Col
          md={5}
          className="d-flex flex-row  justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end">
          <BackButton className="m-1" onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      )}

      <ModalFilterJobOrderHPP
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

const TableContent = ({ data, pagination, setPagination, history }) => (
  <>
    <div className="mb-1 mt-2" style={{ fontSize: '.9rem' }}>
      List Data Job Order Yang Siap Dibuatkan Posting HPP
    </div>

    <div className="table-responsive">
      <table className="table table-bordered bg-white table-sm">
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th>Informasi SPK</Th>
            <Th>Informasi RAP</Th>
            <Th>Informasi Job Order</Th>
            <Th>Customer</Th>
            <Th>Item Job Order</Th>
            <Th>Qty. Job Order</Th>
            <Th>Total Qty. Yang Telah Diposting HPP</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data?.map((val, index) => {
              return (
                <tr key={index}>
                  <Td className="text-center">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_spk
                        ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_spk ?? '-'}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_rap
                        ? DateConvert(new Date(val.tgl_rap)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_rap ?? '-'}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_job_order
                        ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_job_order ?? '-'}</div>
                  </Td>

                  <Td style={{ minWidth: '200px' }}>
                    {val.nama_customer ?? '-'}
                  </Td>

                  <Td style={{ minWidth: '200px' }}>
                    {val.nama_barang_jadi ?? ''}
                  </Td>

                  <Td style={{ minWidth: '150px' }}>
                    {`${parseFloat(val.qty_rap ?? 0)} ${val.nama_satuan ?? ''}`}
                  </Td>

                  <Td style={{ minWidth: '150px' }}>
                    {`
                    ${parseFloat(val.total_hpp ?? 0)} 
                    ${val.nama_satuan ?? ''}
                    `}
                  </Td>

                  <Td>
                    <ButtonGroup>
                      <ReadButton
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/transaksi/hpp/list-job-order/detail/${val.id_job_order}`
                          )
                        }
                      />
                      <ActionButton
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/transaksi/hpp/tambah/${val.id_job_order}`
                          )
                        }
                      >
                        <IoAddOutline />
                      </ActionButton>
                    </ButtonGroup>
                  </Td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>

    {data?.length ? (
      <Pagination
        dataLength={pagination.per_page}
        dataNumber={
          pagination.page * pagination.per_page - pagination.per_page + 1
        }
        dataPage={
          pagination.data_count < pagination.per_page
            ? pagination.data_count
            : pagination.page * pagination.per_page
        }
        dataCount={pagination.data_count}
        currentPage={pagination.page}
        totalPage={pagination.total_page}
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({
            ...prev,
            page: selected + 1,
          }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    ) : null}
  </>
);
