import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { DateConvert, RupiahConvert } from 'utilities';
import propTypes from 'prop-types';

const ModalDetailJurnal = ({ modalConfig, setModalConfig, BDPType }) => {
  // BDPTYpe : Bahan|Alat Mesin|Overhead|Sisa Produksi
  const data = modalConfig?.data;

  const InfoItemHorizontal = ({ label, text, width }) => (
    <div className={`d-flex align-items-top`}>
      <div style={{ width: width ? width : 180, fontSize: 14 }}>{label}</div>
      <div className="pl-3 pr-2" style={{ fontSize: 14 }}>
        :
      </div>
      <div style={{ fontSize: 14, maxWidth: '70%' }}>{text}</div>
    </div>
  );

  const InfoItemVertical = ({ title, text }) => (
    <div className="mb-2">
      <small
        className="text-secondary"
        style={{ opacity: 0.8, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  return (
    <Modal
      size="lg"
      show={modalConfig?.show}
      onHide={() => setModalConfig({ show: false, data: {} })}
      title="Detail Data Jurnal BDP"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-primary h6">
          Detail Data Jurnal BDP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InfoItemHorizontal
          label="Tgl. Realisasi Produksi"
          text={DateConvert(new Date(data?.tgl_realisasi_produksi)).detail}
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi"
          text={data?.no_realisasi_produksi}
        />
        <InfoItemHorizontal label="No. SPK" text={data?.no_spk} />
        <InfoItemHorizontal label="No. RAP" text={data?.no_rap} />
        <InfoItemHorizontal label="No. Job Order" text={data?.no_job_order} />
        <InfoItemHorizontal
          label={`Item ${BDPType ?? ''}`}
          text={data?.nama_item}
        />
        <InfoItemHorizontal label="Satuan" text={data?.nama_satuan} />
        <InfoItemHorizontal label="Qty. Realisasi" text={data?.qty_realisasi} />
        <InfoItemHorizontal
          label="Harga Satuan"
          text={RupiahConvert(String(parseInt(data?.harga_satuan))).detail}
        />
        <hr />
        <Row>
          <Col>
            <InfoItemVertical
              title="Tgl. BDP"
              text={DateConvert(new Date(data?.tgl_bdp)).detail}
            />
          </Col>
          <Col>
            <InfoItemVertical title="No. BDP" text={data?.no_bdp} />
          </Col>
        </Row>
        <InfoItemVertical
          title="Jumlah"
          text={RupiahConvert(String(parseInt(data?.jumlah))).detail}
        />
        <InfoItemVertical
          title="COA Debet"
          text={`${data?.nomor_akun_debet} - ${data?.nama_akun_debet}`}
        />
        <InfoItemVertical
          title="COA Kredit"
          text={`${data?.nomor_akun_kredit} - ${data?.nama_akun_kredit}`}
        />
        <InfoItemVertical
          title="Keterangan Jurnal*"
          text={data?.keterangan_jurnal}
        />
      </Modal.Body>
    </Modal>
  );
};

ModalDetailJurnal.propTypes = {
  BDPType: propTypes.oneOf(['Bahan', 'Alat Mesin, Overhead, Sisa Produksi'])
    .isRequired,
};

export default ModalDetailJurnal;
