import { DataStatus, Table, Th, ThFixed, Td, Pagination } from 'components';
import { TableNumber } from 'utilities';
import { rupiahConvert } from 'utilities2';

export const TableContent = ({
  data,
  type = 'page',
  paginationConfig,
  setPaginationConfig,
}) => {
  const errorMessage =
    type === 'page'
      ? 'Tidak ada data'
      : 'Hitung harga satuan rata-rata terlebih dulu';

  return (
    <>
      <Table>
        <thead>
          <tr>
            <ThFixed className="text-nowrap">No.</ThFixed>
            <ThFixed>Kode Overhead</ThFixed>
            <Th>Nama Item Overhead</Th>
            <Th>Kelompok Overhead</Th>
            <Th>Total Biaya Berdasarkan COA</Th>
            <Th>Persentase Pembebanan</Th>
            <Th>Qty. Produksi Bulan Ini</Th>
            <ThFixed className="text-nowrap">
              Harga Satuan <br /> Rata-rata Overhead
            </ThFixed>
          </tr>
        </thead>
        <tbody>
          {!data || data?.length < 1 ? (
            <tr>
              <td colSpan={8}>
                <DataStatus text={errorMessage} />
              </td>
            </tr>
          ) : (
            data.map((val, index) => (
              <tr key={index}>
                <Td>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td>{val.kode_item ?? '-'}</Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td>{val.nama_kelompok ?? '-'}</Td>
                <Td className="text-right">
                  {val.total_biaya
                    ? rupiahConvert().getWithComa(parseFloat(val.total_biaya))
                    : '-'}
                </Td>
                <Td className="text-right">{parseFloat(val.persentase)}%</Td>
                <Td className="text-right">
                  {val.qty_realisasi_overhead ?? '-'} {val.nama_satuan ?? ''}
                </Td>
                <Td className="text-right">
                  {val.harga_satuan
                    ? rupiahConvert().getWithComa(parseFloat(val.harga_satuan))
                    : '-'}
                </Td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {data?.length > 1 && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      )}
    </>
  );
};
