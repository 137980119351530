import { Fragment } from 'react';
import { ThFixed, Th, Td } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

export const TableBDP = ({ data = [], title = '', tipe = 'bahan' }) => {
  const isUpahSubcont = tipe === 'upah' || tipe === 'subcont';
  const isSisaProduksi = tipe === 'sisa';

  const columnTitle = {
    bahan: {
      nama: 'Item Bahan',
      harga: 'Harga Satuan Rata-rata Bahan',
    },
    upah: {
      nama: 'Uraian Pekerjaan',
      harga: 'Harga Satuan Kontrak',
    },
    alat: {
      nama: 'Item Alat & Mesin',
      harga: 'Harga Satuan Rata-rate Penyusutan',
    },
    subcont: {
      nama: 'Uraian Pekerjaan',
      harga: 'Harga Satuan Kontrak',
    },
    overhead: {
      nama: 'Item Overhead',
      harga: 'Harga Satuan Rata-rata Overhead',
    },
    sisa: {
      nama: 'Item Sisa Produksi',
      harga: 'Harga Satuan Rata-rata Bahan',
    },
  };

  return (
    <Fragment>
      <div style={{ fontSize: '.9rem' }}>List Data BDP {title}</div>
      <div className="table-responsive">
        <table className="table table-bordered bg-white table-sm">
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <Th>Informasi BDP</Th>
              {!isSisaProduksi && <ThFixed>Nama Grup</ThFixed>}
              {isUpahSubcont && <Th>Vendor</Th>}
              <Th className="text-nowrap">{columnTitle[tipe]['nama']}</Th>
              <Th>Qty. BDP</Th>
              <Th>{columnTitle[tipe]['harga']}</Th>
              <Th>Total</Th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <div>
                      {val.tgl_bdp
                        ? DateConvert(new Date(val.tgl_bdp)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_bdp ?? '-'}</div>
                  </Td>

                  {!isSisaProduksi && (
                    <Td className="text-nowrap">{val.nama_grup ?? '-'}</Td>
                  )}

                  {isUpahSubcont && <Td>{val.nama_vendor}</Td>}

                  <Td>{val.nama_item}</Td>

                  <Td style={{ minWidth: '200px' }}>
                    {`${parseFloat(val.qty_bdp ?? 0)} ${val.nama_satuan ?? ''}`}
                  </Td>

                  <Td className="text-right" style={{ minWidth: '100px' }}>
                    {val.harga_satuan
                      ? RupiahConvert(String(Math.round(val.harga_satuan)))
                          .detail
                      : '-'}
                  </Td>

                  <Td className="text-right" style={{ minWidth: '100px' }}>
                    {val.harga_satuan
                      ? RupiahConvert(
                          String(
                            Math.round(
                              val.harga_satuan * parseFloat(val.qty_bdp ?? 0)
                            )
                          )
                        ).detail
                      : '-'}
                  </Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  Tidak ada data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};
