import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Nav, Tab, Row, Col } from 'react-bootstrap';
import { Alert, BackButton, InfoItemHorizontal, DataStatus } from 'components';
import { DateConvert } from 'utilities';
import {
  TabBahan,
  TabOverhead,
  TabUpah,
  TabAlatMesin,
  TabSisaProduksi,
  TabSubkon,
} from './Tab';
import { BDPProduksiApi } from 'api';
import { BadgeNotif } from '../Components';
import { NotifBDPContext, withNotifBDPProvider } from '../Context/NotifContext';

const DataBDPProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_job_order } = useParams();
  const { notif, setNotif } = useContext(NotifBDPContext);

  const [dataInfo, setDataInfo] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'danger',
    text: '',
  });

  // Tab Bahan
  const [hardwood, setHardwood] = useState([]);
  const [plywood, setPlywood] = useState([]);
  const [factorySupply, setFactorySupply] = useState([]);
  const [bahanPenunjang, setBahanPenunjang] = useState([]);

  // Tab Upah
  const [labourCost, setLabourCost] = useState([]);
  const [labourCostFin, setLabourCostFin] = useState([]);

  // Tab Alat Mesin
  const [machineProcess, setMachineProcess] = useState([]);

  // Tab Subkon
  const [subkonFin, setSubkonFin] = useState([]);

  // Tab Overhead
  const [overheadPabrik, setOverheadPabrik] = useState([]);

  // Tab Sisa Produksi
  const [sisaProduksi, setSisaProduksi] = useState([]);

  const getDataInfo = () => {
    // Get Informasi Job Order
    setLoading(true);
    BDPProduksiApi.single({ id_job_order })
      .then((res) => setDataInfo(res?.data?.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('BDP Produksi');
    getDataInfo();
  }, []);

  return (
    <React.Fragment>
      {/* Informasi Job Order Section */}
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Informasi Job Order</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <DataStatus loading={isLoading} text="Memuat Data . . ." />
          ) : (
            <Row>
              <Col>
                <InfoItemHorizontal
                  label="Tgl. Job Order"
                  text={DateConvert(new Date(dataInfo?.tgl_job_order)).detail}
                />
                <InfoItemHorizontal
                  label="No. Job Order"
                  text={dataInfo?.no_job_order ?? '-'}
                />
                <InfoItemHorizontal
                  label="Proyek"
                  text={dataInfo?.nama_proyek ?? '-'}
                />
                <InfoItemHorizontal
                  label="Customer"
                  text={dataInfo?.nama_customer ?? '-'}
                />
              </Col>
              <Col>
                <InfoItemHorizontal
                  label="Kode Barang Jadi"
                  text={dataInfo?.kode_barang_jadi ?? '-'}
                />
                <InfoItemHorizontal
                  label="Item Barang Jadi"
                  text={dataInfo?.nama_barang_jadi ?? '-'}
                />
                <InfoItemHorizontal
                  label="Tgl. Mulai"
                  text={DateConvert(new Date(dataInfo?.tgl_mulai_jo)).detail}
                />
                <InfoItemHorizontal
                  label="Tgl. Selesai"
                  text={DateConvert(new Date(dataInfo?.tgl_selesai_jo)).detail}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {/* Data Realisasi Produksi Section */}
      <span className="font-weight-bold mb-1">Data Realisasi Produksi</span>
      <Card className="border">
        <Tab.Container defaultActiveKey="bdp_bahan">
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bdp_bahan">
                  BDP Bahan
                  <BadgeNotif show={notif.bdp_bahan.status} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_upah">
                  BDP Upah
                  <BadgeNotif show={notif.bdp_upah.status} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_alat_mesin">
                  BDP Alat Mesin
                  <BadgeNotif show={notif.bdp_alat_mesin.status} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_subkon">
                  BDP Subkon
                  <BadgeNotif show={notif.bdp_subkon.status} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_overhead">
                  BDP Overhead
                  <BadgeNotif show={notif.bdp_overhead.status} />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_sisa_produksi">
                  BDP Sisa Produksi
                  <BadgeNotif show={notif.bdp_sisa_produksi.status} />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              {isLoading ? (
                <DataStatus loading={isLoading} text="Memuat Data . . ." />
              ) : (
                <>
                  <Tab.Pane eventKey="bdp_bahan">
                    <TabBahan
                      hardwood={hardwood}
                      setHardwood={setHardwood}
                      plywood={plywood}
                      setPlywood={setPlywood}
                      factorySupply={factorySupply}
                      setFactorySupply={setFactorySupply}
                      bahanPenunjang={bahanPenunjang}
                      setBahanPenunjang={setBahanPenunjang}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bdp_upah">
                    <TabUpah
                      labourCost={labourCost}
                      setLabourCost={setLabourCost}
                      labourCostFin={labourCostFin}
                      setLabourCostFin={setLabourCostFin}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bdp_alat_mesin">
                    <TabAlatMesin
                      machineProcess={machineProcess}
                      setMachineProcess={setMachineProcess}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bdp_subkon">
                    <TabSubkon
                      subkonFin={subkonFin}
                      setSubkonFin={setSubkonFin}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bdp_overhead">
                    <TabOverhead
                      overheadPabrik={overheadPabrik}
                      setOverheadPabrik={setOverheadPabrik}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bdp_sisa_produksi">
                    <TabSisaProduksi
                      sisaProduksi={sisaProduksi}
                      setSisaProduksi={setSisaProduksi}
                    />
                  </Tab.Pane>
                </>
              )}
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </React.Fragment>
  );
};

export default withNotifBDPProvider(DataBDPProduksi);
