import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoTrashBinOutline } from 'react-icons/io5';
import { useIsGuest } from 'hooks';

const DeleteButton = ({
  text,
  type,
  size,
  variant,
  disable,
  className,
  style,
  children,
  onClick,
  tooltip,
  tooltipText,
  tooltipPlacement,
  ...rest
}) => {
  const isGuest = useIsGuest();

  if (isGuest) {
    return null;
  }

  return (
    <OverlayTrigger
      placement={tooltipPlacement}
      overlay={tooltip ? <Tooltip>{tooltipText}</Tooltip> : <div></div>}
    >
      <Button
        {...rest}
        type={type}
        variant="danger"
        onClick={onClick}
        disabled={disable}
        className={className}
        size={size}
        style={style}
      >
        <IoTrashBinOutline />
        {text && <span className="ml-2 text-uppercase">{text}</span>}
      </Button>
    </OverlayTrigger>
  );
};

export default DeleteButton;
