import { useState, useCallback, useEffect } from 'react';
import { Nav, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { BackButton, DataStatus } from 'components';
import { PostingBiayaPrelimApi } from 'api';
import {
  InfoSection,
  TablePosting,
  TableHistory,
  TableItemSPK,
} from '../Section';
import { getGrandTotalPrelim } from '../Helper';

const DetailPostingBiayaPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: ID } = useParams();
  const [dataInfo, setDataInfo] = useState([]);
  const [dataSPK, setDataSPK] = useState([]);
  const [dataPrelim, setDataPrelim] = useState([]);
  const [tabs, setTabs] = useState(''); //State untuk menampung tabs yang aktif
  const [submitCount, setSubmitCount] = useState(1); //State untuk merender ulang tabel realisasi & history
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'honor',
      label: 'Honor Staff',
    },
    {
      tab: 'atk',
      label: 'ATK',
    },
    {
      tab: 'safety',
      label: 'Safety',
    },
    {
      tab: 'handTools',
      label: 'Hand Tools',
    },
    {
      tab: 'proteksiOnSite',
      label: 'Proteksi',
    },
    {
      tab: 'lainLain',
      label: 'Lain-Lain',
    },
  ];

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  // Menangani fetching data
  const fetchingDataHandler = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    return await PostingBiayaPrelimApi.singleSPK({ id_spk: ID })
      .then((res) => {
        setDataInfo(res?.data?.data ?? {});
        setDataSPK(res?.data?.data?.detail ?? []);
        setDataPrelim(res?.data?.data?.prelim ?? []);
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  // Menambahkan nilai dari submitCount agar dapat merender ulang tabel realisasi & history
  const addSubmitCount = useCallback(() => {
    setSubmitCount(submitCount + 1);
  }, [submitCount]);

  useEffect(() => {
    setNavbarTitle('Posting Biaya Prelim');
    setTabs(tabsConfig[0].tab);
    fetchingDataHandler();
  }, []);

  const grandTotalPrelim = getGrandTotalPrelim(dataPrelim ?? []);

  // Tampilan title pada card
  const CardTitle = () => (
    <Card.Header className="d-flex justify-content-between align=-items-center">
      <b>Detail Posting Biaya Prelim</b>
      <BackButton size="sm" onClick={() => history.goBack()} />
    </Card.Header>
  );

  // Tampilan saat loading dan data gagal dimuat
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Card>
        <CardTitle />
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat!" />
          )}
        </Card.Body>
      </Card>
    );
  }

  // Tampilan pada saat data berhasil dimuat
  return (
    <>
      <Card>
        <CardTitle />
        <Card.Body>
          {/* Info Section */}
          <InfoSection dataInfo={dataInfo} />

          <hr className="my-4" />

          <TableItemSPK
            data={dataSPK}
            totalPrelim={grandTotalPrelim}
            diskon={dataInfo?.diskon ?? 0}
            ppn={dataInfo?.ppn ?? 0}
          />

          {/* Tab Section */}
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Header>
          <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
            {tabsConfig.map((val, index) => (
              <Nav.Item key={index}>
                <Nav.Link
                  href={`#${val.tab}`}
                  onClick={(e) => onTabsChangeHandler(e, val.tab)}
                >
                  {val.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {tabsConfig.map(
            (val, index) =>
              val.tab === tabs && (
                <div key={index}>
                  <TablePosting
                    isReadOnly={true}
                    type={val.tab.toUpperCase()}
                    submitCount={submitCount}
                    addSubmitCount={addSubmitCount}
                  />
                  <hr />
                  <TableHistory
                    id={ID}
                    type={val.tab.toUpperCase()}
                    submitCount={submitCount}
                  />
                </div>
              )
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailPostingBiayaPrelim;
