import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  CreateButton,
  ThFixed,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PostingBiayaPrelimApi } from 'api';

const ListPostingBiayaPrelim = ({ setNavbarTitle }) => {
  const title = 'Posting Biaya Prelim';
  const history = useHistory();
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PostingBiayaPrelimApi.page({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        console.log(data);
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const Select = (props) => (
    <select
      {...props}
      className={`custom-select custom-select ${props.className}`}
    >
      {props.children}
    </select>
  );

  const PageContent = () => {
    const DataTable = () => (
      <Table className="mt-1">
        <thead>
          <tr>
            <Th width={20}>No</Th>
            <ThFixed>Informasi Posting Biaya Prelim</ThFixed>
            <ThFixed>Informasi Realisasi Prelim</ThFixed>
            <Th>Proyek</Th>
            <Th>Item Prelim</Th>
            <ThFixed>Qty. Realisasi Prelim</ThFixed>
            <Th noPadding width={80}>
              Unit Price
            </Th>
            <Th noPadding width={80}>
              Durasi Pemakaian
            </Th>
            <Th>COA</Th>
            <Th noPadding width={80}>
              Jumlah
            </Th>
            <Th>Keterangan Posting</Th>
            <ThFixed>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((val, index) => {
              const jumlah =
                parseFloat(val?.qty_realisasi ?? 0) *
                parseInt(val?.harga_satuan ?? 0);
              return (
                <tr key={index}>
                  <Td className="text-center">
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_posting_biaya_prelim
                        ? DateConvert(new Date(val.tgl_posting_biaya_prelim))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_posting_biaya_prelim ?? '-'}</div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_realisasi_prelim
                        ? DateConvert(new Date(val.tgl_realisasi_prelim))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_realisasi_prelim ?? '-'}</div>
                  </Td>
                  <Td>{val.nama_proyek ?? '-'}</Td>
                  <Td>{val?.nama_item_prelim ?? '-'}</Td>
                  <Td>
                    {val?.qty_realisasi
                      ? `${parseFloat(val?.qty_realisasi ?? 0)}  ${
                          val?.nama_satuan_realisasi
                        }`
                      : '-'}
                  </Td>
                  <Td className="text-right">
                    {
                      RupiahConvert(String(parseInt(val?.harga_satuan ?? 0)))
                        .detail
                    }
                  </Td>
                  <Td>
                    {val?.durasi_realisasi
                      ? `${parseFloat(val?.durasi_realisasi ?? 0)}  ${
                          val?.nama_satuan_waktu ?? 'Hari'
                        }`
                      : '-'}
                  </Td>
                  <Td>
                    <div>
                      Debet :{' '}
                      {`${val?.nomor_akun_debet} - ${val?.nama_akun_debit}`}
                    </div>
                    <div>
                      Kredit:{' '}
                      {`${val?.nomor_akun_kredit} - ${val?.nama_akun_kredit}`}
                    </div>
                  </Td>
                  <Td className="text-right">
                    {RupiahConvert(String(parseInt(jumlah ?? 0))).detail}
                  </Td>
                  {/*Keterangan Posting*/}
                  <Td>{val?.keterangan_posting ?? '-'}</Td>
                  <Td>
                    <ActionButton
                      size="sm"
                      className="col"
                      tooltipText="Detail"
                      onClick={() =>
                        history.push(
                          '/transaksi/posting-biaya-prelim/detail/' + val.id_spk
                        )
                      }
                    >
                      <IoEyeOutline />
                    </ActionButton>
                  </Td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mt-2">
          {' '}
          <b>List Data Posting Biaya Prelim</b>{' '}
        </div>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push(
                '/transaksi/posting-biaya-prelim/list-realisasi-prelim'
              )
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
    </CRUDLayout>
  );
};

export default ListPostingBiayaPrelim;
