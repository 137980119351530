import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';

const InputSearch = ({
  name,
  value,
  placeholder,
  onChange,
  onSubmit,
  className,
  wrapperClassName,
  ...rest
}) => {
  return (
    <form onSubmit={onSubmit} className={wrapperClassName}>
      <div className="input-group input-group-merge mb-2">
        <div className="input-group-prepend">
          <span className="input-group-text bg-white" id="basic-addon-search2">
            <IoSearchOutline />
          </span>
        </div>
        <input
          {...rest}
          type="text"
          className={`form-control ${className}`}
          name={name}
          placeholder={placeholder ? placeholder : 'Cari . . .'}
          onChange={onChange}
          value={value}
        />
      </div>
    </form>
  );
};

export default InputSearch;
