import { useState, useEffect, useContext } from 'react';
import { HPPApi } from 'api';
import { mapBDPList } from '../Helpers';
import { HPPContext } from '../Context';

export const useFetchSingleJO = (id_job_order) => {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { summary, totalSummary, dataBDP, setDataBDP, dataInfo, setDataInfo } =
    useContext(HPPContext);

  useEffect(() => {
    if (id_job_order) {
      setIsPageLoading(true);

      HPPApi.single_jo_bdp({ id_job_order })
        .then(({ data }) => {
          const {
            bahan,
            upah,
            alat_mesin,
            subkon,
            overhead,
            sisa_produksi,
            ...info
          } = data?.data ?? {};

          setDataInfo(info);
          setDataBDP({
            bahan: mapBDPList(bahan),
            upah: mapBDPList(upah),
            alat_mesin: mapBDPList(alat_mesin),
            subkon: mapBDPList(subkon),
            overhead: mapBDPList(overhead),
            sisa_produksi: mapBDPList(sisa_produksi),
          });
        })
        .finally(() => setIsPageLoading(false));
    }
  }, [id_job_order, setDataBDP]);

  return { isPageLoading, dataInfo, dataBDP, summary, totalSummary };
};
