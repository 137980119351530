import Services from 'services';

class HPPApi {
  page(params) {
    return Services.get('/hpp/page', { params });
  }

  preform_page(params) {
    return Services.get('/hpp/list_jo_bdp', { params });
  }

  single(params) {
    return Services.get('/hpp/single', { params });
  }

  single_jo_bdp(params) {
    return Services.get('/hpp/single_jo_bdp', { params });
  }

  no_baru(params) {
    return Services.get('/hpp/no_baru', { params });
  }

  dropdown(params) {
    return Services.get('/hpp/dropdown', { params });
  }

  create(value) {
    return Services.post('/hpp', value);
  }

  update(value) {
    return Services.put('/hpp', value);
  }

  delete(value) {
    return Services.post('/hpp/delete', value);
  }
}

export default new HPPApi();
