/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { CRUDLayout, ActionButton, BackButton } from 'components';
import { HargaSatuanRataRataOverheadApi } from 'api';
import { Card } from 'react-bootstrap';
import {
  ModalKonfirmasiPosting,
  TableContent,
  FormPosting,
} from './components';
import { DateConvert } from 'utilities';
import { fetchIsPosting, getHitungHSR } from './helpers';

const TambahHargaSatuanRataOverhead = ({ setNavbarTitle }) => {
  const history = useHistory();
  const TODAY = new Date();
  const [data, setData] = useState([]);
  const [modalConfirm, setModalConfirm] = useState({
    show: false,
    type: 'warning',
    message: '',
  });
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });

  const initialValues = {
    month: '',
    year: '',
    status_posting: null, // Sudah Di Posting | Belum Di Posting
    is_status_posting: null, // true | false
  };

  const validationSchema = Yup.object({
    month: Yup.string().required(),
    year: Yup.string().required(),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const lastDateOfMonth = DateConvert(
      new Date(values.year, values.month, 0)
    ).default;

    const finalValue = {
      tgl_posting: lastDateOfMonth,
    };

    HargaSatuanRataRataOverheadApi.create(finalValue)
      .then(() => {
        setModalConfirm({
          show: true,
          type: 'success',
          message: 'Posting Data Harga Satuan Overhead Berhasil!',
        });
        setSubmitting(false);
        resetForm();
      })
      .catch(() =>
        setModalConfirm({
          show: true,
          type: 'error',
          message: 'Gagal Posting Data Harga Satuan Overhead!',
        })
      );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit,
  });

  const closeModalKonfirmasi = () =>
    setModalConfirm((prev) => ({ ...prev, show: false }));

  const { values, handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    setNavbarTitle('Harga Satuan Rata-rata Overhead');
    getHitungHSR({ formik, paginationConfig, setPaginationConfig, setData });
  }, [setNavbarTitle, paginationConfig.page, paginationConfig.dataLength]);

  useEffect(
    () =>
      values.month &&
      values.year &&
      fetchIsPosting({ formik, setData, setPaginationConfig }),

    [values.month, values.year]
  );

  return (
    <CRUDLayout>
      <Row className="flex justify-content-end align-items-center mx-1">
        <BackButton onClick={() => history.goBack()} />
      </Row>

      <div className="font-weight-bold" style={{ fontSize: '.9rem' }}>
        Tambah Data Harga Satuan Rata-Rata Overhead
      </div>

      <Card>
        <Card.Body>
          <FormPosting
            today={TODAY}
            formik={formik}
            paginationConfig={paginationConfig}
            setPaginationConfig={setPaginationConfig}
            setData={setData}
          />
        </Card.Body>
      </Card>

      <div className="font-weight-bold mt-4" style={{ fontSize: '.9rem' }}>
        List Data Harga Satuan Rata-Rata Overhead
      </div>

      <Card>
        <Card.Body>
          <TableContent
            key={JSON.stringify(paginationConfig)}
            type="tambah"
            data={data}
            paginationConfig={paginationConfig}
            setPaginationConfig={setPaginationConfig}
          />
        </Card.Body>
      </Card>

      <Row className="justify-content-end align-items-end mt-5 mx-2">
        <ActionButton
          disable={values.is_status_posting === false ? false : true}
          text="Posting"
          onClick={() =>
            setModalConfirm({
              show: true,
              type: 'warning',
              title: 'Konfirmasi Posting',
              message:
                'Apakah Anda yakin ingin memposting data ini? Data yang telah diposting tidak bisa di edit!',
            })
          }
        />
      </Row>

      <ModalKonfirmasiPosting
        modalConfirm={modalConfirm}
        onSubmit={handleSubmit}
        onWarningClose={closeModalKonfirmasi}
        onModalClose={closeModalKonfirmasi}
        onErrorClose={closeModalKonfirmasi}
        onSuccessClose={() =>
          history.push('/transaksi/harga-satuan-rata-rata-overhead')
        }
        isSubmitting={isSubmitting}
      />
    </CRUDLayout>
  );
};

export default TambahHargaSatuanRataOverhead;
