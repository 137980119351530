import { createContext } from 'react';
import { useQuery } from 'react-query';
import { TransferProduksiOverheadApi } from 'api';

export const DropdownTransferOverheadContext = createContext();

export const DropdownTransferOverheadProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES
  const dropdownMenuKey = 'transfer_overhead_produksi';

  const dropdownStatusPermintaan = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'on_progress', label: 'On Progress' },
    { value: 'done', label: 'Done' },
  ];

  const dropdownItem = useQuery(
    ['dropdown', 'item', 'job_order', dropdownMenuKey],
    () =>
      TransferProduksiOverheadApi.dropdown({ tipe: 'item' }).then((res) => {
        return (
          res.data.data.map((v) => ({
            value: v.id_item_buaso,
            label: `${v.kode_item} - ${v.nama_item}`,
          })) ?? []
        );
      }),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownItemOverhead = useQuery(
    ['dropdown', 'item', 'overhead', dropdownMenuKey],
    () =>
      TransferProduksiOverheadApi.dropdownOverhead({ tipe: 'overhead' }).then(
        (res) => {
          return (
            res.data.data.map((v) => ({
              ...v,
              value: v.id_item_buaso,
              label: `${v.kode_item} - ${v.nama_item}`,
            })) ?? []
          );
        }
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      TransferProduksiOverheadApi.dropdown({ tipe: 'customer' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_customer,
          label: k.nama_customer,
        }))
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );

  const dropdownProyek = useQuery(
    ['dropdown', 'proyek', dropdownMenuKey],
    () =>
      TransferProduksiOverheadApi.dropdown({ tipe: 'proyek' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_proyek,
          label: k.nama_proyek,
        }))
      ),
    { staleTime: STALE_DURATION_MINUTES }
  );
  return (
    <DropdownTransferOverheadContext.Provider
      value={{
        dropdownStatusPermintaan,
        dropdownCustomer,
        dropdownProyek,
        dropdownItem,
        dropdownItemOverhead,
      }}
    >
      {children}
    </DropdownTransferOverheadContext.Provider>
  );
};

export const withDropdownTransferOverheadProvider = (Component) => (props) => {
  return (
    <DropdownTransferOverheadProvider>
      <Component {...props} />
    </DropdownTransferOverheadProvider>
  );
};
