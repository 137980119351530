import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import _ from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  CreateButton,
  ReadButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { OverheadPrelimApi } from 'api';

const ListOverheadPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataOverheadPrelim, setDataOverheadPrelim] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [isModalShow, setIsModalShow] = useState(false);
  const [readModalData, setReadModalData] = useState({});

  const getInitialData = () => {
    setIsPageLoading(true);

    OverheadPrelimApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataOverheadPrelim(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.registerAlertConfig) {
        setAlertConfig({
          show: locationState.registerAlertConfig.show,
          text: locationState.registerAlertConfig.text,
          variant: locationState.registerAlertConfig.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  useEffect(() => {
    setNavbarTitle('OVERHEAD PRELIM');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    paginationConfig.page,
    paginationConfig.dataLength,
  ]);

  const ReadModal = () => {
    const jumlah =
      parseFloat(readModalData.qty_overhead) * readModalData.harga_satuan;
    const InfoSection = () => {
      const InfoItems = ({ label, value }) => (
        <tr style={{ fontSize: 14 }}>
          <td>{label}</td>
          <td className="pl-3 pr-2">:</td>
          <td>{value}</td>
        </tr>
      );
      return (
        <div>
          <InfoItems
            label="Tgl. Overhead Prelim"
            value={
              DateConvert(new Date(readModalData.tgl_overhead_prelim)).detail ??
              '-'
            }
          />
          <InfoItems
            label="No. Overhead Prelim"
            value={readModalData.no_overhead_prelim ?? '-'}
          />
          <InfoItems
            label="No. Permintaan Prelim"
            value={readModalData.no_permintaan_prelim ?? '-'}
          />
          <InfoItems label="No. SPK" value={readModalData.no_spk ?? '-'} />

          <InfoItems
            label="Item Prelim"
            value={readModalData.nama_item ?? '-'}
          />
          <InfoItems
            label="Qty. Overhead Prelim"
            value={readModalData.qty_overhead ?? '-'}
          />
          <InfoItems
            label="Harga Satuan Prelim"
            value={
              RupiahConvert(parseInt(readModalData.harga_satuan).toString())
                .detail ?? '-'
            }
          />
          <InfoItems
            label="Durasi Prelim"
            value={readModalData.qty_durasi_prelim ?? '-'}
          />
          <InfoItems
            label="Jumlah"
            value={RupiahConvert(parseInt(jumlah).toString()).detail ?? '-'}
          />
          <InfoItems label="Catatan" value={readModalData.catatan ?? '-'} />
        </div>
      );
    };
    return (
      <Modal
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
        title="Detail Overhead Prelim"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <h6 className="mb-0">
              {/* <IoPencilOutline className="mb-1 mr-2" size={20} /> */}
              Detail Overhead Prelim
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfoSection />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr className="text-center">
            <Th>No.</Th>
            <Th className="text-center">Aksi</Th>
            <Th>Tgl. Overhead Prelim</Th>
            <Th>No. Overhead Prelim</Th>
            <Th>No. Permintaan Prelim</Th>
            <Th>Proyek</Th>
            <Th>Item Prelim</Th>
            <Th>Qty. Overhead Prelim</Th>
            <Th>Harga Satuan</Th>
            <Th>Durasi Prelim</Th>
            <Th>Jumlah</Th>
          </tr>
        </thead>
        <tbody>
          {dataOverheadPrelim.map((val, index) => {
            const jumlah = parseFloat(val.qty_overhead) * val.harga_satuan;
            return (
              <tr key={val.id_item_buaso}>
                <Td className="text-center" width="20px">
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <Td width="50">
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      size="sm"
                      onClick={() => {
                        setIsModalShow(true);
                        setReadModalData(val);
                      }}
                    />
                  </div>
                </Td>
                <Td>
                  {DateConvert(new Date(val.tgl_overhead_prelim)).defaultDMY}
                </Td>
                <Td>{val.no_overhead_prelim ?? '-'}</Td>
                <Td>{val.no_permintaan_prelim ?? '-'}</Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td className="text-right">{val.qty_overhead ?? '-'}</Td>
                <Td className="text-right">
                  {RupiahConvert(parseInt(val.harga_satuan).toString())
                    .detail ?? '-'}
                </Td>
                <Td className="text-right">{val.qty_durasi_prelim}</Td>
                <Td className="text-right">
                  {RupiahConvert(String(jumlah)).detail}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );

    if (!dataOverheadPrelim || dataOverheadPrelim.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch
                value={searchConfig.key}
                onChange={onInputSearchChange}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center">
            <CreateButton
              onClick={() =>
                history.push({
                  pathname: '/transaksi/overhead-prelim/list-permintaan-prelim',
                })
              }
            />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      <ReadModal />
    </CRUDLayout>
  );
};

export default ListOverheadPrelim;
