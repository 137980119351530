import { Fragment, useEffect, useState } from 'react';
import { ButtonGroup, Col, Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  DataStatus,
  InputSearch,
  Pagination,
  ReadButton,
  Td,
  Th,
  ThFixed,
  UpdateButton,
  FilterButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { HPPApi } from 'api';
import { ModalFilterHPP } from './Components';
import { withDropdownHPPProvider } from './Context';

export const ListHPP = withDropdownHPPProvider(({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: 1,
    per_page: 10,
    total_page: 1,
    data_count: 0,

    active: false,
    tgl_hpp_start: undefined,
    tgl_hpp_end: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    id_barang_jadi: undefined,
    id_customer: undefined,
    status_jurnal: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_hpp_start: pagination.tgl_hpp_start,
      tgl_hpp_end: pagination.tgl_hpp_end,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      id_barang_jadi: pagination.id_barang_jadi,
      id_customer: pagination.id_customer,
      status_jurnal: pagination.status_jurnal,
    };

    HPPApi.page(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination({
          ...pagination,
          data_count: data.data_count,
          total_page: data.total_page,
        });
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert(locationState.alert);
    }
  };

  const dismissAlert = () => {
    const currentState = history.location.state;

    if (currentState?.alert) {
      let state = { ...currentState };
      delete state.alert;

      history.replace(history.location.pathname, state);
      setAlert({
        text: '',
        variant: 'primary',
      });
    }
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(() => {
    setNavbarTitle('Harga Pokok Produksi (HPP)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
      dismissAlert();
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.key,
    pagination.page,
    pagination.per_page,
    pagination.tgl_hpp_start,
    pagination.tgl_hpp_end,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.id_barang_jadi,
    pagination.id_customer,
    pagination.status_jurnal,
  ]);

  return (
    <>
      <Row className="mb-2 d-flex justify-content-around align-items-end">
        <Col
          md={5}
          className="d-flex flex-row  justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end">
          <ActionButton
            className="m-1"
            text="Tambah"
            onClick={() => history.push('/transaksi/hpp/list-job-order')}
          />
        </Col>
      </Row>

      <Alert
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={dismissAlert}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent
          data={data}
          pagination={pagination}
          setPagination={setPagination}
          history={history}
        />
      )}

      {showFilter && (
        <ModalFilterHPP
          show={showFilter}
          setShow={setShowFilter}
          filter={pagination}
          setFilter={setPagination}
        />
      )}
    </>
  );
});

const TableContent = ({ data, pagination, setPagination, history }) => {
  return (
    <>
      <div className="mb-1 mt-2" style={{ fontSize: '.9rem' }}>
        List Data Harga Pokok Produksi (HPP)
      </div>

      <div className="table-responsive">
        <Table className="table table-bordered table-hover bg-white table-sm">
          <thead>
            <tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Informasi HPP</Th>
              <Th>Informasi Job Order</Th>
              <Th>Item Job Order</Th>
              <Th className="text-nowrap">Qty. Posting HPP</Th>
              <Th>Customer</Th>
              <Th>Jumlah HPP</Th>
              <Th>COA</Th>
              <Th>Keterangan</Th>
              <Th>Status Jurnal</Th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data?.map((val, index) => {
                const isJournal = Boolean(val.no_jurnal_hpp);

                return (
                  <tr key={index}>
                    <Td className="text-center">
                      {TableNumber(pagination.page, pagination.per_page, index)}
                    </Td>
                    <Td>
                      <ButtonGroup>
                        <ReadButton
                          size="sm"
                          onClick={() =>
                            history.push(`/transaksi/hpp/detail/${val.id_hpp}`)
                          }
                        />
                        {isJournal ? null : (
                          <UpdateButton
                            size="sm"
                            onClick={() =>
                              history.push(`/transaksi/hpp/ubah/${val.id_hpp}`)
                            }
                          />
                        )}
                      </ButtonGroup>
                    </Td>
                    <Td>
                      <div>
                        {val.tgl_hpp
                          ? DateConvert(new Date(val.tgl_hpp)).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_hpp ?? '-'}</div>
                    </Td>
                    <Td>
                      <div>
                        {val.tgl_job_order
                          ? DateConvert(new Date(val.tgl_job_order)).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_job_order ?? '-'}</div>
                    </Td>
                    <Td style={{ minWidth: '200px' }}>
                      {val.nama_barang_jadi ?? ''}
                    </Td>

                    <Td className="text-right">
                      {` ${parseFloat(val.qty_hpp ?? 0)} ${
                        val.nama_satuan ?? ''
                      }`}
                    </Td>

                    <Td style={{ minWidth: '200px' }}>
                      {val.nama_customer ?? '-'}
                    </Td>

                    <Td className="text-right" style={{ minWidth: '100px' }}>
                      {val.jumlah_hpp
                        ? RupiahConvert(String(Math.round(val.jumlah_hpp)))
                            .detail
                        : '-'}
                    </Td>

                    <Td>
                      <div className="text-nowrap">
                        {`D: 
                      ${val.nomor_akun_debet ?? ''} - 
                      ${val.nama_akun_debet ?? ''}`}
                      </div>

                      <div className="text-nowrap">
                        {`K: 
                      ${val.nomor_akun_kredit ?? ''} - 
                      ${val.nama_akun_kredit ?? ''}`}
                      </div>
                    </Td>

                    <Td style={{ minWidth: '200px' }}>
                      {val.keterangan_jurnal ?? '-'}
                    </Td>

                    <Td className="text-center text-nowrap">
                      {isJournal ? (
                        <Fragment>
                          <div className="text-success"> Sudah Dijurnal</div>
                          <div> {val.no_hpp}</div>
                        </Fragment>
                      ) : (
                        <div className="text-danger">Belum Dijurnal</div>
                      )}
                    </Td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <Td colSpan={11}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {data?.length ? (
        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({
              ...prev,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }))
          }
        />
      ) : null}
    </>
  );
};
