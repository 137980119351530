import { Row, Modal } from 'react-bootstrap';
import {
  IoWarning,
  IoCheckmarkCircleSharp,
  IoCloseCircle,
} from 'react-icons/io5';
import { ActionButton } from 'components';

export const ModalKonfirmasiPosting = ({
  modalConfirm,
  onModalClose,
  onWarningClose,
  onErrorClose,
  onSuccessClose,
  onSubmit,
  isSubmitting,
}) => {
  const { show, type, title, message } = modalConfirm;

  const error = {
    title: title ?? 'Gagal!',
    message: message ?? 'Terjadi keselahan!',
    icon: <IoCloseCircle size={80} color="#c93636" />,
    closeButton: {
      onClick: onErrorClose ?? null,
      text: 'Ok',
      variant: 'primary',
    },
  };

  const success = {
    title: title ?? 'Berhasil!',
    message: message ?? 'Berhasil!',
    icon: <IoCheckmarkCircleSharp size={80} color="#30b090" />,
    closeButton: {
      onClick: onSuccessClose ?? null,
      text: 'Ok',
      variant: 'primary',
    },
  };

  const warning = {
    title: title ?? 'Konfirmasi',
    message: message ?? 'Konfirmasi',
    icon: <IoWarning size={80} color="#c93636" />,
    closeButton: {
      onClick: onWarningClose ?? null,
      text: 'Batal',
      variant: 'danger',
    },
    submitButton: {
      onClick: onSubmit ?? null,
      text: 'Submit',
      variant: 'primary',
    },
  };

  const config =
    type === 'warning' ? warning : type === 'success' ? success : error;

  return (
    <Modal show={show} onHide={onModalClose}>
      <Modal.Header closeButton>{config.title}</Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div>{config.icon}</div>
        <small className="text-center font-weight-bold">{config.message}</small>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          {config?.submitButton?.onClick && (
            <ActionButton
              loading={isSubmitting}
              className="m-1"
              text="Posting"
              onClick={config?.submitButton?.onClick}
            />
          )}

          {config?.closeButton?.onClick && (
            <ActionButton
              text={config.closeButton.text}
              className="px-4"
              variant={config.closeButton.variant}
              onClick={config?.closeButton?.onClick}
            />
          )}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};
