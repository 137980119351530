import { useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { BadgeNotif } from 'pages/Transaksi/BDPProduksi/Components';
import SisaProduksiSection from './SisaProduksiSection';

const TabSisaProduksi = ({ sisaProduksi, setSisaProduksi }) => {
  const { notif } = useContext(NotifBDPContext);
  return (
    <Card className="border">
      <Tab.Container defaultActiveKey="sisa_produksi">
        <Card.Header className='className="d-flex justify-content-between align-items-center'>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="sisa_produksi">
                Sisa Produksi
                <BadgeNotif show={notif.bdp_sisa_produksi.sisa_produksi} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="sisa_produksi">
            <SisaProduksiSection
              sisaProduksi={sisaProduksi}
              setSisaProduksi={setSisaProduksi}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default TabSisaProduksi;
