import { Fragment, useContext } from 'react';
import { Td, Th, ThFixed } from 'components';
import { RupiahConvert } from 'utilities';
import { HPPContext } from '../Context';

export const TableSummary = () => {
  const { summary, totalSummary } = useContext(HPPContext);

  return (
    <Fragment>
      <div style={{ fontSize: '.9rem' }}>Summary BDP</div>
      <div className="d-flex justify-content-center align-items-center p-2 card">
        <div className="table-responsive">
          <table className="table table-bordered bg-white table-sm">
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th>Grup BDP</Th>
                <Th>Grand Total</Th>
              </tr>
            </thead>
            <tbody>
              {summary?.map((val, index) => {
                return (
                  <tr key={index}>
                    <Td className="text-center">{index + 1}</Td>

                    <Td>{val.nama_bdp}</Td>

                    <Td className="text-right">
                      {RupiahConvert(String(Math.round(val.total ?? 0))).detail}
                    </Td>
                  </tr>
                );
              })}
              <tr>
                <Td />
                <Td className="font-weight-bold text-right">Jumlah HPP</Td>
                <Td className="font-weight-bold text-right">
                  {RupiahConvert(String(Math.round(totalSummary))).detail}
                </Td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};
