const getNamaGrupRef = (ref) => {
  const GRUP = {
    RHW: 'Hardwood',
    RPW: 'Plywood',
    RFS: 'Factory Supply',
    RPF: 'Bahan Penunjang',
    RLC: 'Labour Cost',
    RLF: 'Labour Cost Finishing',
    RMP: 'Machine Process',
    RSF: 'Subkon Finishing',
    ROP: 'Biaya Overhead Pabrik',
  };

  if (ref) {
    return GRUP[ref];
  }

  return '';
};

export const mapBDPList = (data) =>
  data?.map((v) => ({
    ...v,
    tgl_bdp:
      v.tgl_bdp_bahan ??
      v.tgl_bdp_upah ??
      v.tgl_bdp_alat_mesin ??
      v.tgl_bdp_subkon ??
      v.tgl_bdp_overhead ??
      v.tgl_bdp_sisa_produksi,
    no_bdp:
      v.no_bdp_bahan ??
      v.no_bdp_upah ??
      v.no_bdp_alat_mesin ??
      v.no_bdp_subkon ??
      v.no_bdp_overhead ??
      v.no_bdp_sisa_produksi,
    nama_item: v.nama_item ?? v.uraian_pekerjaan,
    qty_bdp:
      v.qty_realisasi_produksi_bahan ??
      v.qty_realisasi_produksi_upah ??
      v.qty_realisasi_produksi_alat_mesin ??
      v.qty_realisasi_produksi_subkon ??
      v.qty_realisasi_produksi_overhead,
    nama_satuan: v.nama_satuan,
    harga_satuan:
      v.harga_satuan_rata_rata_pakai ??
      v.harga_satuan_kontrak_upah ??
      v.harga_satuan_kontrak_subkon ??
      v.harga_satuan_overhead,
    nama_grup: getNamaGrupRef(v.ref),
  })) ?? [];
