import React, { useState, useEffect, isValidElement } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal, DataStatus } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { BDPSubkonApi } from 'api';
import { calcGrandTotal } from './calculateOpname';
import TableDetailOpname from './TableDetailOpname';

const ModalDetailJurnalSubkon = ({
  modalConfig,
  setModalConfig,
  isHistory,
}) => {
  const dataModal = modalConfig?.data ?? {};
  const isValid = Boolean(dataModal?.id_realisasi_produksi_subkon);
  const [dataOpname, setDataOpname] = useState({});
  const [isFetch, setIsFetch] = useState({
    loading: false,
    success: false,
  });

  const getDataOpname = (id_realisasi_produksi_subkon) => {
    setIsFetch((prev) => ({ ...prev, loading: true }));

    BDPSubkonApi.opname({ id_realisasi_produksi_subkon })
      .then((res) => {
        setDataOpname(res?.data?.data);
        setIsFetch({ loading: false, success: true });
      })
      .catch(() => setIsFetch({ loading: false, success: false }));
  };

  const InfoItemVertical = ({ title, text }) => (
    <div className="mb-2">
      <small
        className="text-secondary"
        style={{ opacity: 0.8, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div
        className="text-dark"
        style={{
          fontWeight: 500,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  const InfoSection = () => {
    return (
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItemHorizontal
                label="Vendor"
                text={dataModal?.nama_vendor ?? '-'}
              />
              <InfoItemHorizontal
                label="Tgl. Realisasi Produksi"
                text={
                  DateConvert(
                    new Date(dataModal?.tgl_realisasi_produksi_subkon)
                  ).defaultDMY
                }
              />
              <InfoItemHorizontal
                label="No. Realisasi Produksi"
                text={dataModal?.no_realisasi_produksi_subkon}
              />
              <InfoItemHorizontal label="No. SPK" text={dataModal?.no_spk} />
              <InfoItemHorizontal label="No. RAP" text={dataModal?.no_rap} />
              <InfoItemHorizontal
                label="No. Job Order"
                text={dataModal?.no_job_order}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <table>
            <tbody>
              <InfoItemHorizontal
                label="Proyek"
                text={dataOpname?.nama_proyek ?? '-'}
              />
              <InfoItemHorizontal
                label="Item Barang Jadi"
                text={dataOpname?.nama_barang_jadi ?? '-'}
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const FormBody = () => {
    const calc = calcGrandTotal(dataOpname?.detail_opname);
    return (
      <Modal.Body>
        <InfoSection />
        <hr />
        <TableDetailOpname data={dataOpname?.detail_opname ?? []} />

        {isHistory && (
          <>
            <hr />
            <Row>
              <Col md>
                <InfoItemVertical
                  title="Tgl.BDP"
                  text={DateConvert(new Date(dataModal?.tgl_bdp_subkon)).detail}
                />
                <InfoItemVertical
                  title="Jumlah"
                  text={
                    RupiahConvert(String(parseInt(calc?.totalPeriodeIni || 0)))
                      .detail
                  }
                />
              </Col>
              <Col md>
                <InfoItemVertical
                  title="No.BDP"
                  text={dataModal?.no_bdp_subkon}
                />
              </Col>
              <Col md>
                <InfoItemVertical
                  title="COA Debet"
                  text={dataModal?.nama_coa_debet}
                />
                <InfoItemVertical
                  title="COA Kredit"
                  text={dataModal?.nama_coa_kredit}
                />
              </Col>
              <Col md>
                <InfoItemVertical
                  title="Keterangan Jurnal"
                  text={dataModal?.keterangan_jurnal}
                />
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    );
  };

  useEffect(() => {
    isValid && getDataOpname(dataModal.id_realisasi_produksi_subkon);
  }, [dataModal.id_realisasi_produksi_subkon]);

  if (isFetch.loading) {
    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() => setModalConfig({ data: {}, show: false })}
      >
        <Modal.Header closeButton>
          <b>Detail Data Jurnal BDP</b>
        </Modal.Header>
        <Modal.Body>
          <DataStatus loading={isFetch.loading} text="Memuat Data . . . " />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      size="xl"
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>Detail Data Jurnal BDP</b>
      </Modal.Header>

      {isValid ? <FormBody /> : <DataStatus text="Tidak dapat memuat data" />}
    </Modal>
  );
};

export default ModalDetailJurnalSubkon;
