import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  InfoItemHorizontal,
  TextArea,
  ActionButton,
  SelectSearch,
  DatePicker,
  Input,
  DataStatus,
} from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { BDPSubkonApi } from 'api';
import { calcGrandTotal } from './calculateOpname';
import TableDetailOpname from './TableDetailOpname';

const ModalJurnalBDP = ({
  modalConfig,
  setModalConfig,
  getInitialData,
  type,
}) => {
  const today = DateConvert(new Date()).default;
  const dataModal = modalConfig?.data ?? {};
  const isValid = Boolean(dataModal?.id_realisasi_produksi_subkon);
  const [isFetch, setIsFetch] = useState({
    loading: false,
    success: false,
  });
  const [dataOpname, setDataOpname] = useState({});
  const [dropdownCOA, setDropdownCOA] = useState([]);
  const calc = calcGrandTotal(dataOpname?.detail_opname ?? []);

  const formInitialValues = {
    id_realisasi_produksi_subkon: dataModal?.id_realisasi_produksi_subkon ?? '',
    tgl_realisasi_produksi_subkon:
      dataModal?.tgl_realisasi_produksi_subkon ?? '-',
    no_realisasi_produksi_subkon:
      dataModal?.no_realisasi_produksi_subkon ?? '-',
    nama_item: dataModal?.nama_item ?? '-',
    nama_vendor: dataModal?.nama_vendor ?? '-',
    qty_realisasi_produksi_subkon:
      dataModal?.qty_realisasi_produksi_subkon ?? 0,

    tgl_bdp_subkon: type === 'update' ? dataModal?.tgl_bdp_subkon ?? '' : '',
    no_bdp_subkon: type === 'update' ? dataModal?.no_bdp_subkon ?? '' : '',
    harga_satuan_kontrak_subkon: calc?.totalPeriodeIni || 0,
    id_coa_debet: type === 'update' ? dataModal?.id_coa_debet : '1010505',
    id_coa_kredit: type === 'update' ? dataModal?.id_coa_kredit : '2010102',
    keterangan_jurnal:
      type === 'update' ? dataModal.keterangan_jurnal ?? '' : '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_bdp_subkon: Yup.string().required('Pilih tanggal BDP'),
    no_bdp_subkon: Yup.string().required(
      'Pilih tgl. realisasi untuk menentukan nomor'
    ),
    id_coa_debet: Yup.string().required('Pilih COA Debet'),
    id_coa_kredit: Yup.string().required('Pilih COA Kredit'),
    keterangan_jurnal: Yup.string().required('Keterangan Jurnal wajib diisi'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = {
      ...values,
      nomor_akun_debet: values.id_coa_debet,
      nomor_akun_kredit: values.id_coa_kredit,
    };

    BDPSubkonApi.save(finalValue)
      .then(() => {
        setSubmitting(false);
        setModalConfig({ show: false, data: {} });
        getInitialData();
      })
      .catch(() => {
        setSubmitting(false);
        window.alert('Gagal menyimpan data!');
      });
  };

  const fetchCOA = () => {
    BDPSubkonApi.getCOA()
      .then((res) => {
        const coa = res?.data?.data ?? [];
        const mapcoa = coa.map((item) =>
          Object({
            ...item,
            value: item.id_coa,
            label: `${item.nomor_akun} - ${item.nama_akun}`,
          })
        );
        setDropdownCOA(mapcoa ?? []);
      })
      .catch(() => alert('Gagal memuat data COA!'));
  };

  const getDataOpname = (id_realisasi_produksi_subkon) => {
    setIsFetch((prev) => ({ ...prev, loading: true }));

    BDPSubkonApi.opname({ id_realisasi_produksi_subkon })
      .then((res) => {
        setDataOpname(res?.data?.data);
        setIsFetch({ loading: false, success: true });
      })
      .catch(() => setIsFetch({ loading: false, success: false }));
  };

  const InfoSection = ({ values }) => {
    return (
      <Row>
        <Col>
          <InfoItemHorizontal
            label="Vendor"
            text={values?.nama_vendor ?? '-'}
          />
          <InfoItemHorizontal
            label="Tgl. Realisasi Produksi"
            text={
              DateConvert(new Date(values?.tgl_realisasi_produksi_subkon))
                .defaultDMY
            }
          />
          <InfoItemHorizontal
            label="No. Realisasi Produksi"
            text={values?.no_realisasi_produksi_subkon}
          />
        </Col>
        <Col>
          <InfoItemHorizontal
            label="Proyek"
            text={dataOpname?.nama_proyek ?? '-'}
          />
          <InfoItemHorizontal
            label="Item Barang Jadi"
            text={dataOpname?.nama_barang_jadi ?? '-'}
          />
        </Col>
      </Row>
    );
  };

  const FormBody = ({ formik }) => {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      setValues,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = formik;

    const getNoHandler = (tanggal = today) => {
      BDPSubkonApi.getNomor({ tanggal }).then((res) =>
        setValues({
          ...values,
          tgl_bdp_subkon: tanggal,
          no_bdp_subkon: res.data.data,
        })
      );
    };

    useEffect(() => type !== 'update' && getNoHandler(), []);

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <InfoSection values={values} />
          <hr />
          <TableDetailOpname data={dataOpname?.detail_opname ?? []} />
          <hr />
          <Row>
            <Col md>
              <Row>
                <Col>
                  <DatePicker
                    label="Tgl. BDP"
                    type="date"
                    placeholderText="Pilih tgl. BDP"
                    selected={
                      values.tgl_bdp_subkon
                        ? new Date(values.tgl_bdp_subkon)
                        : ''
                    }
                    onChange={(date) => {
                      const newDate = DateConvert(date).default;
                      getNoHandler(newDate);
                    }}
                    error={
                      Boolean(errors.tgl_bdp_subkon) && touched.tgl_bdp_subkon
                    }
                    errorText={errors.tgl_bdp_subkon}
                  />
                </Col>
                <Col>
                  <Input
                    label="No. BDP"
                    type="text"
                    placeholder="Pilih tgl. BDP"
                    value={values.no_bdp_subkon}
                    error={
                      Boolean(errors.no_bdp_subkon) && touched.no_bdp_subkon
                    }
                    errorText={errors.no_bdp_subkon}
                    readOnly
                  />
                </Col>
              </Row>
              <Input
                label="Jumlah"
                value={
                  RupiahConvert(
                    String(parseInt(values.harga_satuan_kontrak_subkon))
                  ).detail
                }
                className="text-right"
                readOnly
              />
            </Col>

            <Col md>
              <SelectSearch
                label="COA Debet"
                name="id_coa_debet"
                placeholder="Pilih COA Debet"
                onChange={(val) => setFieldValue('id_coa_debet', val.value)}
                option={dropdownCOA}
                defaultValue={dropdownCOA.map((item) =>
                  item.id_coa === values.id_coa_debet ? item : ''
                )}
                error={errors.id_coa_debet && touched.id_coa_debet && true}
                errorText={
                  errors.id_coa_debet &&
                  touched.id_coa_debet &&
                  errors.id_coa_debet
                }
              />
              <SelectSearch
                label="COA Kredit"
                name="id_coa_kredit"
                placeholder="Pilih COA Kredit"
                onChange={(val) => setFieldValue('id_coa_kredit', val.value)}
                option={dropdownCOA}
                defaultValue={dropdownCOA.map((item) =>
                  item.id_coa === values.id_coa_kredit ? item : ''
                )}
                error={errors.id_coa_kredit && touched.id_coa_kredit && true}
                errorText={
                  errors.id_coa_kredit &&
                  touched.id_coa_kredit &&
                  errors.id_coa_kredit
                }
              />
            </Col>

            <Col md>
              <TextArea
                label="Keterangan Jurnal*"
                name="keterangan_jurnal"
                value={values.keterangan_jurnal}
                onChange={handleChange}
                error={
                  errors.keterangan_jurnal && touched.keterangan_jurnal && true
                }
                errorText={errors.keterangan_jurnal}
                rows={5} //menentukan tinggi text area
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant={type === 'update' ? 'warning' : 'primary'}
            type="submit"
            text="Simpan"
            className="px-4 text-white"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    );
  };

  useEffect(() => fetchCOA(), []);
  useEffect(
    () => isValid && getDataOpname(dataModal.id_realisasi_produksi_subkon),
    [dataModal.id_realisasi_produksi_subkon]
  );

  if (isFetch.loading) {
    return (
      <Modal
        size="xl"
        show={modalConfig.show}
        onHide={() => setModalConfig({ data: {}, show: false })}
      >
        <Modal.Header closeButton>
          <b>Detail Data Jurnal BDP</b>
        </Modal.Header>
        <Modal.Body>
          <DataStatus loading={isFetch.loading} text="Memuat Data . . . " />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal
      size="xl"
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>{`${type === 'update' ? 'Ubah' : 'Tambah'} Data Jurnal BDP`}</b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) =>
          isValid ? (
            <FormBody formik={formik} />
          ) : (
            <DataStatus text="Tidak dapat memuat data" />
          )
        }
      </Formik>
    </Modal>
  );
};

export default ModalJurnalBDP;
