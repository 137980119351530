import { Fragment, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
} from 'components';
import {
  FormSectionPostingHPP,
  InfoSectionJobOrder,
  TabContent,
  TableSummary,
} from './Components';
import { withHPPProvider } from './Context';
import { mapOnSubmit, validationSchema } from './Helpers';
import { useFetchSingleHPP } from './Hooks';
import { HPPApi } from 'api';

export const UbahHPP = withHPPProvider(({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_hpp } = useParams();
  const { isPageLoading, dataInfo, dataBDP } = useFetchSingleHPP(id_hpp);
  const [alert, setAlert] = useState({
    variant: 'danger',
    text: '',
  });

  const initialValues = {
    id_hpp,
    id_job_order: dataInfo.id_job_order,
    tgl_hpp: dataInfo.tgl_hpp,
    no_hpp: dataInfo.no_hpp,
    qty_hpp: parseFloat(dataInfo.qty_hpp ?? 0),
    jumlah_hpp: parseFloat(dataInfo.jumlah_hpp ?? 0),
    id_coa_debet: dataInfo.no_coa_debet,
    id_coa_kredit: dataInfo.no_coa_kredit,
    keterangan: dataInfo.keterangan_jurnal,
  };

  const onSubmit = (values, { setSubmitting }) => {
    const finalValues = mapOnSubmit({
      ...values,
      ...dataBDP,
    });

    HPPApi.update(finalValues)
      .then(() => {
        history.push('/transaksi/hpp', {
          alert: { variant: 'primary', text: 'Data berhasil disimpan' },
        });
      })
      .catch((error) => {
        setSubmitting(false);
        setAlert({
          variant: 'danger',
          text: `Gagal menyimpan data ${error.message ?? ''}`,
        });
      });
  };

  useEffect(() => setNavbarTitle('Harga Pokok Produksi (HPP)'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center mb-3">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() =>
          setAlert({
            ...alert,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoSectionJobOrder data={dataInfo} />
          <TabContent />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Fragment>
                <Row className="my-3 flex justify-content-center align-items-start">
                  <Col md="6">
                    <TableSummary />
                  </Col>

                  <Col md="6">
                    <FormSectionPostingHPP />
                  </Col>
                </Row>

                <div className="d-flex flex-row justify-content-end mt-2 pb-4">
                  <ActionButton
                    text="Posting"
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              </Fragment>
            )}
          </Formik>
        </Fragment>
      )}
    </CRUDLayout>
  );
});
