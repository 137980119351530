import { DataStatus, Table, Th, ThFixed, Td, Pagination } from 'components';
import { TableNumber, RupiahConvert } from 'utilities';

export const TableContent = ({
  data,
  type = 'page',
  paginationConfig,
  setPaginationConfig,
}) => {
  const errorMessage =
    type === 'page'
      ? 'Tidak ada data'
      : 'Hitung harga satuan rata-rata terlebih dulu';

  return (
    <>
      <Table>
        <thead>
          <tr>
            <ThFixed className="text-nowrap">No.</ThFixed>
            <ThFixed className="text-nowrap">Kode Item</ThFixed>
            <Th>Nama Item Bahan</Th>
            <Th>Satuan</Th>
            <Th>Kelompok Bahan</Th>
            <Th className="text-nowrap">Jenis Bahan</Th>
            <ThFixed className="text-nowrap">
              Harga Satuan <br /> Rata-rata
            </ThFixed>
          </tr>
        </thead>
        <tbody>
          {!data || data?.length < 1 ? (
            <tr>
              <td colSpan={7}>
                <DataStatus text={errorMessage} />
              </td>
            </tr>
          ) : (
            data.map((val, index) => {
              const harga = val.harga_satuan ?? val.hsr_current ?? 0;
              return (
                <tr key={index}>
                  <Td textCenter>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </Td>
                  <Td>{val.kode_item ?? '-'}</Td>
                  <Td>{val.nama_item ?? '-'}</Td>
                  <Td>{val.nama_satuan ?? '-'}</Td>
                  <Td>{val.nama_kelompok ?? '-'}</Td>
                  <Td>{val.nama_jenis ?? '-'}</Td>
                  <Td className="text-right">
                    {RupiahConvert(Math.round(harga).toString()).detail}
                  </Td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>

      {data?.length > 1 && (
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig((prev) => ({
              ...prev,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPaginationConfig((prev) => ({
              ...prev,
              page: 1,
              dataLength: e.target.value,
            }))
          }
        />
      )}
    </>
  );
};
