import { Fragment, useContext, useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  ActionButton,
  Alert,
  BackButton,
  CRUDLayout,
  DataStatus,
} from 'components';
import { TransferProduksiOverheadApi } from 'api';
import {
  InfoDetailJobOrder,
  TabBiayaOverhead,
  ModalFormTransfer,
  ModalKonfirmasi,
} from './Components';
import {
  TransferOverheadContext,
  withDropdownTransferOverheadProvider,
  withTransferOverheadProvider,
} from './Context';
import { useMockNumber } from './Hooks';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const TambahTransferOverheadProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id_permintaan_produksi } = useParams();
  const [generateMockNumber] = useMockNumber();
  const {
    dataJobOrder,
    modalForm,
    setJobOrder,
    dataPermintaan,
    dataTransfer,
    setDataPermintaan,
    setDataHistory,
    setModalKonfirmasi,
  } = useContext(TransferOverheadContext);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const onSubmit = () => {
    setSubmitting(true);

    const dataStatusPermintaan = dataPermintaan?.map((item) => ({
      id_permintaan_produksi_bo: item.id_permintaan_produksi_bo,
      status_permintaan: item.status_permintaan,
    }));

    const listDataTransfer = {
      data: dataTransfer,
    };

    axios
      .all([
        TransferProduksiOverheadApi.updateStatusPermintaan(
          dataStatusPermintaan
        ),
        TransferProduksiOverheadApi.create(listDataTransfer),
      ])
      .then(() =>
        history.push('/transaksi/transfer-produksi-overhead', {
          alert: {
            variant: 'primary',
            text: 'Tambah Data Transfer Overhead Produksi Berhasil',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: `Gagal Menyimpan Data Transfer Overhead Produksi ${
            response?.data?.message ?? ''
          }`,
        })
      )
      .finally(() => {
        setSubmitting(false);
        setModalKonfirmasi(false);
      });
  };

  const getInitialData = () => {
    setPageLoading(true);

    TransferProduksiOverheadApi.single({ id_permintaan_produksi })
      .then((res) => {
        const { biaya_overhead, history, ...jobOrder } = res?.data?.data;

        setJobOrder(jobOrder ?? {});
        setDataPermintaan(biaya_overhead ?? []);
        setDataHistory(history ?? []);
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Transfer Overhead Produksi');
    getInitialData();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="mp">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="mp">
                  <NavTab eventKey="mp" title="Biaya Overhead" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane eventKey="mp" component={<TabBiayaOverhead />} />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>

          <Row className="d-flex justify-content-end align-items-center px-3 pt-4 pb-2 ">
            <ActionButton
              disable={isSubmitting}
              text="Simpan"
              loading={isSubmitting}
              onClick={() => setModalKonfirmasi(true)}
            />
          </Row>
        </Fragment>
      )}

      {modalForm.show && (
        <ModalFormTransfer generateMockNumber={generateMockNumber} />
      )}

      <ModalKonfirmasi handleSubmit={onSubmit} isSubmitting={isSubmitting} />
    </CRUDLayout>
  );
};

export default withDropdownTransferOverheadProvider(
  withTransferOverheadProvider(TambahTransferOverheadProduksi)
);
