import { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { DataStatus, ActionButton, Table, Td, Th } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { PostingBiayaPrelimApi } from 'api';
import { ModalPosting } from '../Section';

const TableHistory = ({ id: ID, type: TYPE, submitCount }) => {
  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalFormConfig, setModalFormConfig] = useState({
    show: false,
    id: '',
    idHistory: '',
    type: '',
  });

  const TABLE_HEADER = [
    { show: true, label: 'No', props: { width: 30 } },
    {
      show: true,
      label: 'Informasi Posting Biaya Prelim',
      props: { noPadding: true, width: 100 },
    },
    {
      show: true,
      label: 'Item Prelim',
      props: { noPadding: true, width: 250 },
    },
    { show: true, label: 'COA', props: {} },
    { show: true, label: 'Jumlah', props: {} },
    { show: true, label: 'Keterangan Posting', props: {} },
    { show: true, label: 'Aksi', props: { width: 10 } },
  ];

  // Menghasilkan tipe untuk params pada fetching data
  const generateTypeOnRequest = () => {
    if (TYPE === 'HONOR') return 'honor_staff';
    if (TYPE === 'ATK') return 'atk';
    if (TYPE === 'SAFETY') return 'safety';
    if (TYPE === 'HANDTOOLS') return 'hand_tools';
    if (TYPE === 'PROTEKSIONSITE') return 'proteksi';
    if (TYPE === 'LAINLAIN') return 'lain_lain';
  };

  // Mendapatkan colspan pada tabel
  const generateColSpan = useMemo(
    () => TABLE_HEADER.filter((val) => val.show).length,
    [dataTable]
  );

  // Menyembunyikan modal form
  const onHideModalFormHandler = useCallback(() => {
    setModalFormConfig({
      show: false,
      id: '',
    });
  }, [modalFormConfig.show]);

  // Menampilkan modal form
  const onShowModalFormHandler = useCallback(
    (id, idHistory) => {
      setModalFormConfig({
        show: true,
        id: id,
        idHistory: idHistory,
        type: TYPE,
      });
    },
    [modalFormConfig.show]
  );

  // Fetching data
  const fetchingData = async () => {
    setIsLoading(true);
    setDataTable([]);

    return await PostingBiayaPrelimApi.history({
      id_spk: ID,
      tipe: generateTypeOnRequest(),
    })
      .then((res) => {
        setDataTable(res?.data?.data ?? []);
      })
      .catch(() => {
        dataTable([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchingData();
  }, [submitCount]);

  // Menampilkan data pada tabel
  const DataTable = ({ index, data, onDetail }) => {
    return (
      <tr>
        <Td textCenter>{index + 1}</Td>
        <Td>
          <div>
            {data.tgl_posting_biaya_prelim
              ? DateConvert(new Date(data.tgl_posting_biaya_prelim)).defaultDMY
              : '-'}
          </div>
          <div>{data.no_posting_biaya_prelim ?? '-'}</div>
        </Td>
        <Td>{data.nama_item_prelim ?? '-'}</Td>
        <Td width={200}>
          <div>
            Debet : {data.nomor_akun_debet ? `${data.nomor_akun_debet} - ` : ''}{' '}
            {data.nama_akun_debit ?? ''}
          </div>
          <div>
            Kredit:{' '}
            {data.nomor_akun_kredit ? `${data.nomor_akun_kredit} - ` : ''}{' '}
            {data.nama_akun_kredit ?? ''}
          </div>
        </Td>
        <Td width={110} textRight>
          {data.jumlah_harga
            ? RupiahConvert(String(parseFloat(data.jumlah_harga))).detail
            : RupiahConvert(String(0)).detail}
        </Td>
        <Td width={200}>{data.keterangan_posting ?? ''}</Td>
        <Td noPadding className="text-center">
          <ActionButton
            size="sm"
            className="m-1"
            onClick={() =>
              onDetail(data.id_realisasi, data.id_posting_biaya_prelim)
            }
          >
            <IoEyeOutline />
          </ActionButton>
        </Td>
      </tr>
    );
  };

  return (
    <div>
      <div className="p-2">
        <b>History Posting Biaya Prelim</b>
      </div>

      {/* Table */}
      <Table responsive>
        <thead className="bg-light">
          <tr>
            {TABLE_HEADER.map((val, index) => (
              <Th {...val.props} key={index}>
                {val.label}{' '}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataTable && dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <DataTable
                key={index}
                index={index}
                data={val}
                onDetail={onShowModalFormHandler}
              />
            ))
          ) : (
            <tr>
              <Td colSpan={generateColSpan}>
                <DataStatus
                  loading={isLoading}
                  text={isLoading ? 'Memuat data . . .' : 'Tidak ada data'}
                />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal */}
      <ModalPosting
        modalType="DETAIL"
        type={TYPE}
        id={modalFormConfig.id}
        idHistory={modalFormConfig.idHistory}
        show={modalFormConfig.show}
        onHide={onHideModalFormHandler}
      />
    </div>
  );
};

export default TableHistory;
