import { createContext, useState } from 'react';

export const TransferOverheadContext = createContext();

export const TransferOverheadProvider = ({ children }) => {
  const [dataJobOrder, setJobOrder] = useState({});
  const [dataPermintaan, setDataPermintaan] = useState([{}]);
  const [dataTransfer, setDataTransfer] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [modalForm, setModalForm] = useState({
    show: false,
    action: 'create',
    index: null,
    data: null,
  });
  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);

  return (
    <TransferOverheadContext.Provider
      value={{
        dataJobOrder,
        setJobOrder,
        dataPermintaan,
        setDataPermintaan,
        dataHistory,
        setDataHistory,
        dataTransfer,
        setDataTransfer,
        modalForm,
        setModalForm,
        modalKonfirmasi,
        setModalKonfirmasi,
      }}
    >
      {children}
    </TransferOverheadContext.Provider>
  );
};

export const withTransferOverheadProvider = (Component) => (props) => {
  return (
    <TransferOverheadProvider>
      <Component {...props} />
    </TransferOverheadProvider>
  );
};
