import { Formik } from 'formik';
import { FilterModal, SelectSearch } from 'components';

export const ModalFilter = ({ show, setShow, filter, setFilter, dropdown }) => {
  const defaultDropdown = { value: undefined, label: 'Semua' };

  const initialValues = {
    id_satuan: filter?.id_satuan,
    id_kelompok: filter?.id_kelompok,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({
      ...prev,
      id_kelompok: undefined,
      id_satuan: undefined,
      active: false,
    }));

    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      id_kelompok: undefined,
      id_satuan: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            label="Kelompok Item Overhead"
            name="id_kelompok"
            placeholder="Pilih Kelompok"
            onChange={(val) => setFieldValue('id_kelompok', val.value)}
            option={[defaultDropdown].concat(dropdown?.kelompok)}
            defaultValue={
              values?.id_kelompok
                ? dropdown?.kelompok?.find(
                    (v) => v.value === values?.id_kelompok
                  )
                : defaultDropdown
            }
          />

          <SelectSearch
            label="Satuan Pakai"
            name="id_satuan"
            placeholder="Pilih Satuan Pakai"
            onChange={(val) => setFieldValue('id_satuan', val.value)}
            option={[defaultDropdown].concat(dropdown?.satuan)}
            defaultValue={
              values?.id_satuan
                ? dropdown?.satuan?.find((v) => v.value === values.id_satuan)
                : defaultDropdown
            }
          />
        </FilterModal>
      )}
    </Formik>
  );
};
