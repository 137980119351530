import { DateConvert } from 'utilities';
import { HargaSatuanRataRataPakaiApi } from 'api';

export const fetchIsPosting = ({ formik, setData, setPaginationConfig }) => {
  const { values, setFieldValue } = formik;

  const firstDateOfMonth = DateConvert(
    new Date(values.year, values.month - 1, 1)
  ).default;

  HargaSatuanRataRataPakaiApi.cekStatus({
    tanggal: firstDateOfMonth,
  }).then((resStatus) => {
    const isPosted = Boolean(resStatus.data.message === 'Sudah Di Posting');

    setFieldValue('status_posting', resStatus.data.message);
    setFieldValue('is_status_posting', isPosted);

    setData([]);
    setPaginationConfig({
      page: '1',
      dataLength: '10',
      totalPage: '1',
      dataCount: '0',
    });
  });
};

export const getHitungHSR = ({
  formik,
  paginationConfig,
  setPaginationConfig,
  setData,
}) => {
  const { values, setTouched, setErrors } = formik;

  const firstDateOfMonth = DateConvert(
    new Date(values.year, values.month - 1, 1)
  ).default;

  const errors = {};
  const touched = {};

  if (!values.month) {
    errors.month = 'required';
    touched.month = true;
  }

  if (!values.year) {
    errors.year = 'required';
    touched.year = true;
  }

  // Show Errors
  if (Object.keys(errors).length) {
    setTouched(touched);
    setErrors(errors);
    return;
  }

  // Get Data HSR from Backend
  HargaSatuanRataRataPakaiApi.hsr({
    tanggal: firstDateOfMonth,
    q: paginationConfig.q,
    page: paginationConfig.page,
    per_page: paginationConfig.dataLength,
  }).then((resData) => {
    const data = resData.data ?? [];
    setData(
      data.data.map((item) => ({
        ...item,
        nama_satuan: item.nama_kelompok,
        nama_kelompok: item.nama_satuan,
      }))
    );
    setPaginationConfig((prev) => ({
      ...prev,
      totalPage: data.total_page,
      dataCount: data.data_count,
    }));
  });
};
