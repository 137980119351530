import { useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import HardwoodSection from './HardwoodSection';
import PlywoodSection from './PlywoodSection';
import FactorySupplySection from './FactorySupplySection';
import BahanPenunjangSection from './BahanPenunjangSection';
import { BadgeNotif } from 'pages/Transaksi/BDPProduksi/Components';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';

const TabBahan = ({
  hardwood,
  setHardwood,
  plywood,
  setPlywood,
  factorySupply,
  setFactorySupply,
  bahanPenunjang,
  setBahanPenunjang,
}) => {
  const { notif } = useContext(NotifBDPContext);

  return (
    <Card className="border">
      <Tab.Container defaultActiveKey="hardwood">
        <Card.Header className='className="d-flex justify-content-between align-items-center'>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="hardwood">
                Hardwood
                <BadgeNotif show={notif.bdp_bahan.hardwood} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="plywood">
                Plywood
                <BadgeNotif show={notif.bdp_bahan.plywood} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="factory_supply">
                Factory Supply
                <BadgeNotif show={notif.bdp_bahan.factory_supply} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bahan_penunjang">
                Bahan Penunjang
                <BadgeNotif show={notif.bdp_bahan.bahan_penunjang} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="hardwood">
            <HardwoodSection hardwood={hardwood} setHardwood={setHardwood} />
          </Tab.Pane>
          <Tab.Pane eventKey="plywood">
            <PlywoodSection plywood={plywood} setPlywood={setPlywood} />
          </Tab.Pane>
          <Tab.Pane eventKey="factory_supply">
            <FactorySupplySection
              factorySupply={factorySupply}
              setFactorySupply={setFactorySupply}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="bahan_penunjang">
            <BahanPenunjangSection
              bahanPenunjang={bahanPenunjang}
              setBahanPenunjang={setBahanPenunjang}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default TabBahan;
