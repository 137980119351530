/**
 * Pre form page sebelum membuat data BDP Produksi
 */

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import Axios from 'axios';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  BackButton,
  Table,
  Td,
  Th,
  ThFixed,
  Pagination,
  DataStatus,
  FilterButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { BDPProduksiApi } from 'api';
import { ModalFilterRealisasiProduksi } from './components';

const ListRealisasiProduksiBDP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const style = { minWidth: '250px' };
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER)
    ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER))
    : {};
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION)
    ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION))
    : {};

  const [dataRealisasi, setDataRealisasi] = useState([]);
  const [isLoading, setIsLoading] = useState({
    page: true,
    dropdown: true,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });
  const [dropdown, setDropdown] = useState({
    spk: [],
    customer: [],
    proyek: [],
  });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_realisasi_awal: filter?.tgl_realisasi_awal,
      tgl_realisasi_akhir: filter?.tgl_realisasi_akhir,
      no_spk: filter?.no_spk,
      nama_customer: filter?.nama_customer,
      nama_proyek: filter?.nama_proyek,
    },
    pagination: {
      page: pagination?.page ?? '1',
      dataLength: pagination?.dataLength ?? '10',
      totalPage: pagination?.totalPage ?? '1',
      dataCount: pagination?.dataCount ?? '0',
    },
  });

  const getInitialData = () => {
    setIsLoading((prev) => ({ ...prev, page: true }));

    BDPProduksiApi.get({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter,
    })
      .then((res) => {
        const data = res?.data?.data;

        setDataRealisasi(data);
        setDataFilter((prev) => ({
          ...prev,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data.data_count,
            totalPage: res?.data?.total_page,
          },
        }));
      })
      .finally(() => setIsLoading((prev) => ({ ...prev, page: false })));
  };

  const fetchDropdown = () => {
    setIsLoading((prev) => ({ ...prev, dropdown: true }));

    Axios.all([
      BDPProduksiApi.dropdownSPKRealisasi(),
      BDPProduksiApi.dropdown({ tipe: 'customer' }),
      BDPProduksiApi.dropdown({ tipe: 'proyek' }),
    ])
      .then(
        Axios.spread((resSPK, resCustomer, resProyek) => {
          const spk = resSPK.data.data
            .filter((v) => v.no_spk !== null)
            .map((v) => ({ value: v.no_spk, label: v.no_spk }));

          const customer = resCustomer?.data?.data?.map((cus) => ({
            value: cus.nama_customer,
            label: cus.nama_customer,
          }));
          const proyek = resProyek?.data?.data?.map((pro) => ({
            value: pro.nama_proyek,
            label: pro.nama_proyek,
          }));

          setDropdown({ spk, customer, proyek });
        })
      )
      .catch(({ response }) =>
        alert(`Gagal memuat data dropdown ${response?.data?.message ?? ''}`)
      )
      .finally(() => setIsLoading((prev) => ({ ...prev, dropdown: false })));
  };

  const searchHandler = (e) => {
    const key = e.target.value;
    setSearchConfig(() => ({ key, status: key.length > 0 ? true : false }));

    setDataFilter((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: '1',
        dataLength: '10',
        totalPage: '1',
        dataCount: '0',
      },
    }));
  };

  useEffect(() => {
    getInitialData();

    return () => {};
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter,
  ]);

  useEffect(() => {
    setNavbarTitle('BDP Produksi');
    fetchDropdown();
  }, []);

  return (
    <CRUDLayout>
      <Row className="mb-2 d-flex justify-content-end align-items-center">
        <Col
          md={5}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            active={dataFilter?.filter?.active}
            onClick={() => setModalConfig({ show: true, data: {} })}
          />
        </Col>
        <Col className="d-flex flex-row justify-content-end align-items-center">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      {isLoading.page ? (
        <DataStatus loading={isLoading.page} text="Memuat data ..." />
      ) : dataRealisasi?.length > 0 ? (
        <>
          <div className="font-weight-bold">List Data Realisasi Produksi</div>
          <Table className="mt-1">
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Informasi Realisasi</ThFixed>
                <ThFixed>Informasi SPK</ThFixed>
                <ThFixed>Informasi RAP</ThFixed>
                <ThFixed>Indormasi Job Order</ThFixed>
                <Th style={style}>Customer</Th>
                <Th style={style}>Proyek</Th>
                <Th style={style}>Barang Jadi</Th>
                <Th>Qty. Job Order</Th>
                <ThFixed>Aksi</ThFixed>
              </tr>
            </thead>
            <tbody>
              {dataRealisasi?.map((val, index) => (
                <tr key={index}>
                  <Td>
                    {TableNumber(
                      dataFilter?.pagination?.page,
                      dataFilter?.pagination?.dataLength,
                      index
                    )}
                  </Td>
                  <Td>
                    <div>
                      {
                        DateConvert(new Date(val.tgl_realisasi_produksi))
                          .defaultDMY
                      }
                    </div>
                    <div> {val.no_realisasi_produksi ?? '-'} </div>
                  </Td>
                  <Td>
                    <div>{DateConvert(new Date(val.tgl_spk)).defaultDMY}</div>
                    <div> {val.no_spk ?? '-'} </div>
                  </Td>
                  <Td>
                    <div>{DateConvert(new Date(val.tgl_rap)).defaultDMY}</div>
                    <div> {val.no_rap ?? '-'} </div>
                  </Td>
                  <Td>
                    <div>
                      {DateConvert(new Date(val.tgl_job_order)).defaultDMY}
                    </div>
                    <div> {val.no_job_order ?? '-'} </div>
                  </Td>
                  <Td>{val.nama_customer ?? '-'}</Td>
                  <Td>{val.nama_proyek}</Td>
                  <Td>
                    {val.kode_barang_jadi
                      ? `(${val.kode_barang_jadi}) ${
                          val.nama_barang_jadi ?? ''
                        }`
                      : ''}
                  </Td>
                  <Td className="text-right">
                    {val.qty_rap
                      ? `${val.qty_rap} ${val.nama_satuan ?? ''}`
                      : '-'}
                  </Td>
                  <Td>
                    <ActionButton
                      variant="primary"
                      size="sm"
                      className="m-1"
                      onClick={() => {
                        // Store Filter State to Session

                        sessionStorage.setItem(
                          SESSION_KEY.FILTER,
                          JSON.stringify(dataFilter?.filter)
                        );
                        sessionStorage.setItem(
                          SESSION_KEY.PAGINATION,
                          JSON.stringify(dataFilter?.pagination)
                        );

                        history.push(
                          `/transaksi/bdp-produksi/data/${val.id_job_order}`,
                          val
                        );
                      }}
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>

          {!searchConfig.status && (
            <Pagination
              dataLength={dataFilter?.pagination?.dataLength}
              dataNumber={
                dataFilter?.pagination?.page *
                  dataFilter?.pagination?.dataLength -
                dataFilter?.pagination?.dataLength +
                1
              }
              dataPage={
                dataFilter?.pagination?.dataCount <
                dataFilter?.pagination?.dataLength
                  ? dataFilter?.pagination?.dataCount
                  : dataFilter?.pagination?.page *
                    dataFilter?.pagination?.dataLength
              }
              dataCount={dataFilter?.pagination?.dataCount}
              currentPage={dataFilter?.pagination?.page}
              totalPage={dataFilter?.pagination?.totalPage}
              onPaginationChange={({ selected }) =>
                setDataFilter({
                  ...dataFilter,
                  pagination: {
                    ...dataFilter.pagination,
                    page: selected + 1,
                  },
                })
              }
              onDataLengthChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  pagination: {
                    ...dataFilter.pagination,
                    page: 1,
                    dataLength: e.target.value,
                  },
                })
              }
            />
          )}
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalConfig.show && (
        <ModalFilterRealisasiProduksi
          SESSION_KEY={SESSION_KEY}
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          isLoading={isLoading}
        />
      )}
    </CRUDLayout>
  );
};

export default ListRealisasiProduksiBDP;
