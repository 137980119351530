import Services from 'services';

class BDPSisaProduksiApi {
  get(params) {
    return Services.get('/bdp_sisa_produksi/page', { params });
  }

  getSingle(params) {
    return Services.get('/bdp_sisa_produksi/single_realisasi', { params });
  }

  getSingleHistory(params) {
    return Services.get('/bdp_sisa_produksi/page_bdp', { params });
  }

  getCOA() {
    return Services.get('/bdp_sisa_produksi/dropdown');
  }

  getNomor(params) {
    return Services.get('/bdp_sisa_produksi/no_baru', { params });
  }

  save(data) {
    return Services.post('/bdp_sisa_produksi', data);
  }
}

export default new BDPSisaProduksiApi();
