import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { DataStatus } from 'components';
import { BDPBahanApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import {
  hasNotifCheck,
  parentHasNotifCheck,
} from 'pages/Transaksi/BDPProduksi/Helpers';
import TableRealisasiBahan from './comps/TableRealisasiBahan';
import TableHistoryBahan from './comps/TableHistoryBahan';

const FactorySupplySection = ({ factorySupply, setFactorySupply }) => {
  const location = useLocation();
  const { no_realisasi_produksi } = location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [historyFactorySupply, setHistoryFactorySupply] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPBahanApi.single({ ref: 'RFS', no_realisasi_produksi }),
      BDPBahanApi.history({ id_job_order, ref: 'RFS' }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data?.realisasi_bahan;
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setFactorySupply(data);
          setHistoryFactorySupply(history);

          const hasNotif = hasNotifCheck(
            data?.filter((e) => e.ref === 'RFS'),
            history
          );
          setNotif((prev) => ({
            ...prev,
            bdp_bahan: {
              ...prev.bdp_bahan,
              status: parentHasNotifCheck(prev.bdp_bahan, hasNotif),
              factory_supply: hasNotif,
            },
          }));
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInitialData();
  }, []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Factory Supply
      </small>
      <TableRealisasiBahan
        dataList={factorySupply}
        dataHistory={historyFactorySupply}
        getInitialData={getInitialData}
        refKey="RFS"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List BDP Yang Telah Diinput
      </small>
      <TableHistoryBahan
        type="update"
        dataList={factorySupply}
        setDataList={setFactorySupply}
        refKey="RFS"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistoryBahan
        type="detail"
        dataHistory={historyFactorySupply}
        refKey="RFS"
      />
    </div>
  );
};

export default FactorySupplySection;
