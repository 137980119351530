export const calculateOpname = (
  qty,
  unit_price,
  persen_periode_lalu,
  persen_periode_ini
) => {
  const parseQty = parseFloat(qty ?? 0);
  const harga_satuan = parseInt(unit_price ?? 0);
  const persentase_lalu = parseFloat(persen_periode_lalu ?? 0);
  const persentase_ini = parseFloat(persen_periode_ini ?? 0);
  const persentase_hingga_ini = parseFloat(persentase_ini + persentase_lalu);

  const jumlah = parseInt(parseQty * harga_satuan);
  const total_persentase_lalu = Math.round(
    parseFloat(persentase_lalu / 100) * jumlah
  );
  const total_persentase_ini = Math.round(
    parseFloat(persentase_ini / 100) * jumlah
  );
  const total_persentase_hingga_ini = Math.round(
    parseFloat(persentase_hingga_ini / 100) * jumlah
  );

  return {
    persentase_hingga_ini,
    jumlah,
    total_persentase_lalu,
    total_persentase_ini,
    total_persentase_hingga_ini,
  };
};

export const calcGrandTotal = (data) => {
  const calcAllOpname = data?.map(
    ({ qty, unit_price, persentase_periode_lalu, persentase_periode_ini }) =>
      calculateOpname(
        qty,
        unit_price,
        persentase_periode_lalu,
        persentase_periode_ini
      )
  );

  const totalJumlah = calcAllOpname?.reduce(
    (acc, { jumlah }) => acc + parseInt(jumlah ?? 0),
    0
  );
  const totalPeriodeLalu = calcAllOpname?.reduce(
    (acc, { total_persentase_lalu }) =>
      acc + parseInt(total_persentase_lalu ?? 0),
    0
  );
  const totalPeriodeIni = calcAllOpname?.reduce(
    (acc, { total_persentase_ini }) =>
      acc + parseInt(total_persentase_ini ?? 0),
    0
  );
  const totalPeriodeHinggaIni = calcAllOpname?.reduce(
    (acc, { total_persentase_hingga_ini }) =>
      acc + parseInt(total_persentase_hingga_ini ?? 0),
    0
  );
  const retensi = totalJumlah - totalPeriodeLalu;

  return {
    totalJumlah,
    totalPeriodeLalu,
    totalPeriodeIni,
    totalPeriodeHinggaIni,
    retensi,
  };
};
