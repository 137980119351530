import Services from 'services';

class HargaSatuanRataRataPakaiApi {
  page(params) {
    return Services.get('/harga_satuan_pakai/page', { params });
  }

  hsr(params) {
    return Services.get('/harga_satuan_pakai/hitung_hsr', { params });
  }

  create(data) {
    return Services.post('/harga_satuan_pakai', data);
  }

  dropdown(params) {
    return Services.get('/harga_satuan_pakai/dropdown', { params });
  }

  cekStatus(params) {
    return Services.get('harga_satuan_pakai/hsr', { params });
  }
}

export default new HargaSatuanRataRataPakaiApi();
