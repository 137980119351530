import { Fragment, useContext } from 'react';
import { Card, Tab, Nav } from 'react-bootstrap';
import { HPPContext } from '../Context';
import { TableBDP } from './TableBDP';

export const TabContent = () => {
  const { dataBDP } = useContext(HPPContext);

  return (
    <Fragment>
      <div className="mt-2" style={{ fontSize: '.9rem' }}>
        Detail Data BDP
      </div>
      <Card>
        <Tab.Container defaultActiveKey="bahan">
          {/* Tab Navigation */}
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="bahan" defaultValue="bahan">
              <NavTab eventKey="bahan" title="BDP Bahan" />
              <NavTab eventKey="upah" title="BDP Upah" />
              <NavTab eventKey="alat" title="BDP Alat & Mesin" />
              <NavTab eventKey="subcont" title="BDP Subcont" />
              <NavTab eventKey="overhead" title="BDP Overhead" />
              <NavTab eventKey="sisa" title="BDP Sisa Produksi" />
            </Nav>
          </Card.Header>

          {/* Tab Content */}
          <Card.Body>
            <Tab.Content>
              <TabPane
                eventKey="bahan"
                component={
                  <TableBDP
                    data={dataBDP?.bahan ?? []}
                    title="Bahan"
                    tipe="bahan"
                  />
                }
              />
              <TabPane
                eventKey="upah"
                component={
                  <TableBDP
                    data={dataBDP?.upah ?? []}
                    title="Upah"
                    tipe="upah"
                  />
                }
              />
              <TabPane
                eventKey="alat"
                component={
                  <TableBDP
                    data={dataBDP?.alat_mesin ?? []}
                    title="Alat & Mesin"
                    tipe="alat"
                  />
                }
              />
              <TabPane
                eventKey="subcont"
                component={
                  <TableBDP
                    data={dataBDP?.subkon ?? []}
                    title="Subcont"
                    tipe="subcont"
                  />
                }
              />
              <TabPane
                eventKey="overhead"
                component={
                  <TableBDP
                    data={dataBDP?.overhead ?? []}
                    title="Overhead"
                    tipe="overhead"
                  />
                }
              />
              <TabPane
                eventKey="sisa"
                component={
                  <TableBDP
                    data={dataBDP?.sisa_produksi ?? []}
                    title="Sisa Produksi"
                    tipe="sisa"
                  />
                }
              />
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </Fragment>
  );
};

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
