import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { ActionButton, Input, SelectMonth } from 'components';
import { CustomSelect } from './CustomSelect';
import { getHitungHSR } from '../helpers';

export const FormPosting = ({
  today,
  formik,
  paginationConfig,
  setPaginationConfig,
  setData,
}) => {
  const { values, errors, touched, setFieldValue } = formik;

  return (
    <Row className="d-flex justify-content-start align-items-end">
      <Col md="2" className="justify-content-end align-items-end">
        <small className="d-block">Bulan</small>
        <SelectMonth
          noMargin
          size="md"
          optionDefault
          defaultValue={values.month}
          onChange={(e) => setFieldValue('month', e.target.value)}
          error={errors.month && touched.month}
        />
      </Col>

      <Col md="2">
        <small className="d-block">Tahun</small>
        <CustomSelect
          defaultValue={values.year}
          error={errors.year && touched.year}
          onChange={(e) => setFieldValue('year', e.target.value)}
        >
          <option value="">Pilih tahun</option>
          {_.range(today.getFullYear(), 1999).map((val) => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </CustomSelect>
      </Col>

      <Col md="3" className="pt-2">
        <Input
          value={values.status_posting}
          noMargin
          disabled
          label="Status Posting"
          size="md"
        />
      </Col>

      <Col md="3" className="">
        <ActionButton
          disable={values.is_status_posting === false ? false : true}
          text="Hitung Satuan Rata Rata"
          size="sm"
          className="py-2"
          onClick={() =>
            getHitungHSR({
              formik,
              paginationConfig,
              setPaginationConfig,
              setData,
            })
          }
        />
      </Col>
    </Row>
  );
};
