/**
 * Table memiliki 2 fungsi untuk :
 * Menampilkan data BDP yang telah diinput dan History BDP
 */
import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { IoPencilOutline, IoEyeOutline, IoCloseOutline } from 'react-icons/io5';
import { Td, Th, Table, ActionButton, DataStatus, ThFixed } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { useIsGuest } from 'hooks';
import { calcGrandTotal } from './calculateOpname';
import ModalJurnalBDP from './ModalJurnalBDP';
import ModalJurnalBDPDetail from './ModalJurnalBDPDetail';

const TableHistorySubkon = ({
  dataList,
  setDataList,
  dataHistory,
  refKey,
  type = 'detail',
}) => {
  // type : update | detail
  const isGuest = useIsGuest();
  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  const TableBodyUpdate = () => {
    return (
      <tbody>
        {dataList?.filter((item) => item.ref === refKey && item.isAdded)
          ?.length > 0 ? (
          dataList
            ?.filter((item) => item.ref === refKey && item.isAdded)
            ?.map((val, index) => {
              const calc = calcGrandTotal(val?.rincian);

              return (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>
                    <span className="d-block">{`${
                      val.tgl_realisasi_produksi_subkon
                        ? DateConvert(
                            new Date(val.tgl_realisasi_produksi_subkon)
                          ).defaultDMY
                        : '-'
                    }`}</span>
                    <span className="d-block">{`${
                      val.no_realisasi_produksi_subkon ?? ''
                    }`}</span>
                  </Td>
                  <Td>{val.nama_vendor ?? '-'}</Td>
                  <Td>
                    {
                      RupiahConvert(String(parseInt(calc.totalPeriodeIni || 0)))
                        .detail
                    }
                  </Td>
                  <Td>
                    {val.nama_coa_debet && (
                      <span className="d-block">{`D: ${val.nama_coa_debet}`}</span>
                    )}
                    {val.nama_coa_kredit && (
                      <span className="d-block">{` K: ${
                        val.nama_coa_kredit ?? ''
                      }`}</span>
                    )}
                  </Td>
                  <Td className="text-right">{val.keterangan_jurnal ?? ''}</Td>
                  <Td className="text-center">
                    {!isGuest && (
                      <ButtonGroup>
                        <ActionButton
                          size="sm"
                          variant="warning"
                          onClick={() =>
                            setModalConfig({ show: true, data: val })
                          }
                        >
                          <IoPencilOutline color="white" />
                        </ActionButton>

                        <ActionButton
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            const copiedDataList = [...dataList];
                            const index = copiedDataList.findIndex(
                              (item) =>
                                item.id_realisasi_produksi_subkon ===
                                val.id_realisasi_produksi_subkon
                            );
                            copiedDataList[index].isAdded = false;

                            setDataList(copiedDataList);
                          }}
                        >
                          <IoCloseOutline />
                        </ActionButton>
                      </ButtonGroup>
                    )}
                  </Td>
                </tr>
              );
            })
        ) : (
          <tr>
            <Td colSpan={9}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </tr>
        )}
      </tbody>
    );
  };

  const TableBodyDetail = () => {
    return (
      <tbody>
        {dataHistory?.length > 0 ? (
          dataHistory?.map((val, index) => {
            const calc = calcGrandTotal(val?.rincian);

            return (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  <span className="d-block">{`${
                    val.tgl_bdp_subkon
                      ? DateConvert(new Date(val.tgl_bdp_subkon)).defaultDMY
                      : '-'
                  }`}</span>
                  <span className="d-block">{`${
                    val.no_bdp_subkon ?? ''
                  }`}</span>
                </Td>
                <Td>{val.nama_vendor ?? '-'}</Td>
                <Td>
                  {
                    RupiahConvert(String(parseInt(calc.totalPeriodeIni || 0)))
                      .detail
                  }
                </Td>
                <Td>
                  {val.nama_coa_debet && (
                    <span className="d-block">{`D: ${val.nama_coa_debet}`}</span>
                  )}
                  {val.nama_coa_kredit && (
                    <span className="d-block">{` K: ${
                      val.nama_coa_kredit ?? ''
                    }`}</span>
                  )}
                </Td>
                <Td>{val.keterangan_jurnal ?? ''}</Td>
                <Td className="text-center">
                  <ActionButton
                    size="sm"
                    variant="primary"
                    onClick={() => setModalConfig({ show: true, data: val })}
                  >
                    <IoEyeOutline />
                  </ActionButton>
                </Td>
              </tr>
            );
          })
        ) : (
          <tr>
            <Td colSpan={9}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </tr>
        )}
      </tbody>
    );
  };

  return (
    <>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed className="py-2 text-center">No.</ThFixed>
            <ThFixed className="p-2 text-center">Informasi BDP</ThFixed>
            <Th className="p-2 text-center" style={{ width: 250 }}>
              Vendor
            </Th>
            <Th className="p-2 text-center">Jumlah</Th>
            <Th className="p-2 text-center" style={{ minWidth: 200 }}>
              Akun COA
            </Th>
            <Th className="p-2 text-center">Keterangan Jurnal</Th>
            <ThFixed className="p-0 text-center">Aksi</ThFixed>
          </tr>
        </thead>
        {type === 'update' ? TableBodyUpdate() : TableBodyDetail()}
      </Table>

      {modalConfig.show && type === 'update' && (
        <ModalJurnalBDP
          type={type}
          dataList={dataList}
          setDataList={setDataList}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}

      {modalConfig.show && type === 'detail' && (
        <ModalJurnalBDPDetail
          isHistory
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </>
  );
};

export default TableHistorySubkon;
