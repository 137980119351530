import Services from 'services';

class TransferProduksiOverheadApi {
  page(params) {
    return Services.get('/transfer_produksi_overhead/page', {
      params,
    });
  }

  preform(params) {
    return Services.get('/transfer_produksi_overhead/permintaan_page', {
      params,
    });
  }

  single(params) {
    return Services.get('/transfer_produksi_overhead/single_permintaan', {
      params,
    });
  }

  updateStatusPermintaan(data) {
    return Services.post('/transfer_produksi_overhead/status_permintaan', data);
  }

  transfer(params) {
    return Services.get('/transfer_produksi_overhead/transfer', {
      params,
    });
  }

  no_baru(params) {
    return Services.get('/transfer_produksi_overhead/no_baru', {
      params,
    });
  }

  create(value) {
    return Services.post('/transfer_produksi_overhead', value);
  }

  delete(value) {
    return Services.post('/transfer_produksi_overhead/delete', value);
  }

  dropdownOverhead(params) {
    return Services.get('/transfer_produksi_overhead/dropdown', {
      params,
    });
  }

  dropdown(params) {
    return Services.get('/dropdown/dropdown', {
      params,
    });
  }
}

export default new TransferProduksiOverheadApi();
