// Dashboard
import Dashboard from 'pages/Dashboard';

// MASTER
import {
  ItemOverhead,
  KelompokOverhead,
  HargaPerkiraanSendiri,
} from 'pages/Master';

// Transaksi
import {
  // Transfer Produksi Overhead
  TransferProduksiOverheadList,
  TransferProduksiOverheadPreform,
  TambahTransferOverheadProduksi,

  // HPP
  ListHPP,
  ListPreformHPP,
  TambahHPP,
  UbahHPP,
  DetailHPP,
  DetailPreformHPP,

  // Harga Satuan Rata-Rata
  HargaSatuanRataRataPenyusutan,
  TambahHargaSatuanRataPenyusutan,
  HargaSatuanRataRataPakai,
  TambahHargaSatuanRataRataPakai,
  HargaSatuanRataRataOverhead,
  TambahHargaSatuanRataOverhead,

  // Overhead Prelim
  ListOverheadPrelim,
  ListPermintaanPrelim,
  RegisterOverheadPrelim,
  DetailSPKSection,

  // Posting Biaya Prelim
  ListPostingBiayaPrelim,
  ListRealisasiPrelim,
  PostingBiayaPrelim,

  // BDP Produksi
  ListBDPProduksi,
  ListRealisasiProduksiBDP,
  DataBDPProduksi,
  DetailPostingBiayaPrelim,

  // Posting Petty Cash
  PostingPettyCashList,
  PostingRealisasiPettyCashList,
  PostingPettyCashTambah,
} from 'pages/Transaksi';

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
  // Dashboard
  {
    exact: true,
    path: '/',
    page: Dashboard,
    hak: ['SUPA', 'COC'],
  },

  // MASTER
  {
    exact: true,
    path: '/master/item-overhead',
    page: ItemOverhead,
    hak: ['SUPA', 'COC_REG_OVH'],
  },
  {
    exact: true,
    path: '/master/kelompok-overhead',
    page: KelompokOverhead,
    hak: ['SUPA', 'COC_MAS_KOH'],
  },
  {
    text: 'Harga Perkiraan Sendiri',
    path: '/master/harga-perkiraan-sendiri',
    page: HargaPerkiraanSendiri,
    hak: ['SUPA', 'COC_MAS_HPS'],
  },

  // TRANSAKSI
  // Transfer Produksi Overhead
  {
    exact: true,
    path: '/transaksi/transfer-produksi-overhead',
    page: TransferProduksiOverheadList,
    hak: ['SUPA', 'COC_TRN_TPO'],
  },
  {
    exact: true,
    path: '/transaksi/transfer-produksi-overhead/list-permintaan-produksi',
    page: TransferProduksiOverheadPreform,
    hak: ['SUPA', 'COC_TRN_TPO'],
  },
  {
    exact: true,
    path: '/transaksi/transfer-produksi-overhead/tambah/:id_permintaan_produksi',
    page: TambahTransferOverheadProduksi,
    hak: ['SUPA', 'COC_TRN_TPO'],
  },

  // HPP
  {
    exact: true,
    path: '/transaksi/hpp',
    page: ListHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },
  {
    exact: true,
    path: '/transaksi/hpp/list-job-order',
    page: ListPreformHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },
  {
    exact: true,
    path: '/transaksi/hpp/list-job-order/detail/:id_job_order',
    page: DetailPreformHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },
  {
    exact: true,
    path: '/transaksi/hpp/tambah/:id_job_order',
    page: TambahHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },
  {
    exact: true,
    path: '/transaksi/hpp/ubah/:id_hpp',
    page: UbahHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },
  {
    exact: true,
    path: '/transaksi/hpp/detail/:id_hpp',
    page: DetailHPP,
    hak: ['SUPA', 'COC_TRN_HPP'],
  },

  // Harga Satuan Rata-Rata
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-penyusutan',
    page: HargaSatuanRataRataPenyusutan,
    hak: ['SUPA', 'COC_TRN_HSP'],
  },
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-penyusutan/tambah',
    page: TambahHargaSatuanRataPenyusutan,
    hak: ['SUPA', 'COC_TRN_HSP'],
  },
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-bahan',
    page: HargaSatuanRataRataPakai,
    hak: ['SUPA', 'COC_TRN_HSR'],
  },
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-bahan/tambah',
    page: TambahHargaSatuanRataRataPakai,
    hak: ['SUPA', 'COC_TRN_HSR'],
  },
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-overhead',
    page: HargaSatuanRataRataOverhead,
    hak: ['SUPA', 'COC_TRN_HSO'],
  },
  {
    exact: true,
    path: '/transaksi/harga-satuan-rata-rata-overhead/tambah',
    page: TambahHargaSatuanRataOverhead,
    hak: ['SUPA', 'COC_TRN_HSO'],
  },
  // Overhead Prelim
  {
    exact: true,
    path: '/transaksi/overhead-prelim',
    page: ListOverheadPrelim,
    hak: ['SUPA', 'COC_TRN_OHP'],
  },
  {
    exact: true,
    path: '/transaksi/overhead-prelim/list-permintaan-prelim',
    page: ListPermintaanPrelim,
    hak: ['SUPA', 'COC_TRN_OHP'],
  },
  {
    exact: true,
    path: '/transaksi/overhead-prelim/list-permintaan-prelim/register-overhead-prelim/detail-spk/:id',
    page: DetailSPKSection,
    hak: ['SUPA', 'COC_TRN_OHP'],
  },
  {
    exact: true,
    path: '/transaksi/overhead-prelim/list-permintaan-prelim/register-overhead-prelim/:id',
    page: RegisterOverheadPrelim,
    hak: ['SUPA', 'COC_TRN_OHP'],
  },

  // Posting Biaya Prelim
  {
    exact: true,
    path: '/transaksi/posting-biaya-prelim',
    page: ListPostingBiayaPrelim,
    hak: ['SUPA', 'COC_TRN_PBR'],
  },
  {
    exact: true,
    path: '/transaksi/posting-biaya-prelim/list-realisasi-prelim',
    page: ListRealisasiPrelim,
    hak: ['SUPA', 'COC_TRN_PBR'],
  },
  {
    exact: true,
    path: '/transaksi/posting-biaya-prelim/posting/:id',
    page: PostingBiayaPrelim,
    hak: ['SUPA', 'COC_TRN_PBR'],
  },
  {
    exact: true,
    path: '/transaksi/posting-biaya-prelim/posting/detail-spk/:id',
    page: DetailSPKSection,
    hak: ['SUPA', 'COC_TRN_PBR'],
  },
  {
    exact: true,
    path: '/transaksi/posting-biaya-prelim/detail/:id',
    page: DetailPostingBiayaPrelim,
    hak: ['SUPA', 'COC_TRN_PBR'],
  },

  // BDP Produksi
  {
    exact: true,
    path: '/transaksi/bdp-produksi/',
    page: ListBDPProduksi,
    hak: ['SUPA', 'COC_TRN_BDP'],
  },
  {
    exact: true,
    path: '/transaksi/bdp-produksi/list-realisasi-produksi',
    page: ListRealisasiProduksiBDP,
    hak: ['SUPA', 'COC_TRN_BDP'],
  },
  {
    exact: true,
    path: '/transaksi/bdp-produksi/data/:id_job_order',
    page: DataBDPProduksi,
    hak: ['SUPA', 'COC_TRN_BDP'],
  },

  // Posting Petty Cash
  {
    exact: true,
    path: '/transaksi/posting-petty-cash',
    page: PostingPettyCashList,
    hak: ['SUPA', 'COC_TRN_PPC'],
  },
  {
    exact: true,
    path: '/transaksi/posting-petty-cash/realisasi-petty-cash',
    page: PostingRealisasiPettyCashList,
    hak: ['SUPA', 'COC_TRN_PPC'],
  },
  {
    exact: true,
    path: '/transaksi/posting-petty-cash/tambah/:id',
    page: PostingPettyCashTambah,
    hak: ['SUPA', 'COC_TRN_PPC'],
  },
];
