import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DataStatus } from 'components';
import Axios from 'axios';
import { BDPSubkonApi } from 'api';
import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { hasNotifCheck } from 'pages/Transaksi/BDPProduksi/Helpers';
import TableRealisasiSubkon from './comps/TableRealisasiSubkon';
import TableHistorySubkon from './comps/TableHistorySubkon';

const SubkonFinishingSection = ({ subkonFin, setSubkonFin }) => {
  const location = useLocation();
  const { id_realisasi_produksi: id_realisasi_produksi_master } =
    location.state;
  const { id_job_order } = useParams();
  const { setNotif } = useContext(NotifBDPContext);
  const [historySubkonFin, setHistorySubkonFin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = () => {
    setIsLoading(true);
    Axios.all([
      BDPSubkonApi.getSingle({
        id_job_order,
        id_realisasi_produksi_master,
        ref: 'RSF',
      }),
      BDPSubkonApi.getSingleHistory({
        id_job_order,
        id_realisasi_produksi_master,
      }),
    ])
      .then(
        Axios.spread((res, resHistory) => {
          const data = res?.data?.data ?? [];
          const history = resHistory?.data?.data?.map((item) => ({
            ...item,
            nama_coa_debet: `${item.nomor_akun_debet} - ${item.nama_akun_debet}`,
            nama_coa_kredit: `${item.nomor_akun_kredit} - ${item.nama_akun_kredit}`,
          }));

          setSubkonFin(data);
          setHistorySubkonFin(history);

          const hasNotif = hasNotifCheck(data, history);
          setNotif((prev) => ({
            ...prev,
            bdp_subkon: {
              status: hasNotif,
              subkon_finishing: hasNotif,
            },
          }));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getInitialData(), []);

  if (isLoading) {
    return <DataStatus loading={isLoading} text="Memuat Data . . ." />;
  }

  return (
    <div className="px-2 py-3">
      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        List Realisasi Labour Cost Finishing
      </small>
      <TableRealisasiSubkon
        dataList={subkonFin}
        dataHistory={historySubkonFin}
        getInitialData={getInitialData}
        refKey="RSF"
      />

      <small className="font-weight-bold mb-1" style={{ fontSize: 14 }}>
        History BDP
      </small>
      <TableHistorySubkon
        type="detail"
        dataHistory={historySubkonFin}
        refKey="RSF"
      />
    </div>
  );
};

export default SubkonFinishingSection;
