import { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Row, Col, Card, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import Axios from 'axios';
import { Formik } from 'formik';
import {
  DataStatus,
  BackButton,
  ActionButton,
  Input,
  TextArea,
} from 'components';
import { DateConvert } from 'utilities';
import { OverheadPrelimApi } from 'api';
import TableHistory from './TableHistory';
import { RupiahConvert } from 'utilities';

const RegisterOverheadPrelim = ({ setNavbarTitle }) => {
  const History = useHistory();
  const dateNow = new Date().toISOString().slice(0, 10);
  const { id } = useParams();
  const [dataPermintaanPrelim, setDataPermintaanPrelim] = useState([]);
  const [dataHistoryOverhead, setDataHistoryOverhead] = useState([]);
  const [noPrelim, setNoPrelim] = useState('');

  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [showAlert, setShowAlert] = useState(false);

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([
      OverheadPrelimApi.getSinglePermintaan({ id_permintaan_prelim: id }),
      OverheadPrelimApi.getNomor({ tanggal: dateNow }),
    ])
      .then(
        Axios.spread((data, no) => {
          const dataOverhead = data.data.data.permintaan;
          const dataHistory = data.data.data.histori;
          const dataNo = no.data.data;

          setDataPermintaanPrelim(dataOverhead ?? []);
          setDataHistoryOverhead(dataHistory ?? []);
          setNoPrelim(dataNo);

          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const getNomor = ({ setFieldValue, tgl, values }) => {
    OverheadPrelimApi.getNomor({ tanggal: tgl })
      .then((res) => {
        setFieldValue('no_overhead_prelim', res.data.data);
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    setNavbarTitle('TAMBAH DATA OVERHEAD PRELIM');
    getInitialData();
  }, []);

  const InfoSection = () => {
    const InfoItems = ({ label, value, textStyle, link }) => (
      <tr style={{ fontSize: 14 }}>
        <td>{label}</td>
        <td className="pl-3 pr-2">:</td>
        <td>
          <span
            onClick={() =>
              link
                ? History.push(
                    '/transaksi/overhead-prelim/list-permintaan-prelim/register-overhead-prelim/detail-spk/' +
                      dataPermintaanPrelim.id_spk
                  )
                : ''
            }
            style={textStyle}
          >
            {value}
          </span>
        </td>
      </tr>
    );
    return (
      <div>
        <Row>
          <Col>
            <InfoItems
              label="Tgl. Permintaan Prelim"
              value={
                DateConvert(
                  new Date(dataPermintaanPrelim.tgl_permintaan_prelim)
                ).detail ?? '-'
              }
            />
            <InfoItems
              label="No. Permintaan Prelim"
              value={dataPermintaanPrelim.no_permintaan_prelim ?? '-'}
            />

            <InfoItems
              label="No. SPK"
              textStyle={{
                color: 'rgb(119,168,248)',
                cursor: 'pointer',
                fontSize: 14,
              }}
              value={dataPermintaanPrelim.no_spk ?? '-'}
              link
            />

            <InfoItems
              label="Proyek"
              value={dataPermintaanPrelim.nama_proyek ?? '-'}
            />
          </Col>
          <Col>
            <InfoItems
              label="Item Permintaan"
              value={dataPermintaanPrelim.nama_item_prelim ?? '-'}
            />
            <InfoItems
              label="Qty. Permintaan"
              value={
                dataPermintaanPrelim.qty_permintaan +
                  ' ' +
                  dataPermintaanPrelim.nama_satuan_permintaan ?? '-'
              }
            />
            <InfoItems
              label="Qty. Telah Di Overhead"
              value={dataPermintaanPrelim.qty_teroverhead ?? '-'}
            />
            <InfoItems
              label="Durasi Prelim"
              value={dataPermintaanPrelim.qty_durasi + ' Hari' ?? '-'}
            />
          </Col>
        </Row>
      </div>
    );
  };

  console.log(dataPermintaanPrelim);

  const formInitialValues = {
    id_permintaan_prelim: id,
    tgl_overhead_prelim: dateNow,
    id_item_prelim: dataPermintaanPrelim.id_item_buaso_prelim,
    qty_overhead:
      parseFloat(dataPermintaanPrelim.qty_permintaan ?? 0) -
        parseFloat(dataPermintaanPrelim.qty_teroverhead ?? 0) <
      0
        ? 0
        : parseFloat(dataPermintaanPrelim.qty_permintaan ?? 0) -
          parseFloat(dataPermintaanPrelim.qty_teroverhead ?? 0),
    harga_satuan: dataPermintaanPrelim.harga_satuan_prelim,
    qty_durasi_prelim: dataPermintaanPrelim.qty_durasi,
    id_satuan_prelim: dataPermintaanPrelim.id_satuan_permintaan,
    catatan: '',
    no_overhead_prelim: noPrelim,
  };

  const formValidationSchema = Yup.object().shape({
    qty_overhead: Yup.string()
      .test('checkQty', 'Qty. Overhead Melebihi Qty. Permintaan', (value) =>
        parseFloat(value) > parseFloat(dataPermintaanPrelim.qty_permintaan)
          ? false
          : true
      )
      .required('Masukkan Qty'),
    harga_satuan: Yup.string().required('Masukkan Harga Satuan'),
  });

  const formSubmitHandler = (values) => {
    console.log('simpan', values);
    OverheadPrelimApi.save(values)
      .then(() => {
        History.push('/transaksi/overhead-prelim', {
          registerAlertConfig: {
            variant: 'primary',
            text: 'Tambah data berhasil!',
            show: true,
          },
        });
      })
      .catch((err) => {
        // ERROR ALERT
        setAlertConfig({
          variant: 'danger',
          text: `Tambah data gagal! (${err.response.data.message})`,
        });
      })
      .finally(() => {
        // SHOW ALERT
        setShowAlert(true);
      });
  };

  return (
    <>
      <Card className="mb-4">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Permintaan Prelim</b>
          <BackButton onClick={() => History.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            show={showAlert}
            showCloseButton={true}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setShowAlert(false)}
          />
          {fetchingStatus.loading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : fetchingStatus.success ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                handleChange,
                handleSubmit,
                setFieldValue,
                setValues,
              }) => (
                <>
                  <InfoSection />
                  <hr />
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            {console.log(values)}
                            <Input
                              label="Tgl. Overhead Prelim"
                              type="date"
                              name="tgl_overhead_prelim"
                              value={values.tgl_overhead_prelim ?? ''}
                              onChange={(e) => {
                                const tgl = e.target.value;
                                // setValues({ ...values, tgl_overhead_prelim: e.target.value });
                                setFieldValue('tgl_overhead_prelim', tgl);
                                getNomor({ setFieldValue, tgl, values });
                              }}
                            />
                          </Col>
                          <Col>
                            <Input
                              label="No. Overhead Prelim"
                              type="text"
                              name="no_overhead_prelim"
                              value={values.no_overhead_prelim ?? ''}
                              readOnly={true}
                              onChange={handleChange}
                            />
                          </Col>
                        </Row>
                        <TextArea
                          label="Catatan"
                          name="catatan"
                          rows={7}
                          onChange={(e) => {
                            setValues({ ...values, catatan: e.target.value });
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          label="Item Prelim"
                          type="text"
                          placeholder="Kode Overhead"
                          value={dataPermintaanPrelim.nama_item_prelim ?? '-'}
                          readOnly={true}
                        />
                        <Row>
                          <Col>
                            <Input
                              label="Qty. Overhead"
                              type="text"
                              placeholder="Masukan Qty. Overhead"
                              value={values.qty_overhead ?? '-'}
                              onChange={(e) => {
                                setValues({
                                  ...values,
                                  qty_overhead: e.target.value,
                                });
                              }}
                              error={
                                errors.qty_overhead &&
                                touched.qty_overhead &&
                                true
                              }
                              errorText={errors.qty_overhead}
                            />
                            <Input
                              label="Harga Satuan Overhead"
                              type="text"
                              value={
                                RupiahConvert(
                                  parseInt(values.harga_satuan).toString()
                                ).detail ?? RupiahConvert(String(0)).detail
                              }
                              onChange={(event) => {
                                const { value } = event.target;
                                const onlyNumber = value.replace(/[^\d]/g, '');
                                event.target.value =
                                  RupiahConvert(onlyNumber).detail;
                                setValues({
                                  ...values,
                                  harga_satuan: onlyNumber,
                                });
                              }}
                              // error={errors.nama_item && touched.nama_item && true}
                              // errorText={errors.nama_item}
                            />
                          </Col>
                          <Col>
                            <Input
                              label="Satuan Overhead"
                              type="text"
                              value={
                                dataPermintaanPrelim.nama_satuan_permintaan ??
                                '-'
                              }
                              readOnly={true}
                            />
                            <Input
                              label="Durasi Prelim"
                              type="text"
                              value={
                                parseInt(dataPermintaanPrelim.qty_durasi) +
                                  ' Hari' ?? '-'
                              }
                              readOnly={true}
                            />
                          </Col>
                        </Row>
                        <Input
                          label="Jumlah"
                          type="text"
                          value={
                            RupiahConvert(
                              (
                                parseFloat(values.qty_overhead ?? 0) *
                                parseFloat(values.harga_satuan ?? 0)
                              ).toString()
                            ).detail
                          }
                          readOnly={true}
                          // onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <div className="mb-4"></div>
                    <TableHistory dataHistory={dataHistoryOverhead} />
                    <div className="d-flex justify-content-end">
                      <ActionButton
                        type="submit"
                        variant="primary"
                        text="Simpan"
                        className="mt-2 px-4"
                        loading={isSubmitting}
                      />
                    </div>
                  </form>
                </>
              )}
            </Formik>
          ) : (
            <DataStatus text="Data gagal dimuat!" />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default RegisterOverheadPrelim;
