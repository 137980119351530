import { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  InfoItemHorizontal,
  SelectSearch,
  Input,
  TextArea,
  DatePicker,
  ActionButton,
  DataStatus,
  InfoItemVertical,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { PostingBiayaPrelimApi } from 'api';

const ModalPosting = ({
  id: ID,
  idHistory: IDHISTORY,
  type: TYPE,
  modalType: MODALTYPE,
  show,
  onHide,
  setModalConfirmConfig,
}) => {
  const [dataModal, setDataModal] = useState({});
  const [dataCOA, setDataCOA] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });

  // Mapping data COA agar dapat digunakan pada select
  const mappingDataCOA = (data) =>
    data.map((res) => {
      return {
        label: `${res.nomor_akun ? `${res.nomor_akun} - ` : ''} ${
          res.nama_akun ?? ''
        }`,
        value: res.nomor_akun,
      };
    });

  // Menghasilkan type untuk params pada form & post data
  const generateRefOnPost = () => {
    if (TYPE === 'HONOR') return 'rkp';
    if (TYPE === 'LAINLAIN') return 'rop';

    return 'rtp';
  };

  // Menghasilkan params pada request data
  const generateParamseOnRequest = () => {
    if (TYPE === 'HONOR')
      return {
        id_realisasi_kontrak_prelim: ID,
        ref_realisasi: generateRefOnPost(),
      };
    if (TYPE === 'LAINLAIN')
      return {
        id_realisasi_overhead_prelim: ID,
        ref_realisasi: generateRefOnPost(),
      };

    return {
      id_realisasi_transfer_prelim: ID,
      ref_realisasi: generateRefOnPost(),
    };
  };

  // Fetch data pada saat modal dimuat
  const fetchDataHandler = async () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    // Fetching pada status modal UPDATE
    if (MODALTYPE === 'FORM')
      return await Axios.all([
        PostingBiayaPrelimApi.single(generateParamseOnRequest()),
        PostingBiayaPrelimApi.coa(),
      ])
        .then(
          Axios.spread((single, coa) => {
            const dataInfo = single.data.data.realisasi;
            const dataCOA = coa.data.data;

            setDataModal(dataInfo ?? {});
            setDataCOA(mappingDataCOA(dataCOA ?? []));
            setFetchingStatus({
              loading: false,
              success: true,
            });
          })
        )
        .catch(() => {
          setFetchingStatus({
            loading: false,
            success: false,
          });
        });

    // Fetching pada status modal DETAIL
    if (MODALTYPE === 'DETAIL')
      return await Axios.all([
        PostingBiayaPrelimApi.single(generateParamseOnRequest()),
        PostingBiayaPrelimApi.historySingle({
          id_posting_biaya_prelim: IDHISTORY,
        }),
      ])
        .then(
          Axios.spread((single, history) => {
            const dataInfo = single.data.data.realisasi;
            const dataHistory = history.data.data;

            setDataModal(dataInfo ?? {});
            setDataDetail(dataHistory ?? {});
            setFetchingStatus({
              loading: false,
              success: true,
            });
          })
        )
        .catch(() => {
          setFetchingStatus({
            loading: false,
            success: false,
          });
        });
  };

  // Mendapatkan data id realisasi pada form
  const getIdRealisasiForm = () => {
    if (TYPE === 'HONOR') return dataModal.id_realisasi_kontrak_prelim;
    if (TYPE === 'LAINLAIN') return dataModal.id_realisasi_overhead_prelim;

    return dataModal.id_realisasi_transfer_prelim;
  };

  const formInitialValues = {
    tgl_posting_biaya_prelim: '',
    no_posting_biaya_prelim: '',
    id_realisasi: getIdRealisasiForm(),
    ref_realisasi: generateRefOnPost(),
    jumlah_harga:
      parseInt(dataModal?.harga_satuan || 0) *
      parseFloat(dataModal?.qty_realisasi || 0),
    nomor_akun_debet: '',
    nomor_akun_kredit: '',
    keterangan_posting: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_posting_biaya_prelim: Yup.string().required('Pilih tanggal posting'),
    no_posting_biaya_prelim: Yup.string().required(
      'Pilih tanggal untuk menentukan nomor'
    ),
    nomor_akun_debet: Yup.string().required('Pilih COA debet'),
    nomor_akun_kredit: Yup.string().required('Pilih COA kredit'),
    keterangan_posting: Yup.string().required('Keterangan tidak boleh kosong'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    console.log({ values });
    const submitHandler = () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            setModalConfirmConfig({
              show: true,
              data: values,
            })
          );
        }, 200);
      });

    submitHandler().finally(() => {
      setSubmitting(false);
      onHide();
    });
  };

  useEffect(() => {
    show && fetchDataHandler();
  }, [show]);

  const ModalInfo = memo(() => {
    if (TYPE === 'HONOR')
      return (
        <>
          <InfoItemHorizontal
            label="Tgl. Realisasi Prelim"
            text={
              dataModal.tgl_realisasi_kontrak_prelim
                ? DateConvert(new Date(dataModal.tgl_realisasi_kontrak_prelim))
                    .detail
                : '-'
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="No. Realisasi Prelim"
            text={dataModal.no_realisasi_kontrak_prelim ?? '-'}
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Item Prelim"
            text={dataModal.nama_item_prelim ?? '-'}
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Qty. Realisasi Prelim"
            text={
              dataModal.qty_realisasi
                ? `${parseFloat(dataModal.qty_realisasi)} ${
                    dataModal.kode_satuan_realisasi ?? ''
                  }`
                : '-'
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Vendor"
            text={dataModal.nama_vendor ?? ''}
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Harga Satuan Kontrak"
            text={
              dataModal.harga_satuan
                ? RupiahConvert(String(parseFloat(dataModal.harga_satuan)))
                    .detail
                : RupiahConvert(String(0)).detail
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Durasi Realisasi"
            text={
              dataModal.durasi_realisasi
                ? `${parseFloat(dataModal.durasi_realisasi)} ${
                    dataModal.kode_satuan_waktu ?? ''
                  }`
                : '-'
            }
            width={155}
            className="mb-1"
          />
        </>
      );

    if (TYPE === 'LAINLAIN')
      return (
        <>
          <InfoItemHorizontal
            label="Tgl. Realisasi Prelim"
            text={
              dataModal.tgl_realisasi_overhead_prelim
                ? DateConvert(new Date(dataModal.tgl_realisasi_overhead_prelim))
                    .detail
                : '-'
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="No. Realisasi Prelim"
            text={dataModal.no_realisasi_overhead_prelim ?? '-'}
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Item Prelim"
            text={dataModal.nama_item_prelim ?? '-'}
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Qty. Realisasi Prelim"
            text={
              dataModal.qty_realisasi
                ? `${parseFloat(dataModal.qty_realisasi)} ${
                    dataModal.kode_satuan_realisasi ?? ''
                  }`
                : '-'
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Harga Satuan Overhead"
            text={
              dataModal.harga_satuan
                ? RupiahConvert(String(parseFloat(dataModal.harga_satuan)))
                    .detail
                : RupiahConvert(String(0)).detail
            }
            width={155}
            className="mb-1"
          />
          <InfoItemHorizontal
            label="Durasi Realisasi"
            text={
              dataModal.durasi_realisasi
                ? `${parseFloat(dataModal.durasi_realisasi)} ${
                    dataModal.kode_satuan_waktu ?? ''
                  }`
                : '-'
            }
            width={155}
            className="mb-1"
          />
        </>
      );

    return (
      <>
        <InfoItemHorizontal
          label="Tgl. Realisasi Prelim"
          text={
            dataModal.tgl_realisasi_transfer_prelim
              ? DateConvert(new Date(dataModal.tgl_realisasi_transfer_prelim))
                  .detail
              : '-'
          }
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="No. Realisasi Prelim"
          text={dataModal.no_realisasi_transfer_prelim ?? '-'}
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Item Prelim"
          text={dataModal.nama_item_prelim ?? '-'}
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Qty. Realisasi Prelim"
          text={
            dataModal.qty_realisasi
              ? `${parseFloat(dataModal.qty_realisasi)} ${
                  dataModal.kode_satuan_realisasi ?? ''
                }`
              : '-'
          }
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Harga Satuan Prelim"
          text={
            dataModal.harga_satuan
              ? RupiahConvert(String(parseFloat(dataModal.harga_satuan))).detail
              : RupiahConvert(String(0)).detail
          }
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Gudang Asal"
          text={dataModal.nama_gudang_asal ?? '-'}
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Gudang Tujuan"
          text={dataModal.nama_gudang_tujuan ?? '-'}
          width={155}
          className="mb-1"
        />
      </>
    );
  });

  const ModalForm = ({ formik }) => {
    const [isNomorLoading, setIsNomorLoading] = useState(false);
    const {
      values,
      errors,
      touched,
      setFieldValue,
      setValues,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = formik;

    // Fetch nomor pada saat tanggal dipilih
    const fetchNomorHandler = async (date) => {
      setIsNomorLoading(true);

      return await PostingBiayaPrelimApi.nomor({ tanggal: date })
        .then((res) => {
          const nomor = res.data.data;
          setValues({
            ...values,
            tgl_posting_biaya_prelim: date,
            no_posting_biaya_prelim: nomor,
          });
        })
        .catch(() => {
          setValues({
            ...values,
            tgl_posting_biaya_prelim: '',
            no_posting_biaya_prelim: '',
          });
        })
        .finally(() => {
          setIsNomorLoading(false);
        });
    };

    useEffect(() => {
      fetchNomorHandler(DateConvert(new Date()).default);
    }, []);

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <ModalInfo />
          <hr />
          <Row>
            {/* Tanggal Posting */}
            <Col>
              <DatePicker
                label="Tgl. Posting Biaya Prelim"
                placeholderText="Pilih tanggal"
                selected={
                  values.tgl_posting_biaya_prelim
                    ? new Date(values.tgl_posting_biaya_prelim)
                    : ''
                }
                onChange={(date) =>
                  fetchNomorHandler(DateConvert(date).default)
                }
                error={Boolean(
                  errors.tgl_posting_biaya_prelim &&
                    touched.tgl_posting_biaya_prelim
                )}
                errorText={
                  Boolean(
                    errors.tgl_posting_biaya_prelim &&
                      touched.tgl_posting_biaya_prelim
                  ) && errors.tgl_posting_biaya_prelim
                }
              />
            </Col>

            {/* No Posting */}
            <Col>
              <Input
                readOnly
                label="No. Posting Biaya Prelim"
                placeholder="Pilih tgl. untuk menentukan nomor"
                value={
                  isNomorLoading
                    ? 'Memuat data . . .'
                    : values.no_posting_biaya_prelim
                }
                error={Boolean(
                  errors.no_posting_biaya_prelim &&
                    touched.no_posting_biaya_prelim
                )}
                errorText={
                  Boolean(
                    errors.no_posting_biaya_prelim &&
                      touched.no_posting_biaya_prelim
                  ) && errors.no_posting_biaya_prelim
                }
              />
            </Col>
          </Row>

          {/* Jumlah */}
          <Input
            readOnly
            label="Jumlah"
            placeholder="Masukan jumlah"
            value={
              RupiahConvert(String(parseInt(values.jumlah_harga || 0))).detail
            }
          />

          {/* COA Debet */}
          <SelectSearch
            label="COA Debet"
            placeholder="Pilih COA debet"
            option={dataCOA}
            defaultValue={
              values.nomor_akun_debet
                ? dataCOA.find((val) => values.nomor_akun_debet === val.values)
                : ''
            }
            onChange={(val) => setFieldValue('nomor_akun_debet', val.value)}
            error={Boolean(errors.nomor_akun_debet && touched.nomor_akun_debet)}
            errorText={
              Boolean(errors.nomor_akun_debet && touched.nomor_akun_debet) &&
              errors.nomor_akun_debet
            }
          />

          {/* COA Kredit */}
          <SelectSearch
            label="COA Kredit"
            placeholder="Pilih COA kredit"
            option={dataCOA}
            defaultValue={
              values.nomor_akun_kredit
                ? dataCOA.find((val) => values.nomor_akun_kredit === val.values)
                : ''
            }
            onChange={(val) => setFieldValue('nomor_akun_kredit', val.value)}
            error={Boolean(
              errors.nomor_akun_kredit && touched.nomor_akun_kredit
            )}
            errorText={
              Boolean(errors.nomor_akun_kredit && touched.nomor_akun_kredit) &&
              errors.nomor_akun_kredit
            }
          />

          {/* Keterangan */}
          <TextArea
            label="Keterangan Posting"
            placeholder="Masukan keterangan"
            name="keterangan_posting"
            onChange={handleChange}
            error={Boolean(
              errors.keterangan_posting && touched.keterangan_posting
            )}
            errorText={
              Boolean(
                errors.keterangan_posting && touched.keterangan_posting
              ) && errors.keterangan_posting
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            type="submit"
            text="Posting"
            className="px-4"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    );
  };

  const ModalDetail = () => {
    return (
      <Modal.Body>
        <ModalInfo />
        <hr />
        <InfoItemHorizontal
          label="Tgl. Posting Biaya Prelim"
          text={
            dataDetail.tgl_posting_biaya_prelim
              ? DateConvert(new Date(dataDetail.tgl_posting_biaya_prelim))
                  .detail
              : '-'
          }
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="No. Posting Biaya Prelim"
          text={dataDetail.no_posting_biaya_prelim ?? '-'}
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="Harga Satuan Kontrak"
          text={
            dataDetail.harga_satuan
              ? RupiahConvert(String(parseFloat(dataDetail.harga_satuan)))
                  .detail
              : RupiahConvert(String(0)).detail
          }
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="COA Debet"
          text={`${
            dataDetail.nomor_akun_debet
              ? `${dataDetail.nomor_akun_debet} - `
              : ''
          } ${dataDetail.nama_akun_debit ?? ''}`}
          width={155}
          className="mb-1"
        />
        <InfoItemHorizontal
          label="COA Kredit"
          text={`${
            dataDetail.nomor_akun_kredit
              ? `${dataDetail.nomor_akun_kredit} - `
              : ''
          } ${dataDetail.nama_akun_kredit ?? ''}`}
          width={155}
          className="mb-1"
        />
        <hr />
        <InfoItemVertical
          label="Keterangan Posting"
          text={dataDetail.keterangan_posting ?? '-'}
        />
      </Modal.Body>
    );
  };

  // Tampilan pada saat loading atau data gagal dimuat
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <b>Posting Biaya Prelim</b>
        </Modal.Header>
        <Modal.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b>Posting Biaya Prelim</b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => (
          <>
            {MODALTYPE === 'FORM' ? (
              <ModalForm formik={formik} />
            ) : (
              <ModalDetail />
            )}
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalPosting;
