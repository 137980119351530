import { NotifBDPContext } from 'pages/Transaksi/BDPProduksi/Context/NotifContext';
import { useContext } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import MachineProcessSection from './MachineProcessSection';
import { BadgeNotif } from 'pages/Transaksi/BDPProduksi/Components';

const TabAlatMesin = ({ machineProcess, setMachineProcess }) => {
  const { notif } = useContext(NotifBDPContext);

  return (
    <Card className="border">
      <Tab.Container defaultActiveKey="mp">
        <Card.Header className='className="d-flex justify-content-between align-items-center'>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="mp">
                Machine Process
                <BadgeNotif show={notif.bdp_alat_mesin.mp} />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="mp">
            <MachineProcessSection
              machineProcess={machineProcess}
              setMachineProcess={setMachineProcess}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};

export default TabAlatMesin;
