import React, { useState, useEffect } from 'react';
import { Table, Th, Td } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

const TableHistory = ({ dataHistory }) => {
  const dataTable = dataHistory ?? [];

  const total = () => {
    let jumlah = 0;
    dataTable.map((val) => {
      jumlah = parseFloat(val.qty_durasi_prelim) * val.harga_satuan;
    });
    return jumlah;
  };
  const TableData = () => (
    <Table>
      <thead>
        <tr className="text-center">
          <Th>No.</Th>
          <Th>Tgl. Overhead Prelim</Th>
          <Th>No. Overhead Prelim</Th>
          <Th>Item Prelim</Th>
          <Th>Qty. Overhead Prelim</Th>
          <Th>Harga Satuan Overhead</Th>
          <Th>Durasi Prelim</Th>
          <Th>Jumlah</Th>
        </tr>
      </thead>
      <tbody>
        {dataTable.map((val, index) => {
          return (
            <tr key={val.id_item_buaso}>
              <Td className="text-center" width="20px">
                {index + 1}
              </Td>
              <Td>
                {DateConvert(new Date(val.tgl_overhead_prelim)).defaultDMY ??
                  ''}
              </Td>
              <Td>{val.no_overhead_prelim ?? ''}</Td>
              <Td>{val.nama_item ?? ''}</Td>
              <Td>{val.qty_overhead ?? ''}</Td>
              <Td>
                {RupiahConvert(parseInt(val.harga_satuan).toString()).detail ??
                  ''}
              </Td>
              <Td>{val.qty_durasi_prelim ?? ''}</Td>
              <Td>{RupiahConvert(total().toString()).detail}</Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
  return (
    <div>
      <TableData />
    </div>
  );
};

export default TableHistory;
