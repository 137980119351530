import Services from 'services';

class HargaPerkiraanSendiriApi {
  page(value, params) {
    return Services.get(`/harga_perkiraan_sendiri/page_${value}`, { params });
  }

  single(value) {
    return Services.get(`/harga_perkiraan_sendiri/single?id_item=${value}`);
  }

  update(data) {
    return Services.post('/harga_perkiraan_sendiri', data);
  }
}

export default new HargaPerkiraanSendiriApi();
