import { useEffect, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  CRUDLayout,
  FilterButton,
  InputSearch,
} from 'components';
import {
  PageListBDPAlatMesin,
  PageListBDPBahan,
  PageListBDPOverhead,
  PageListBDPSisaProduksi,
  PageListBDPSubkon,
  PageListBDPUpah,
} from './Tab';
import { ModalFilterBDPProduksi } from './comps/ModalFilterBDPProduksi';
import {
  BDPAlatMesinApi,
  BDPBahanApi,
  BDPOverheadApi,
  BDPProduksiApi,
  BDPSisaProduksiApi,
  BDPSubkonApi,
  BDPUpahApi,
} from 'api';

const ListBDPProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`,
  };
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER)
    ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER))
    : {};
  const initialPagination = {
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  };
  const [paginationConfigBahan, setPaginationConfigBahan] =
    useState(initialPagination);
  const [paginationConfigUpah, setPaginationConfigUpah] =
    useState(initialPagination);
  const [paginationConfigAlat, setPaginationConfigAlat] =
    useState(initialPagination);
  const [paginationConfigSubkon, setPaginationConfigSubkon] =
    useState(initialPagination);
  const [paginationConfigOverhead, setPaginationConfigOverhead] =
    useState(initialPagination);
  const [paginationConfigSisaProduksi, setPaginationConfigSisaProduksi] =
    useState(initialPagination);

  const [isPageLoading, setIsPageLoading] = useState({
    bahan: true,
    upah: true,
    alat: true,
    subkon: true,
    overhead: true,
    sisa_produksi: true,
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataAlatMesin, setDataAlatMesin] = useState([]);
  const [dataSubkon, setDataSubkon] = useState([]);
  const [dataOverhead, setDataOverhead] = useState([]);
  const [dataSisaProduksi, setDataSisaProduksi] = useState([]);

  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    },
  });
  const [dropdown, setDropdown] = useState({
    spk: [],
    rap: [],
  });

  const fetchDropdownFilter = () => {
    axios
      .all([
        BDPProduksiApi.dropdown({ tipe: 'no_spk' }),
        BDPProduksiApi.dropdown({ tipe: 'no_rap' }),
      ])
      .then(
        axios.spread((resSPK, resRAP) => {
          const spk = resSPK.data.data
            .filter((v) => v.no_spk !== null)
            .map((v) => ({ value: v.no_spk, label: v.no_spk }));

          const rap = resRAP.data.data
            .filter((v) => v.no_rap !== null)
            .map((v) => ({ value: v.no_rap, label: v.no_rap }));

          setDropdown({ spk, rap });
        })
      );
  };

  const fetchBahan = () => {
    setIsPageLoading((prev) => ({ ...prev, bahan: true }));
    const { filter } = dataFilter;

    BDPBahanApi.page({
      q: searchConfig.key,
      page: paginationConfigBahan.page,
      per_page: paginationConfigBahan.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataBahan = data?.map((val) =>
          Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi_bahan,
            no_realisasi_produksi: val.no_realisasi_produksi_bahan,
            qty_realisasi: val.qty_realisasi_produksi_bahan,
            harga_satuan: val.harga_satuan_rata_rata_pakai,
            tgl_bdp: val.tgl_bdp_bahan,
            no_bdp: val.no_bdp_bahan,
          })
        );

        setDataBahan(dataBahan ?? []);
        setPaginationConfigBahan((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() => setIsPageLoading((prev) => ({ ...prev, bahan: false })));
  };

  const fetchUpah = () => {
    setIsPageLoading((prev) => ({ ...prev, upah: true }));
    const { filter } = dataFilter;

    BDPUpahApi.page({
      q: searchConfig.key,
      page: paginationConfigUpah.page,
      per_page: paginationConfigUpah.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataUpah = data?.map((val) =>
          Object({
            ...val,
            nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debet}`,
            nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
          })
        );

        setDataUpah(dataUpah ?? []);
        setPaginationConfigUpah((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() => setIsPageLoading((prev) => ({ ...prev, upah: false })));
  };

  const fetchAlatMesin = () => {
    setIsPageLoading((prev) => ({ ...prev, alat: true }));
    const { filter } = dataFilter;

    BDPAlatMesinApi.get({
      q: searchConfig.key,
      page: paginationConfigAlat.page,
      per_page: paginationConfigAlat.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataAlatMesin = data?.map((val) =>
          Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi_alat_mesin,
            no_realisasi_produksi: val.no_realisasi_produksi_alat_mesin,
            qty_realisasi: val.qty_realisasi_produksi_alat_mesin,
            harga_satuan: val.harga_satuan_penyusutan,
            tgl_bdp: val.tgl_bdp_alat_mesin,
            no_bdp: val.no_bdp_alat_mesin,
            nama_item: val.nama_item_aset,
          })
        );

        setDataAlatMesin(dataAlatMesin ?? []);
        setPaginationConfigAlat((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() => setIsPageLoading((prev) => ({ ...prev, alat: false })));
  };

  const fetchSubkon = () => {
    setIsPageLoading((prev) => ({ ...prev, subkon: true }));
    const { filter } = dataFilter;

    BDPSubkonApi.get({
      q: searchConfig.key,
      page: paginationConfigSubkon.page,
      per_page: paginationConfigSubkon.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataSubkon = data?.map((val) =>
          Object({
            ...val,
            nama_coa_debet: `${val.nomor_akun_debet} - ${val.nama_akun_debet}`,
            nama_coa_kredit: `${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`,
          })
        );
        setDataSubkon(dataSubkon ?? []);
        setPaginationConfigSubkon((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() => setIsPageLoading((prev) => ({ ...prev, subkon: false })));
  };

  const fetchOverhead = () => {
    setIsPageLoading((prev) => ({ ...prev, overhead: true }));
    const { filter } = dataFilter;

    BDPOverheadApi.get({
      q: searchConfig.key,
      page: paginationConfigOverhead.page,
      per_page: paginationConfigOverhead.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataOverhead = data?.map((val) =>
          Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_produksi_overhead,
            no_realisasi_produksi: val.no_realisasi_produksi_overhead,
            qty_realisasi: val.qty_realisasi_produksi_overhead,
            harga_satuan: val.harga_satuan_overhead,
            tgl_bdp: val.tgl_bdp_overhead,
            no_bdp: val.no_bdp_overhead,
          })
        );

        setDataOverhead(dataOverhead ?? []);
        setPaginationConfigOverhead((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() =>
        setIsPageLoading((prev) => ({ ...prev, overhead: false }))
      );
  };

  const fetchSisaProduksi = () => {
    setIsPageLoading((prev) => ({ ...prev, sisa_produksi: true }));
    const { filter } = dataFilter;

    BDPSisaProduksiApi.get({
      q: searchConfig.key,
      page: paginationConfigSisaProduksi.page,
      per_page: paginationConfigSisaProduksi.dataLength,
      tgl_bdp_awal: filter?.tgl_bdp_awal,
      tgl_bdp_akhir: filter?.tgl_bdp_akhir,
      no_spk: filter?.no_spk,
      no_rap: filter?.no_rap,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        const dataSisaProduksi = data?.map((val) =>
          Object({
            ...val,
            tgl_realisasi_produksi: val.tgl_realisasi_sisa_produksi,
            no_realisasi_produksi: val.no_realisasi_sisa_produksi,
            qty_realisasi: val.qty_realisasi,
            harga_satuan: val.harga_satuan_rata_rata_pakai,
            tgl_bdp: val.tgl_bdp_sisa_produksi,
            no_bdp: val.no_bdp_sisa_produksi,
          })
        );

        setDataSisaProduksi(dataSisaProduksi ?? []);
        setPaginationConfigSisaProduksi((prev) => ({
          ...prev,
          dataCount: res?.data.data_count,
          totalPage: res?.data.total_page,
        }));
      })
      .finally(() =>
        setIsPageLoading((prev) => ({ ...prev, sisa_produksi: false }))
      );
  };

  const searchHandler = (e) => {
    const key = e.target.value;

    setSearchConfig((prev) => ({ ...prev, key }));

    setPaginationConfigBahan(initialPagination);
    setPaginationConfigUpah(initialPagination);
    setPaginationConfigAlat(initialPagination);
    setPaginationConfigSubkon(initialPagination);
    setPaginationConfigOverhead(initialPagination);
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onDismissAlert = () => {
    const currentState = history.location.state;

    if (currentState && currentState.alert) {
      let state = { ...currentState };
      delete state.alert;

      history.replace(history.location.pathname, state);
      setAlertConfig({
        show: false,
        text: '',
        variant: '',
      });
    }
  };

  useEffect(() => {
    setNavbarTitle('BDP Produksi');
    fetchDropdownFilter();
    checkAlert();
  }, []);

  useEffect(
    () => fetchBahan(),
    [
      searchConfig.key,
      paginationConfigBahan.page,
      paginationConfigBahan.dataLength,
      dataFilter.filter,
    ]
  );
  useEffect(
    () => fetchUpah(),
    [
      searchConfig.key,
      paginationConfigUpah.page,
      paginationConfigUpah.dataLength,
      dataFilter.filter,
    ]
  );
  useEffect(
    () => fetchAlatMesin(),
    [
      searchConfig.key,
      paginationConfigAlat.page,
      paginationConfigAlat.dataLength,
      dataFilter.filter,
    ]
  );
  useEffect(
    () => fetchSubkon(),
    [
      searchConfig.key,
      paginationConfigSubkon.page,
      paginationConfigSubkon.dataLength,
      dataFilter.filter,
    ]
  );
  useEffect(
    () => fetchOverhead(),
    [
      searchConfig.key,
      paginationConfigOverhead.page,
      paginationConfigOverhead.dataLength,
      dataFilter.filter,
    ]
  );
  useEffect(
    () => fetchSisaProduksi(),
    [
      searchConfig.key,
      paginationConfigSisaProduksi.page,
      paginationConfigSisaProduksi.dataLength,
      dataFilter.filter,
    ]
  );

  return (
    <CRUDLayout>
      <Row className="mb-2 d-flex justify-content-end align-items-center">
        <Col
          md={5}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <InputSearch onChange={debounce(searchHandler, 1500)} />

          <FilterButton
            active={dataFilter?.filter?.active}
            onClick={() => setModalConfig({ show: true, data: {} })}
          />
        </Col>
        <Col className="d-flex flex-row justify-content-end align-items-center">
          <ActionButton
            text="Tambah Data"
            onClick={() => {
              sessionStorage.setItem(
                SESSION_KEY.FILTER,
                JSON.stringify(dataFilter?.filter)
              );

              history.push('/transaksi/bdp-produksi/list-realisasi-produksi');
            }}
          />
        </Col>
      </Row>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={onDismissAlert}
      />

      <Card className="border">
        <Tab.Container defaultActiveKey="bdp_bahan">
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bdp_bahan">BDP Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_upah">BDP Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_alat_mesin">BDP Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_subkon">BDP Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_overhead">BDP Overhead</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bdp_sisa_produksi">
                  BDP Sisa Produksi
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bdp_bahan">
              <PageListBDPBahan
                dataBDPBahan={dataBahan}
                paginationConfig={paginationConfigBahan}
                setPaginationConfig={setPaginationConfigBahan}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bdp_upah">
              <PageListBDPUpah
                dataBDPUpah={dataUpah}
                paginationConfig={paginationConfigUpah}
                setPaginationConfig={setPaginationConfigUpah}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bdp_alat_mesin">
              <PageListBDPAlatMesin
                dataBDPAlat={dataAlatMesin}
                paginationConfig={paginationConfigAlat}
                setPaginationConfig={setPaginationConfigAlat}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bdp_subkon">
              <PageListBDPSubkon
                dataBDPSubkon={dataSubkon}
                paginationConfig={paginationConfigSubkon}
                setPaginationConfig={setPaginationConfigSubkon}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bdp_overhead">
              <PageListBDPOverhead
                dataBDPOverhead={dataOverhead}
                paginationConfig={paginationConfigOverhead}
                setPaginationConfig={setPaginationConfigOverhead}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bdp_sisa_produksi">
              <PageListBDPSisaProduksi
                dataBDPSisaProduksi={dataSisaProduksi}
                paginationConfig={paginationConfigSisaProduksi}
                setPaginationConfig={setPaginationConfigSisaProduksi}
                searchConfig={searchConfig}
                isPageLoading={isPageLoading}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>

      {modalConfig.show && (
        <ModalFilterBDPProduksi
          SESSION_KEY={SESSION_KEY}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
          dropdown={dropdown}
        />
      )}
    </CRUDLayout>
  );
};

export default ListBDPProduksi;
