import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  InfoItemHorizontal,
  TextArea,
  ActionButton,
  SelectSearch,
  DatePicker,
  Input,
} from 'components';
import { RupiahConvert, DateConvert } from 'utilities';
import { BDPBahanApi } from 'api';

const ModalJurnalBDP = ({
  modalConfig,
  setModalConfig,
  getInitialData,
  type,
}) => {
  const today = DateConvert(new Date()).default;
  const dataModal = modalConfig?.data ?? {};
  const [dropdownCOA, setDropdownCOA] = useState([]);

  const formInitialValues = {
    id_realisasi_produksi_bahan: dataModal?.id_realisasi_produksi_bahan ?? '',
    tgl_realisasi_produksi_bahan:
      dataModal?.tgl_realisasi_produksi_bahan ?? '-',
    no_realisasi_produksi_bahan: dataModal?.no_realisasi_produksi_bahan ?? '-',
    nama_item: dataModal?.nama_item ?? '-',
    qty_realisasi_produksi_bahan: dataModal?.qty_realisasi_produksi_bahan ?? 0,
    harga_satuan_rata_rata_pakai: dataModal?.harga_satuan_rata_rata_pakai ?? 0,

    tgl_bdp_bahan: type === 'update' ? dataModal?.tgl_bdp_bahan ?? '' : '',
    no_bdp_bahan: type === 'update' ? dataModal?.no_bdp_bahan ?? '' : '',
    jumlah:
      (dataModal?.qty_realisasi_produksi_bahan ?? 0) *
      (dataModal?.harga_satuan_rata_rata_pakai ?? 0),
    id_coa_debet: type === 'update' ? dataModal?.id_coa_debet : '1010505',
    id_coa_kredit: type === 'update' ? dataModal?.id_coa_kredit : '1010501',
    keterangan_jurnal:
      type === 'update' ? dataModal.keterangan_jurnal ?? '' : '',
    nama_satuan: dataModal?.nama_satuan ?? '-',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_bdp_bahan: Yup.string().required('Pilih tanggal BDP Bahan'),
    no_bdp_bahan: Yup.string().required(
      'Pilih tgl. realisasi untuk menentukan nomor'
    ),
    id_coa_debet: Yup.string().required('Pilih COA Debet'),
    id_coa_kredit: Yup.string().required('Pilih COA Kredit'),
    keterangan_jurnal: Yup.string().required('Keterangan Jurnal wajib diisi'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValue = {
      ...values,
      nomor_akun_debet: values.id_coa_debet,
      nomor_akun_kredit: values.id_coa_kredit,
    };

    BDPBahanApi.create(finalValue)
      .then(() => {
        setSubmitting(false);
        setModalConfig({ show: false, data: {} });
        getInitialData();
      })
      .catch(() => {
        setSubmitting(false);
        window.alert('Gagal menyimpan data!');
      });
  };

  const fetchCOA = () => {
    BDPBahanApi.dropdown()
      .then((res) => {
        const coa = res?.data?.data ?? [];
        const mapcoa = coa.map((item) =>
          Object({
            ...item,
            value: item.id_coa,
            label: `${item.nomor_akun} - ${item.nama_akun}`,
          })
        );
        setDropdownCOA(mapcoa ?? []);
      })
      .catch(() => alert('Gagal memuat data COA!'));
  };

  const InfoSection = ({ values }) => {
    console.log(values);
    return (
      <>
        <InfoItemHorizontal
          label="Tgl. Realisasi Produksi"
          text={
            DateConvert(new Date(values.tgl_realisasi_produksi_bahan)).detail
          }
        />
        <InfoItemHorizontal
          label="No. Realisasi Produksi"
          text={values.no_realisasi_produksi_bahan}
        />
        <InfoItemHorizontal label="Item Bahan" text={values.nama_item} />
        <InfoItemHorizontal
          label="Qty."
          text={`${values.qty_realisasi_produksi_bahan} ${values.nama_satuan}`}
        />
        <InfoItemHorizontal
          label="Harga Satuan"
          text={
            RupiahConvert(String(parseInt(values.harga_satuan_rata_rata_pakai)))
              .detail
          }
        />
      </>
    );
  };

  const FormBody = ({ formik }) => {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      setValues,
      setFieldValue,
      handleChange,
      handleSubmit,
    } = formik;

    const getNoHandler = (tanggal = today) => {
      BDPBahanApi.no_baru({ tanggal }).then((res) => {
        setValues({
          ...values,
          tgl_bdp_bahan: tanggal,
          no_bdp_bahan: res.data.data,
        });
      });
    };

    useEffect(() => type !== 'update' && getNoHandler(), []);

    return (
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <InfoSection values={values} />
          <hr />
          <Row>
            <Col md>
              <DatePicker
                label="Tgl. BDP Bahan"
                type="date"
                placeholderText="Pilih tgl. BDP Bahan"
                selected={
                  values.tgl_bdp_bahan ? new Date(values.tgl_bdp_bahan) : ''
                }
                onChange={(date) => {
                  const newDate = DateConvert(date).default;
                  getNoHandler(newDate);
                }}
                error={Boolean(errors.tgl_bdp_bahan) && touched.tgl_bdp_bahan}
                errorText={errors.tgl_bdp_bahan}
              />
            </Col>
            <Col md>
              <Input
                label="No. BDP Bahan"
                type="text"
                placeholder="Pilih tgl. BDP Bahan"
                value={values.no_bdp_bahan}
                error={Boolean(errors.no_bdp_bahan) && touched.no_bdp_bahan}
                errorText={errors.no_bdp_bahan}
                readOnly
              />
            </Col>
          </Row>
          <Input
            label="Jumlah"
            value={RupiahConvert(String(parseInt(values.jumlah))).detail}
            className="text-right"
            readOnly
          />
          <SelectSearch
            label="COA Debet"
            name="id_coa_debet"
            placeholder="Pilih COA Debet"
            onChange={(val) => setFieldValue('id_coa_debet', val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_debet ? item : ''
            )}
            error={errors.id_coa_debet && touched.id_coa_debet && true}
            errorText={
              errors.id_coa_debet && touched.id_coa_debet && errors.id_coa_debet
            }
          />
          <SelectSearch
            label="COA Kredit"
            name="id_coa_kredit"
            placeholder="Pilih COA Kredit"
            onChange={(val) => setFieldValue('id_coa_kredit', val.value)}
            option={dropdownCOA}
            defaultValue={dropdownCOA.map((item) =>
              item.id_coa === values.id_coa_kredit ? item : ''
            )}
            error={errors.id_coa_kredit && touched.id_coa_kredit && true}
            errorText={
              errors.id_coa_kredit &&
              touched.id_coa_kredit &&
              errors.id_coa_kredit
            }
          />
          <TextArea
            label="Keterangan Jurnal*"
            name="keterangan_jurnal"
            value={values.keterangan_jurnal}
            onChange={handleChange}
            error={
              errors.keterangan_jurnal && touched.keterangan_jurnal && true
            }
            errorText={errors.keterangan_jurnal}
            rows={5}
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant={type === 'update' ? 'warning' : 'primary'}
            type="submit"
            text="Simpan"
            className="px-4 text-white"
            loading={isSubmitting}
          />
        </Modal.Footer>
      </form>
    );
  };

  useEffect(() => {
    fetchCOA();

    return () => {};
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ data: {}, show: false })}
    >
      <Modal.Header closeButton>
        <b>{`${type === 'update' ? 'Ubah' : 'Tambah'} Data Jurnal BDP`}</b>
      </Modal.Header>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {(formik) => <FormBody formik={formik} />}
      </Formik>
    </Modal>
  );
};

export default ModalJurnalBDP;
