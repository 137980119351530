import { Fragment, useContext, useRef } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  InputCurrency,
  SelectSearch,
  TextArea,
} from 'components';
import {
  DropdownTransferOverheadContext,
  TransferOverheadContext,
} from '../Context';

export const ModalFormTransfer = ({ generateMockNumber }) => {
  const formikRef = useRef(null);
  const { dropdownItemOverhead } = useContext(DropdownTransferOverheadContext);
  const { modalForm, setModalForm, setDataTransfer } = useContext(
    TransferOverheadContext
  );
  const { data, index } = modalForm;
  const isUpdate = modalForm.action === 'update';

  const initialValues = {
    id_permintaan_produksi: data?.id_permintaan_produksi ?? '',
    tgl_transfer_produksi_overhead: data?.tgl_transfer_produksi_overhead ?? '',
    no_transfer_produksi_overhead: data?.no_transfer_produksi_overhead ?? '',
    id_item_buaso: data?.id_item_buaso ?? '',
    nama_item: data?.nama_item ?? '',
    nama_satuan: data?.nama_satuan ?? '',
    qty_transfer_produksi_overhead: data?.qty_transfer_produksi_overhead ?? '0',
    qty_permintaan: data?.qty ?? data?.qty_permintaan ?? '0',
    keterangan: data?.keterangan ?? '',
    ref_overhead: 'bop',
  };

  const validationSchema = yup.object().shape({
    tgl_transfer_produksi_overhead: yup
      .date()
      .required('Tgl. Transfer Bahan Produksi diperlukan!'),
    id_item_buaso: yup.string().required('Pilih Salah Satu Item'),
    qty_transfer_produksi_overhead: yup
      .string()
      .required('Qty. Transfer diperlukan!'),
  });

  const onSubmit = (values, { resetForm }) => {
    isUpdate
      ? setDataTransfer((prev) => prev.toSpliced(index, 1, values))
      : setDataTransfer((prev) => [...prev, values]);

    resetForm();
    setModalForm({
      show: false,
      data: null,
    });
  };

  const closeModal = () => setModalForm({ show: false, data: null });

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
      }) => (
        <Modal show={modalForm.show} size="md" onHide={closeModal}>
          <Modal.Header closeButton className="text-capitalize">
            Tambah Data Transfer Overhead Produksi (Biaya Overhead)
          </Modal.Header>
          <Modal.Body>
            <InfoSection data={values} />

            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Transfer Overhead Produksi"
                  dateFormat="dd/MM/yyyy"
                  onChange={async (date) => {
                    setFieldValue('tgl_transfer_produksi_overhead', date);

                    const mockNumberResult = await generateMockNumber(date);
                    setFieldValue(
                      'no_transfer_produksi_overhead',
                      mockNumberResult
                    );
                  }}
                  selected={
                    values?.tgl_transfer_produksi_overhead
                      ? new Date(values?.tgl_transfer_produksi_overhead)
                      : null
                  }
                  error={
                    errors.tgl_transfer_produksi_overhead &&
                    touched.tgl_transfer_produksi_overhead
                  }
                  errorText={errors.tgl_transfer_produksi_overhead}
                />
              </Col>

              <Col>
                <Input
                  readOnly
                  label="No. Transfer Overhead Produksi"
                  value={values.no_transfer_produksi_overhead}
                />
              </Col>
            </Row>

            <SelectSearch
              loading={dropdownItemOverhead?.isLoading}
              label="Item Overhead"
              placeholder="Pilih salah satu . . ."
              option={dropdownItemOverhead?.data ?? []}
              defaultValue={dropdownItemOverhead?.data?.find(
                (i) => i.value === values.id_item_buaso
              )}
              onChange={(val) =>
                setValues({
                  ...values,
                  id_item_buaso: val.value,
                  nama_item: val.nama_item,
                  nama_satuan: val.nama_satuan,
                })
              }
              error={errors.id_item_buaso && touched.id_item_buaso}
              errorText={errors.id_item_buaso}
            />

            <Row>
              <Col>
                <InputCurrency
                  prefix=""
                  label="Qty. Transfer"
                  className="text-right"
                  defaultValue={values.qty_transfer_produksi_overhead}
                  onChange={(val) =>
                    setFieldValue('qty_transfer_produksi_overhead', val)
                  }
                  error={
                    errors.qty_transfer_produksi_overhead &&
                    touched.qty_transfer_produksi_overhead
                  }
                  errorText={errors.qty_transfer_produksi_overhead}
                />
              </Col>

              <Col>
                <Input
                  readOnly
                  label="Satuan Transfer"
                  defaultValue={values.nama_satuan}
                  onChange={(val) => setFieldValue('nama_satuan', val)}
                />
              </Col>
            </Row>

            <TextArea
              label="Keterangan"
              rows={3}
              value={values.keterangan}
              onChange={(val) => setFieldValue('keterangan', val.target.value)}
              error={errors.keterangan && touched.keterangan}
              errorText={errors.keterangan}
            />
          </Modal.Body>

          <Modal.Footer>
            <ActionButton
              className="btn btn-light"
              text="Batal"
              onClick={closeModal}
            />
            <ActionButton text="Simpan" onClick={handleSubmit} />
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

const InfoSection = ({ data }) => {
  return (
    <Fragment>
      <InfoItemHorizontal label="Item Overhead" text={data?.nama_item} />
      <InfoItemHorizontal
        label="Qty. Permintaan"
        text={`
        ${parseFloat(data.qty_permintaan)} 
        ${data.nama_satuan ?? 'Batang'}
        `}
      />
      <hr className="my-2 " />
    </Fragment>
  );
};
