import Services from 'services';

class HargaSatuanRataRataOverheadApi {
  page(params) {
    return Services.get('/harga_satuan_overhead/page', { params });
  }

  hsr(params) {
    return Services.get('/harga_satuan_overhead/hitung_rata_rata_overhead', {
      params,
    });
  }

  create(data) {
    return Services.post('/harga_satuan_overhead', data);
  }

  dropdown(params) {
    return Services.get('/harga_satuan_overhead/kelompok', { params });
  }

  cekStatus(params) {
    return Services.get('harga_satuan_overhead/hsr', {
      params,
    });
  }
}

export default new HargaSatuanRataRataOverheadApi();
