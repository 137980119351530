import { FilterModal, SelectSearch } from 'components';
import { useFormik } from 'formik';

export const ModalFilterHSRPenyusutan = ({
  show,
  setShow,
  filter,
  setFilter,
  dropdown,
  SESSION_KEY,
}) => {
  const initialValues = {
    id_kategori_aset: filter?.id_kategori_aset,
    id_jenis_aset: filter?.id_jenis_aset,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter({
        active: true,
        ...values,
      });

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const formik = useFormik({ initialValues, onSubmit });

  const { values, setFieldValue, handleSubmit } = formik;

  const onResetButtonClick = () => {
    sessionStorage.removeItem(SESSION_KEY.FILTER);

    setFilter({
      active: false,
      id_kategori_aset: null,
      id_jenis_aset: null,
    });

    setShow(false);
  };

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <SelectSearch
        label="Kategori Aset"
        placeholder="Pilih Kategori Aset"
        option={dropdown?.kategori}
        onChange={({ value }) => setFieldValue('id_kategori_aset', value)}
        defaultValue={
          values.id_kategori_aset
            ? dropdown?.kategori?.find(
                (v) => v.value === values.id_kategori_aset
              )
            : null
        }
      />
      <SelectSearch
        label="Jenis Aset"
        placeholder="Pilih Jenis Aset"
        option={dropdown?.jenis}
        onChange={({ value }) => setFieldValue('id_jenis_aset', value)}
        defaultValue={
          values.id_jenis_aset
            ? dropdown?.jenis?.find((v) => v.value === values.id_jenis_aset)
            : null
        }
      />
    </FilterModal>
  );
};
