import { Fragment, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { BackButton, CRUDLayout, DataStatus } from 'components';
import { InfoSectionJobOrder, TabContent, TableSummary } from './Components';
import { useFetchSingleJO } from './Hooks';
import { withHPPProvider } from './Context';

export const DetailPreformHPP = withHPPProvider(({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_job_order } = useParams();
  const { isPageLoading, dataInfo } = useFetchSingleJO(id_job_order);

  useEffect(() => setNavbarTitle('Harga Pokok Produksi (HPP)'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex align-items-center mb-3">
            <BackButton onClick={() => history.goBack()} />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoSectionJobOrder data={dataInfo} />
          <TabContent />
          <Row className="my-3">
            <Col md="6">
              <TableSummary />
            </Col>
          </Row>
        </Fragment>
      )}
    </CRUDLayout>
  );
});
