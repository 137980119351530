import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IoAddOutline } from 'react-icons/io5';
import Axios from 'axios';
import { Alert, ActionButton, DataStatus, Table, Td, Th } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { PostingBiayaPrelimApi } from 'api';
import { ModalPosting, ModalConfirm } from '../Section';
import { useIsGuest } from 'hooks';

const TablePosting = ({
  type: TYPE,
  submitCount,
  addSubmitCount,
  isReadOnly = false,
}) => {
  const { id: ID } = useParams();
  const isGuest = useIsGuest();
  const [dataTable, setDataTable] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });
  const [modalFormConfig, setModalFormConfig] = useState({
    show: false,
    id: '',
    type: '',
  });
  const [modalConfirmConfig, setModalConfirmConfig] = useState({
    show: false,
    data: {},
  });

  // Menghasilkan tipe untuk params pada fetching data
  const generateTypeOnRequest = () => {
    if (TYPE === 'HONOR') return 'honor_staff';
    if (TYPE === 'ATK') return 'atk';
    if (TYPE === 'SAFETY') return 'safety';
    if (TYPE === 'HANDTOOLS') return 'hand_tools';
    if (TYPE === 'PROTEKSIONSITE') return 'proteksi';
    if (TYPE === 'LAINLAIN') return 'lain_lain';
  };

  // Mendapatkan header pada tabel
  const generateTableHeader = useMemo(() => {
    if (TYPE === 'HONOR')
      return [
        { label: 'No', props: { width: 30 } },
        { label: 'Informasi Realisasi Prelim', props: { noPadding: true } },
        { label: 'Item Prelim', props: { width: 250 } },
        { label: 'Qty. Realisasi Prelim', props: { noPadding: true } },
        { label: 'Vendor', props: {} },
        { label: 'Harga Satuan Kontrak', props: { noPadding: true } },
        { label: 'Durasi Pemakaian', props: { noPadding: true } },
        { label: 'Sub Total', props: {} },
        { label: 'Aksi', props: { width: 10 } },
      ];

    if (TYPE === 'LAINLAIN')
      return [
        { label: 'No', props: { width: 30 } },
        { label: 'Informasi Realisasi Prelim', props: {} },
        { label: 'Item Prelim', props: { width: 250 } },
        { label: 'Qty. Realisasi Prelim', props: {} },
        { label: 'Unit Price', props: { noPadding: true } },
        { label: 'Durasi Pemakaian', props: {} },
        { label: 'Sub Total', props: {} },
        { label: 'Aksi', props: { width: 10 } },
      ];

    return [
      { label: 'No', props: { width: 30 } },
      { label: 'Informasi Realisasi Prelim', props: {} },
      { label: 'Item Prelim', props: { width: 250 } },
      { label: 'Qty. Realisasi Prelim', props: {} },
      { label: 'Unit Price', props: { noPadding: true } },
      { label: 'Durasi Pemakaian', props: {} },
      { label: 'Sub Total', props: {} },
      { label: 'Aksi', props: { width: 10 } },
    ];

    // return [
    //   { label: 'No', props: { width: 30 } },
    //   { label: 'Informasi Realisasi Prelim', props: {} },
    //   { label: 'Item Prelim', props: { width: 250 } },
    //   { label: 'Qty. Realisasi Prelim', props: {} },
    //   { label: 'Gudang Asal', props: {} },
    //   { label: 'Gudang Tujuan', props: {} },
    //   { label: 'Aksi', props: { width: 10 } },
    // ]
  }, []);

  // Mendapatkan colspan pada tabel
  const generateColSpan = useMemo(
    () => generateTableHeader.length,
    [dataTable]
  );

  // Fetching data
  const fetchingData = async () => {
    setIsLoading(true);
    setDataTable([]);
    setDataHistory([]);

    return await Axios.all([
      PostingBiayaPrelimApi.realisasi({
        id_spk: ID,
        tipe: generateTypeOnRequest(),
      }),
      PostingBiayaPrelimApi.history({
        id_spk: ID,
        tipe: generateTypeOnRequest(),
      }),
    ])
      .then(
        Axios.spread((table, history) => {
          setDataTable(table?.data?.data ?? []);
          setDataHistory(history?.data?.data ?? []);
        })
      )
      .catch(() => {
        dataTable([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Menampilkan modal form
  const onShowModalFormHandler = useCallback(
    (id) => {
      setModalFormConfig({
        show: true,
        id: id,
        type: TYPE,
      });
    },
    [modalFormConfig.show]
  );

  // Menyembunyikan modal form
  const onHideModalFormHandler = useCallback(() => {
    setModalFormConfig({
      show: false,
      id: '',
    });
  }, [modalFormConfig.show]);

  // Menyembunyikan modal confirm
  const onHideModalConfirmHandler = useCallback(() => {
    setModalConfirmConfig({
      show: false,
      data: {},
    });
  }, [modalConfirmConfig.show]);

  // Cek apakah data pada tabel sudah pernah dibuatkan history
  const checkIsHaveHistory = (id) => {
    if (TYPE === 'HONOR') {
      return Boolean(dataHistory.find((val) => val.id_realisasi === id));
    }

    if (TYPE === 'LAINLAIN') {
      return Boolean(dataHistory.find((val) => val.id_realisasi === id));
    }

    return Boolean(dataHistory.find((val) => val.id_realisasi === id));
  };

  useEffect(() => {
    fetchingData();
  }, [submitCount]);

  // Menampilkan data pada tabel
  const DataTable = ({ index, data, onAdd }) => {
    // Menangani perhitungan total
    const getTotal = (qty, harga) => {
      const parseQty = qty ? parseFloat(qty) : 0;
      const parseHarga = harga ? parseFloat(harga) : 0;

      return parseInt(parseQty * parseHarga);
    };

    if (TYPE === 'HONOR')
      return (
        <tr>
          <Td textCenter>{index + 1}</Td>
          <Td>
            <div>
              {' '}
              {data.tgl_realisasi_kontrak_prelim
                ? DateConvert(new Date(data.tgl_realisasi_kontrak_prelim))
                    .defaultDMY
                : '-'}{' '}
            </div>
            <div> {data.no_realisasi_kontrak_prelim ?? '-'}</div>
          </Td>
          <Td>{data.nama_item_prelim ?? '-'}</Td>
          <Td>
            {data.qty_realisasi ? parseFloat(data.qty_realisasi) : '-'}{' '}
            {data.kode_satuan_realisasi ?? ''}
          </Td>
          <Td>{data.nama_vendor ?? ''}</Td>
          <Td textRight>
            {data.harga_satuan
              ? RupiahConvert(String(parseFloat(data.harga_satuan))).detail
              : RupiahConvert(String(0)).detail}
          </Td>
          <Td>
            {data.durasi_realisasi ? parseFloat(data.durasi_realisasi) : '-'}{' '}
            Hari
            {/* {data.kode_satuan_waktu ?? ''}*/}
          </Td>
          <Td textRight>
            {
              RupiahConvert(
                getTotal(data.qty_realisasi, data.harga_satuan).toString()
              ).detail
            }
          </Td>
          {Boolean(!isGuest && !isReadOnly) && (
            <Td noPadding className="text-center">
              <ActionButton
                size="sm"
                className="m-1"
                onClick={() => onAdd(data.id_realisasi_kontrak_prelim)}
                disable={checkIsHaveHistory(data.id_realisasi_kontrak_prelim)}
              >
                <IoAddOutline />
              </ActionButton>
            </Td>
          )}
        </tr>
      );

    if (TYPE === 'LAINLAIN')
      return (
        <tr>
          <Td textCenter>{index + 1}</Td>
          <Td>
            <div>
              {' '}
              {data.tgl_realisasi_overhead_prelim
                ? DateConvert(new Date(data.tgl_realisasi_overhead_prelim))
                    .defaultDMY
                : '-'}{' '}
            </div>
            <div>{data.no_realisasi_overhead_prelim ?? '-'}</div>
          </Td>
          <Td>{data.nama_item_prelim ?? '-'}</Td>
          <Td>
            {data.qty_realisasi ? parseFloat(data.qty_realisasi) : '-'}{' '}
            {data.kode_satuan_realisasi ?? ''}
          </Td>
          <Td textRight>
            {data.harga_satuan
              ? RupiahConvert(String(parseFloat(data.harga_satuan))).detail
              : RupiahConvert(String(0)).detail}
          </Td>
          <Td>
            {data.durasi_realisasi ? parseFloat(data.durasi_realisasi) : '-'}{' '}
            Hari
            {/* {data.kode_satuan_waktu ?? ''} */}
          </Td>
          <Td textRight>
            {
              RupiahConvert(
                getTotal(data.qty_realisasi, data.harga_satuan).toString()
              ).detail
            }
          </Td>
          {Boolean(!isGuest && !isReadOnly) && (
            <Td noPadding className="text-center">
              <ActionButton
                size="sm"
                className="m-1"
                onClick={() => onAdd(data.id_realisasi_overhead_prelim)}
                disable={checkIsHaveHistory(data.id_realisasi_overhead_prelim)}
              >
                <IoAddOutline />
              </ActionButton>
            </Td>
          )}
        </tr>
      );

    return (
      <tr>
        <Td textCenter>{index + 1}</Td>
        <Td>
          <div>
            {' '}
            {data.tgl_realisasi_transfer_prelim
              ? DateConvert(new Date(data.tgl_realisasi_transfer_prelim))
                  .defaultDMY
              : '-'}{' '}
          </div>
          <div>{data.no_realisasi_transfer_prelim ?? '-'}</div>
        </Td>
        <Td>{data.nama_item_prelim ?? '-'}</Td>
        <Td>
          {data.qty_realisasi ? parseFloat(data.qty_realisasi) : '-'}{' '}
          {data.kode_satuan_realisasi ?? ''}
        </Td>
        <Td textRight>
          {data.harga_satuan
            ? RupiahConvert(String(parseFloat(data.harga_satuan))).detail
            : RupiahConvert(String(0)).detail}
        </Td>
        <Td>
          {data.qty_durasi ? parseFloat(data.qty_durasi) : '-'} Hari
          {/* {data.kode_satuan_durasi ?? ''} */}
        </Td>
        <Td textRight>
          {
            RupiahConvert(
              getTotal(data.qty_realisasi, data.harga_satuan).toString()
            ).detail
          }
        </Td>
        {Boolean(!isGuest && !isReadOnly) && (
          <Td noPadding className="text-center">
            <ActionButton
              size="sm"
              className="m-1"
              onClick={() => onAdd(data.id_realisasi_transfer_prelim)}
              disable={checkIsHaveHistory(data.id_realisasi_transfer_prelim)}
            >
              <IoAddOutline />
            </ActionButton>
          </Td>
        )}
      </tr>
    );
  };

  return (
    <div>
      <div className="p-2">
        <b>List Data Realisasi Prelim</b>
      </div>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Tabel */}
      <Table responsive>
        <thead className="bg-light">
          <tr>
            {generateTableHeader.map((val, index) => {
              if (val.label === 'Aksi' && (isGuest || isReadOnly)) {
                return null;
              }

              return (
                <Th {...val.props} key={index}>
                  {val.label}{' '}
                </Th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {dataTable && dataTable.length > 0 ? (
            dataTable.map((val, index) => (
              <DataTable
                key={index}
                index={index}
                data={val}
                onAdd={onShowModalFormHandler}
              />
            ))
          ) : (
            <tr>
              <Td colSpan={generateColSpan}>
                <DataStatus
                  loading={isLoading}
                  text={isLoading ? 'Memuat data . . .' : 'Tidak ada data'}
                />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal */}
      <ModalPosting
        modalType="FORM"
        type={TYPE}
        id={modalFormConfig.id}
        show={modalFormConfig.show}
        onHide={onHideModalFormHandler}
        setModalConfirmConfig={setModalConfirmConfig}
      />
      <ModalConfirm
        type={TYPE}
        show={modalConfirmConfig.show}
        data={modalConfirmConfig.data}
        setAlertConfig={setAlertConfig}
        onHide={onHideModalConfirmHandler}
        addSubmitCount={addSubmitCount}
      />
    </div>
  );
};

export default TablePosting;
