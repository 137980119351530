import { DatePicker, FilterModal, Input, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { useFormik } from 'formik';

export const ModalFilterBDPProduksi = ({
  SESSION_KEY,
  modalConfig,
  setModalConfig,
  data,
  setData,
  dropdown,
}) => {
  const filter = data?.filter;
  const formInitialValues = {
    tgl_bdp_awal: filter?.tgl_bdp_awal,
    tgl_bdp_akhir: filter?.tgl_bdp_akhir,
    no_spk: filter?.no_spk,
    no_rap: filter?.no_rap,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 },
      });
    } else {
      setData({ ...data, filter: { ...values, active: false } });
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setFieldValue, setValues, handleSubmit } = formik;

  const onChangeTglBDP = (dates) => {
    const [start, end] = dates;

    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_bdp_awal: startDate,
      tgl_bdp_akhir: endDate,
    });
  };

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER);

    setData((prev) => ({
      ...prev,
      filter: {
        tgl_bdp_awal: undefined,
        tgl_bdp_akhir: undefined,
        no_spk: undefined,
        no_rap: undefined,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. BDP Produksi"
        placeholderText="Pilih tanggal BDP Produksi"
        startDate={values?.tgl_bdp_awal ? new Date(values?.tgl_bdp_awal) : ''}
        endDate={values?.tgl_bdp_akhir ? new Date(values?.tgl_bdp_akhir) : ''}
        onChange={(dates) => onChangeTglBDP(dates)}
        monthsShown={2}
      />
      <SelectSearch
        label="No. SPK"
        placeholder="Pilih No. SPK"
        option={dropdown.spk}
        defaultValue={
          values.no_spk
            ? dropdown.spk.find((v) => v.value === values.no_spk)
            : null
        }
        onChange={({ value }) => setFieldValue('no_spk', value)}
      />
      <SelectSearch
        label="No. RAP"
        placeholder="Pilih No. RAP"
        option={dropdown.rap}
        defaultValue={
          values.no_rap
            ? dropdown.rap.find((v) => v.value === values.no_rap)
            : null
        }
        onChange={({ value }) => setFieldValue('no_rap', value)}
      />
    </FilterModal>
  );
};
