import Services from 'services';

class BDPBahanApi {
  page(params) {
    return Services.get('/bdp_bahan/page', { params });
  }

  single(params) {
    return Services.get('/bdp_bahan/single_realisasi', { params });
  }

  history(params) {
    return Services.get('/bdp_bahan/page_bdp', { params });
  }

  no_baru(params) {
    return Services.get('/bdp_bahan/no_baru', { params });
  }

  // GET DROPDOWN COA
  dropdown() {
    return Services.get('/bdp_bahan/dropdown');
  }

  create(data) {
    return Services.post('/bdp_bahan', data);
  }
}

export default new BDPBahanApi();
