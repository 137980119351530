import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoAddOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Table,
  Td,
  Th,
  ThFixed,
} from 'components';
import { useSessionStorage } from 'hooks';
import { TransferProduksiOverheadApi } from 'api';
import { DateConvert, TableNumber } from 'utilities';
import { ItemPermintaanCollapse, ModalFilterPreform } from './Components';
import { withDropdownTransferOverheadProvider } from './Context';

const TransferProduksiOverheadPreform = ({ setNavbarTitle }) => {
  const title = 'Transfer Overhead Produksi';
  const history = useHistory();
  const location = useLocation();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalFilter, setModalFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    tgl_permintaan_produksi_start: undefined,
    tgl_permintaan_produksi_end: undefined,
    id_customer: undefined,
    id_proyek: undefined,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    id_barang_jadi: undefined,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsLoading(true);

    TransferProduksiOverheadApi.preform({
      page: pagination.page,
      per_page: pagination.per_page,
      q: pagination.q,

      tgl_permintaan_produksi_start: pagination.tgl_permintaan_produksi_start,
      tgl_permintaan_produksi_end: pagination.tgl_permintaan_produksi_end,
      id_customer: pagination.id_customer,
      id_proyek: pagination.id_proyek,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      id_barang_jadi: pagination.id_barang_jadi,
    })
      .then((res) => {
        setData(res.data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.tgl_permintaan_produksi_start,
    pagination.tgl_permintaan_produksi_end,
    pagination.id_customer,
    pagination.id_proyek,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.id_barang_jadi,
  ]);

  const TableContent = () => {
    return (
      <>
        <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
          List Data Permintaan Produksi
        </div>

        <Table>
          <thead>
            <tr className="text-center">
              <Th>No</Th>
              <ThFixed> Informasi Permintaan Produksi </ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Informasi Job Order</Th>
              <Th>Item Job Order</Th>
              <Th>Item Permintaan Produksi</Th>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>

          <tbody>
            {data?.map((val, index) => (
              <tr key={index}>
                <Td>
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>
                <Td>
                  <div>
                    {val.tgl_permintaan_produksi
                      ? DateConvert(new Date(val.tgl_permintaan_produksi))
                          .detail
                      : '-'}
                  </div>
                  <div>{val.no_permintaan_produksi}</div>
                </Td>
                <Td>{val.nama_customer}</Td>
                <Td>{val.nama_proyek}</Td>
                <Td>
                  <div>
                    {val.tgl_job_order
                      ? DateConvert(new Date(val.tgl_job_order)).detail
                      : '-'}
                  </div>
                  <div>{val.no_job_order}</div>
                </Td>
                <Td>{val.nama_barang_jadi}</Td>
                <Td>
                  <ItemPermintaanCollapse data={val.detail ?? []} />
                </Td>
                <Td>
                  <ActionButton
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/transfer-produksi-overhead/tambah/${val.id_permintaan_produksi}`
                      )
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({ ...prev, page: selected + 1 }))
          }
          onDataLengthChange={(e) => {
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }));
          }}
        />
      </>
    );
  };

  return (
    <>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch
            onChange={debounce((e) => {
              const searchKey = e.target.value;

              setPagination((prev) => ({
                ...prev,
                q: searchKey,
                page: '1',
              }));
            }, 1500)}
          />

          <FilterButton
            active={pagination.active}
            onClick={() => setModalFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data?.length > 0 ? (
        <TableContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterPreform
        show={modalFilter}
        setShow={setModalFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownTransferOverheadProvider(
  TransferProduksiOverheadPreform
);
