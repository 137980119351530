import { useEffect, useState, createContext } from 'react';

export const NotifBDPContext = createContext();

export const NotifBDPProvider = ({ children }) => {
  const [notif, setNotif] = useState({
    bdp_bahan: {
      status: false,
      hardwood: false,
      plywood: false,
      factory_supply: false,
      bahan_penunjang: false,
    },
    bdp_upah: {
      status: false,
      labour_cost: false,
      labour_cost_finishing: false,
    },
    bdp_alat_mesin: {
      status: false,
      mp: false,
    },
    bdp_subkon: {
      status: false,
      subkon_finishing: false,
    },
    bdp_overhead: {
      status: false,
      bop: false,
    },
    bdp_sisa_produksi: {
      status: false,
      sisa_produksi: false,
    },
  });

  useEffect(() => {
    console.log(notif);
  }, [notif]);

  return (
    <NotifBDPContext.Provider
      value={{
        notif,
        setNotif,
      }}
    >
      {children}
    </NotifBDPContext.Provider>
  );
};

export const withNotifBDPProvider = (Component) => (props) => {
  return (
    <NotifBDPProvider>
      <Component {...props} />
    </NotifBDPProvider>
  );
};
