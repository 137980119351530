import React from 'react';
import { Table, Th, Td, TdFixed, ThFixed } from 'components';
import { RupiahConvert } from 'utilities';

const TableItemSPK = ({ data, totalPrelim, diskon = 0, ppn = 0 }) => {
  // Fungsi hitung List Item SPK
  const getTotal = () => {
    const totalItem = data?.reduce(
      (acc, { qty_rab, harga_satuan_rab }) =>
        acc + parseFloat(qty_rab || 0) * parseInt(harga_satuan_rab || 0),
      0
    );

    const total = parseFloat(totalItem || 0) + parseFloat(totalPrelim || 0);
    const totalSetelahDiskon = total - (total * diskon) / 100;
    const totalSetelahPajak =
      totalSetelahDiskon + (totalSetelahDiskon * ppn) / 100;

    return { total, totalSetelahDiskon, totalSetelahPajak };
  };

  return (
    <div className="table-responsive">
      <b>List Item SPK </b>
      <Table className="mt-2">
        <thead className="text-center bg-light">
          <tr>
            <Th>No.</Th>
            <ThFixed>Kode Item</ThFixed>
            <Th>Item Barang</Th>
            <Th width={150}>Qty.</Th>
            <Th width={150}>Unit Price</Th>
            <Th width={150}>Sub Total</Th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => {
            const subtotal = parseInt(
              parseFloat(val.qty_rab ?? 0) * parseInt(val.harga_satuan_rab ?? 0)
            );
            return (
              <tr key={index}>
                <TdFixed>{index + 1} </TdFixed>
                <TdFixed>{val?.kode_item} </TdFixed>{' '}
                <Td>{val?.nama_item ?? '-'} </Td>
                <Td>
                  {`${parseFloat(val?.qty_rab ?? 0)} ${val?.nama_satuan ?? ''}`}{' '}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(String(parseInt(val?.harga_satuan_rab ?? 0)))
                      .detail
                  }{' '}
                </Td>
                <Td className="text-right">
                  {RupiahConvert(String(parseInt(subtotal ?? 0))).detail}{' '}
                </Td>
              </tr>
            );
          })}

          {/* Prelim */}
          <tr>
            <TdFixed>{data?.length + 1} </TdFixed>
            <TdFixed>-</TdFixed>
            <Td>PRELIM</Td>
            <Td>1</Td>
            <Td className="text-right">
              {RupiahConvert(String(parseInt(totalPrelim ?? 0))).detail}
            </Td>
            <Td className="text-right">
              {RupiahConvert(String(parseInt(totalPrelim ?? 0))).detail}
            </Td>
          </tr>

          {/* Total */}
          <tr className="bg-light">
            <Td colSpan={5} className="text-right font-weight-bold">
              Total
            </Td>
            <Td className="text-right font-weight-bold">
              {RupiahConvert(String(parseInt(getTotal().total || 0))).detail}
            </Td>
          </tr>

          {/* Diskon */}
          <tr>
            <Td colSpan={5} className="text-right  font-weight-bold">
              Diskon (Dalam %)
            </Td>
            <Td className="text-right font-weight-bold">
              {parseFloat(diskon || 0)}
            </Td>
          </tr>

          {/* Total Setelah Diskon */}
          <tr className="bg-light">
            <Td colSpan={5} className="text-right font-weight-bold">
              Total Seteleah Diskon
            </Td>
            <Td className="text-right font-weight-bold">
              {
                RupiahConvert(
                  String(parseInt(getTotal().totalSetelahDiskon || 0))
                ).detail
              }
            </Td>
          </tr>

          {/* PPN */}
          <tr>
            <Td colSpan={5} className="text-right font-weight-bold">
              PPN (Dalam %)
            </Td>
            <Td className="text-right font-weight-bold">
              {parseFloat(ppn || 0)}
            </Td>
          </tr>

          {/* Total Setelah Pajak*/}
          <tr className="bg-light">
            <Td colSpan={5} className="text-right font-weight-bold">
              Total Setelah Pajak
            </Td>
            <Td className="text-right font-weight-bold">
              {
                RupiahConvert(
                  String(parseInt(getTotal().totalSetelahPajak || 0))
                ).detail
              }
            </Td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default TableItemSPK;
