import Services from 'services';

class BDPProduksi {
  get(params) {
    return Services.get('/bdp_produksi/list_realisasi', { params });
  }

  getKontrakUpah(params) {
    return Services.get('/bdp_upah/kontrak', { params });
  }

  single(params) {
    return Services.get('/bdp_produksi/single', { params });
  }

  dropdown(params) {
    return Services.get('/bdp_produksi/dropdown_filter', { params });
  }

  dropdownSPKRealisasi(params) {
    return Services.get('/bdp_produksi/d_spk_realisasi', { params });
  }

  save(data) {
    return Services.post('/bdp_produksi', data);
  }
}

export default new BDPProduksi();
