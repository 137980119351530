import Services from 'services';

class BDPBahanApi {
  page(params) {
    return Services.get('/bdp_upah/page', { params });
  }

  single(params) {
    return Services.get('/bdp_upah/single_realisasi', { params });
  }

  history(params) {
    return Services.get('/bdp_upah/page_bdp', { params });
  }

  opname(params) {
    return Services.get('/bdp_upah/single_opname', { params });
  }

  no_baru(params) {
    return Services.get('/bdp_upah/no_baru', { params });
  }

  dropdown() {
    return Services.get('/bdp_upah/dropdown');
  }

  create(data) {
    return Services.post('/bdp_upah', data);
  }
}

export default new BDPBahanApi();
