import { Badge } from 'react-bootstrap';

export const BadgeNotif = ({ show = true, variant = 'warning' }) => {
  return (
    <Badge
      pill
      className="ml-1 mb-1"
      variant={variant}
      style={{ width: 13, height: 14 }}
    >
      {show && <div />}
    </Badge>
  );
};
